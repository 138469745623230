import { CheckoutCompleteProps, useCheck } from "../context/CheckContext";
import { useCallback } from "react";
import { isFalsy } from "../utils/truthy";
import { setItem } from "./useCookies";
import { encodeCheckId } from "../utils/getCheck/getCheck";
import { usePlace } from "../context/PlaceContext";
import { useNavigate } from "react-router-dom";
import {
  CheckoutAmountRequestProps,
} from "../utils/getCheckoutAmountRequestData";

export const useNavigateToCompletePage = () => {
  const { check  } = useCheck();
  const { place } = usePlace();
  const navigate = useNavigate();

  const toCompletePage = useCallback(
    (request: CheckoutAmountRequestProps, response: CheckoutCompleteProps) => {

      const { sessionGuid } = response;

      if (isFalsy(sessionGuid)) {
        console.error("No sessionGuid at checkout response");
        return;
      }

      setItem(sessionGuid, JSON.stringify(check), 30);

      const data: CheckoutCompletedProps = { request, response };

      const searchParams = new URLSearchParams();
      searchParams.set("data", encodeCheckId(JSON.stringify(data)));

      return (
        place?.code !== undefined &&
        check?.id !== undefined &&
        navigate(
          `/${place?.code}/${encodeCheckId(check?.id)}/complete/${sessionGuid}?${searchParams.toString()}`,
          { replace: true }
        )
      );
    },
    [check, navigate, place?.code]
  );

  return {
    toCompletePage: (request: CheckoutAmountRequestProps, response: CheckoutCompleteProps) => toCompletePage(request, response)
  };
};

export interface CheckoutCompletedProps {
  request: CheckoutAmountRequestProps,
  response: CheckoutCompleteProps
}
