import * as Styled from "../SplitCheckActionSheet.styles";

import {
  SplitCheckByAmountViewModel,
  calculateSplitCheckByAmount,
} from "utils/calculateSplitCheckByAmount";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AmountInput from "components/Inputs/AmountInput/AmountInput";
import Button from "components/Button/Button";
import { FormControlLabel } from "@mui/material";
import { toDollars } from "utils/utilities";
import { useCheck } from "context/CheckContext";

interface SplitAmountProps {
  onHandleClose: () => void;
}

function SplitAmount({ onHandleClose }: SplitAmountProps) {
  const [splitByAmountResult, setSplitByAmountResult] = useState<
    SplitCheckByAmountViewModel | undefined
  >();

  const [specificAmount, setSpecificAmount] = useState<number | undefined>(
    undefined
  );

  const location = useLocation();
  const isSplitPage = location.pathname.includes("split");
  const { placeCode, checkId: checkIdParam } = useParams();
  const navigate = useNavigate();

  const { check, setSplitCheck, splitCheck } = useCheck();

  useEffect(() => {
    const amount = specificAmount ?? 0;
    setSplitByAmountResult(
      calculateSplitCheckByAmount(amount, check, splitCheck)
    );
  }, [check, specificAmount, splitCheck]);

  const handleSpecificAmount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const amount = parseFloat(event.currentTarget.value) || 0;
      setSpecificAmount(amount);
    },
    []
  );

  const handleContinue = useCallback(() => {
    setSplitCheck(splitByAmountResult?.splitCheck);

    sessionStorage.setItem(
      `splitCheck_${check?.number}`,
      JSON.stringify(splitByAmountResult?.splitCheck)
    );

    if (isSplitPage) {
      onHandleClose();
      return;
    }

    navigate(`/${placeCode}/${checkIdParam}/split`);
  }, [
    check?.number,
    checkIdParam,
    isSplitPage,
    navigate,
    onHandleClose,
    placeCode,
    setSplitCheck,
    splitByAmountResult?.splitCheck,
  ]);

  if (!splitByAmountResult) {
    return <></>;
  }

  return (
    <>
      <Styled.AmountFieldContainer direction="column">
        <FormControlLabel
          control={
            <AmountInput
              id="specific-amount"
              dataTestId="specific-amount"
              onChange={handleSpecificAmount}
            />
          }
          label="Payment amount"
          labelPlacement="top"
        />
      </Styled.AmountFieldContainer>
      <Styled.Summary direction="column">
        <Styled.SummaryRow>
          <span>
            Check Total:{" "}
            {!!splitByAmountResult?.checkDiscountInCents && (
              <span className="discount">(with discount)</span>
            )}
          </span>

          <span>${toDollars(splitByAmountResult?.checkTotalInCents)}</span>
        </Styled.SummaryRow>
        <Styled.SummaryRow>
          <span>Due Amount:</span>{" "}
          <span>${toDollars(splitByAmountResult?.checkDueAmountInCents)}</span>
        </Styled.SummaryRow>
        <Styled.SummaryRow
          className={!splitByAmountResult?.isValid ? "error" : ""}
        >
          <span>
            You&apos;re Paying
            {!splitByAmountResult?.checkServiceChargesInCents &&
              " (Tax included)"}
            :
            {!!splitByAmountResult?.checkServiceChargesInCents && (
              <span className="inner-row">
                (Tax + Service Charges included)
              </span>
            )}
          </span>{" "}
          <span>${toDollars(splitByAmountResult?.specificAmountInCents)}</span>
        </Styled.SummaryRow>
      </Styled.Summary>
      <Button
        disabled={!splitByAmountResult?.isValid || !specificAmount}
        onClick={handleContinue}
        text="Continue"
        id="split-check-specific-continue"
      />
    </>
  );
}

export default SplitAmount;
