import { defer, LoaderFunctionArgs } from "react-router-dom";
import {
  getQueryDataFetchingIfNeeded,
  Query,
} from "utils/getQueryDataFetchingIfNeeded";

import {
  BadRequestError,
  NotFoundError,
  TableNotFoundError,
} from "utils/api/errors";
import { QueryClient } from "react-query";
import getCheckAtTable from "utils/getCheck/geCheckAtTable";
import { queryKeys } from "utils/constants";
import {
  getPlaceRedirectionIfNeeded,
} from "@vendor/utils/getPlaceRedirectionIfNeeded";
import { setTag } from "@sentry/react";

export const findCheckAtTableLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {

    const placeRedirectionResponse = getPlaceRedirectionIfNeeded(params);
    if (placeRedirectionResponse) {
      return placeRedirectionResponse;
    }

    if (params.placeCode && params.tableId) {

      setTag("placeCode",params.placeCode);
      setTag("tableId", params.tableId);

      // Trying to Find check at Table with params
      const query = checkAtTableQueryBuilder(params.placeCode, params.tableId);
      const request = getQueryDataFetchingIfNeeded(query, queryClient)
        .catch((error) => {
          if (error instanceof NotFoundError) {
            throw new TableNotFoundError({
              message: "Unable to get ticket details for the table. Please try again.",
              tableId: params.tableId,
              placeCode: params.placeCode,
            });
          } else {
            throw error;
          }
        });
      return defer({ checkData: request });
    } else {
      throw new BadRequestError();
    }
  };

export const checkAtTableQueryBuilder = (
  placeCode: string,
  tableId: string
): Query => ({
  queryKey: checkAtTableQueryKeyBuilder(placeCode, tableId),
  queryFn: getCheckAtTable,
});

export const checkAtTableQueryKeyBuilder = (
  placeCode: string,
  tableId: string
) => [queryKeys.checkAtTableFeed, { placeCode, tableId }];
