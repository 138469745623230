import { environment } from "./constants";

async function getNextScreen() {
  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  environment.refererUrl && headers.append("referer", environment.refererUrl);

  try {
    const xRooamSession = localStorage.getItem("x-rooam-session");
    xRooamSession && headers.append("x-rooam-session", xRooamSession);

    if (!xRooamSession) {
      throw new Error("getNextScreen > Session token not found");
    }

    const response = await fetch(environment.baseUrl ?? "", {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `getNextScreen > Request failed with status ${response.status}`
      );
    }

    const data = await response.json();

    return data.nextPath;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

export default getNextScreen;
