import * as Styled from "./VendorLogin.styles";

import Button from "@component/Button/Button";
import CheckIcon from "@component/Icons/CheckIcon";
import { Divider } from "@mui/material";
import Message from "@component/Message/Message";
import VendorDivider from "../VendorDivider/VendorDivider";
import VendorIcon from "@vendor/components/VendorLogo/VendorIcon";
import { colors } from "@theme/Themes";
import styled from "styled-components";
import { useAuth } from "@context/AuthContext";
import { useLocation } from "react-router-dom";
import { useVendorSignIn } from "../WrappedComponent/VendorSignIn";

const Spacer = styled.div`
  padding: 18px 0;
`;

export default function VendorLogin() {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();

  const VendorSignInButton = useVendorSignIn(Button);

  return <Spacer />;

  /* if (
    location.pathname.includes("checkout") ||
    location.pathname.includes("complete")
  ) {
    return <></>;
  }

  return (
    <>
      <Styled.MessageContainer>
        {!isAuthenticated && (
          <>
            <Message
              icon={<VendorIcon fillColor={colors.vendorColor} />}
              title={"Pay Faster!"}
              message={"Log in with Resy and pay for your check even faster."}
            />
            <VendorSignInButton size="medium" text="Log in with Resy" />
          </>
        )}

        {isAuthenticated && (
          <Message
            icon={<CheckIcon fillColor={colors.primary} />}
            title={`Hi${
              user?.firstName !== undefined ? ` ${user?.firstName}` : ""
            }! You're logged in.`}
          />
        )}
      </Styled.MessageContainer>
      {!isAuthenticated && <VendorDivider />}
    </>
  ); */
}
