import apiClient from "./api/apiClient";
import { isTruthy } from "./truthy";

const sendEmailReceipt = async (request: SendEmailReceipt): Promise<void> => {
  let path = "/email/receipt/send";
  if (isTruthy(request.financialTransactionId)) {
    path = "/split/email/receipt/send";
  }

  console.debug("path", path, request);

  return await apiClient.post({
    path,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request)
  });
};

export default sendEmailReceipt;

export interface SendEmailReceipt {
  sessionGuid: string,
  emailRecipients: string
  financialTransactionId?: string
}
