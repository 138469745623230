import { gtag } from "ga-gtag";
import { isDev } from "./constants";

function trackGaEvent(eventName: string, options?: Record<any, any>) {
  if (isDev) {
    return;
  }

  gtag("event", eventName, options);
}

export default trackGaEvent;
