/**
 *
 * Basic text shift cipher that allows to encode all visible ASCII characters.
 *
 * Rot47 encryption consists in replacing a character with another located 47 positions after in the alphabet.
 *
 * @remarks
 * Uses a 94-character alphabet that is a subset of the ASCII table characters between the character 33 ! and the character 126 ~
 *
 * @param text - Text to encode or decode
 *
 * @returns Text encoded or decoded
 *
 */
const Rot47Cipher = {
  encode: (text: string) => rot47cipher(text),
  decode: (text: string) => rot47cipher(text)
};

export default Rot47Cipher;

const rot47cipher = (text: string) => text
  .split("")
  .map((char: string) => isCharInSubsetRange(char) ? applyShift(char) : char)
  .join("");
const isCharInSubsetRange = (char: string) =>
  char.charCodeAt(0) >= 33 && char.charCodeAt(0) <= 126;
const applyShift = (char: string) =>
  String.fromCharCode(33 + ((char.charCodeAt(0) + 14) % 94));
