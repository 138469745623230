import { TipProps } from "components/Tips/Tips";
import { getCookie } from "react-use-cookie";
import tryOrUndefined from "./tryOrUndefined";

const getCurrentTip = (
  checkId: string,
  isSplitCheck?: boolean
): TipProps | undefined => {
  const cookieType = isSplitCheck ? "userSplitTip" : "userTip";
  const tipCookie = getCookie(`${cookieType}_${checkId}`, undefined);
  return tryOrUndefined(() => JSON.parse(tipCookie));
};

export default getCurrentTip;
