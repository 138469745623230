import { useEffect } from "react";
import { vendorDefaultTitle } from "@vendor/utils/constants";

function usePageTitle(pageTitle: string): void {
  /* const layout = document.getElementById("layout");

  layout?.classList.add(pageTitle.toLowerCase()); */

  useEffect(() => {
    document.title = `${pageTitle} | ${vendorDefaultTitle}`;

    return () => {
      document.title = vendorDefaultTitle;
    };
  }, [pageTitle]);
}

export default usePageTitle;
