import { defer, LoaderFunctionArgs } from "react-router-dom";
import {
  getQueryDataFetchingIfNeeded,
  Query,
} from "@utils/getQueryDataFetchingIfNeeded";
import { QueryClient, QueryKey } from "react-query";

import { BadRequestError } from "@utils/api/errors";
import { queryKeys } from "@utils/constants";
import getPaytronixCheck, {
  GetPaytronixCheckRequest,
} from "@utils/getCheck/paytronix/getPaytronixCheck";

export const findPaytronixCheckLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {

    const venueId = params.venueId;
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    const ticketNumber = searchParams.get("check");
    const paytronixMemberId = searchParams.get("memberId");
    const paytronixToken = searchParams.get("token");
    const paytronixSkipAuth = searchParams.get("skipPaytronixAuth") === "true";

    if (venueId && ticketNumber && paytronixMemberId && paytronixToken) {
        const request: GetPaytronixCheckRequest = {
          venueId,
          ticketNumber,
          paytronix: {
            memberId: paytronixMemberId,
            token: paytronixToken,
            skipAuth: paytronixSkipAuth
          }
        };
        const query = paytronixCheckQueryBuilder(request);
        const response: any = getQueryDataFetchingIfNeeded(query, queryClient);

        return defer({ checkData: response });
    } else {
      throw new BadRequestError();
    }
  };

export const paytronixCheckQueryBuilder = (request: GetPaytronixCheckRequest
): Query => ({
  queryKey: paytronixCheckQueryKeyBuilder(request),
  queryFn: getPaytronixCheck,
});
export const paytronixCheckQueryKeyBuilder = (request: GetPaytronixCheckRequest) =>
  [queryKeys.paytronixCheckFeed, request] as QueryKey;
