import * as Styled from "./EmailReceipt.style";

import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { isFalsy, isTruthy } from "utils/truthy";

import Button from "components/Button/Button";
import Error from "../Error/Error";
import { NetworkError } from "utils/api/errors";
import { addBreadcrumb } from "@sentry/react";
import getCheckoutCompletedData from "../../utils/getCheckoutCompletedData";
import sendEmailReceipt from "utils/sendEmailReceipt";
import sendTabEmailReceipt from "@utils/sendTabEmailReceipt";

interface EmailReceiptProps {
  isWebTab?: boolean;
}

function EmailReceipt({ isWebTab }: EmailReceiptProps): React.ReactElement {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [emailRecipients, setEmailRecipients] = useState<string>("");
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const checkoutCompletedData = getCheckoutCompletedData();
  const sessionGuid = checkoutCompletedData?.response.sessionGuid;
  const financialTransactionId =
    checkoutCompletedData?.response?.financialTransactionId;

  const emailRegEx: RegExp = useMemo(
    () => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
    []
  );

  const handleSendEmailReceipt = useCallback(() => {
    setError(undefined);
    setIsEmailSent(false);

    if (emailRecipients.length === 0 || !emailRegEx.test(emailRecipients)) {
      setError("Invalid email.");
      return;
    }

    if (isTruthy(emailRecipients) && isTruthy(sessionGuid)) {
      setIsSending(true);
      sendEmailReceipt({
        sessionGuid,
        emailRecipients,
        financialTransactionId,
      })
        .then((_) => {
          setIsSending(false);
          setIsEmailSent(true);
        })
        .catch((error) => {
          setIsSending(false);
          setIsEmailSent(false);
          const message =
            (error as NetworkError)?.data?.message ??
            "There was an error sending the email receipt. Please try again.";
          console.error("Error sending email receipt", error);
          setError(message);
        });
    }
  }, [emailRecipients, emailRegEx, sessionGuid, financialTransactionId]);

  const handleSendTabEmailReceipt = useCallback(() => {
    setError(undefined);
    setIsEmailSent(false);

    addBreadcrumb({
      level: "info",
      category: "user_action",
      message: "Sending Email Receipt.",
    });

    if (emailRecipients.length === 0 || !emailRegEx.test(emailRecipients)) {
      setError("Invalid email.");
      return;
    }

    if (isTruthy(emailRecipients)) {
      setIsSending(true);

      sendTabEmailReceipt({ email: emailRecipients })
        .then((_) => {
          setIsSending(false);
          setIsEmailSent(true);
        })
        .catch((error) => {
          setIsSending(false);
          setIsEmailSent(false);
          const message =
            (error as NetworkError)?.data?.message ??
            "There was an error sending the email receipt. Please try again.";
          console.error("Error sending email receipt", error);
          setError(message);
        });
    }
  }, [emailRecipients, emailRegEx]);

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setError(undefined);
      setIsEmailSent(false);
      setEmailRecipients(event.target.value);
    },
    []
  );

  return (
    <Styled.Container
      direction="column"
      isWebTab={isWebTab}
      className="email-receipt"
    >
      <h3>Email Receipt? (optional)</h3>

      {error && error && (
        <Error>
          <strong>Error:</strong> {error}
        </Error>
      )}

      {isEmailSent && (
        <Styled.SuccessBox
          className="overwrite"
          severity="success"
          variant="outlined"
        >
          <Styled.SuccessTitle>Email Sent!</Styled.SuccessTitle>
          <p>Your receipt has been successfully emailed to you. Thank you!</p>
        </Styled.SuccessBox>
      )}

      <Styled.Input
        id="email"
        label={isFalsy(emailRecipients) ? "Email Address" : undefined}
        onChange={handleTextFieldChange}
        inputProps={{
          endAdornment: (
            <Styled.InputAdornment position="end">
              <Button
                isLoading={isSending}
                text={isEmailSent ? "Sent" : "Send"}
                size="medium"
                onClick={() => {
                  isWebTab
                    ? handleSendTabEmailReceipt()
                    : handleSendEmailReceipt();
                }}
              />
            </Styled.InputAdornment>
          ),
        }}
        shrink={false}
      />
    </Styled.Container>
  );
}

export default EmailReceipt;
