import { colors, fonts } from "@theme/Themes";

import StyledBox from "@GlobalComponents/StyledBox";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledBox)`
  text-align: center;
`;

export const LinkContainer = styled(StyledContainer)`
  gap: 10px;
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 1.6rem;
  margin: 0 0 16px;
`;

export const Link = styled.a`
  border: 1px solid ${colors.primary};
  border-radius: 6px;
  color: ${colors.primary};
  cursor: pointer;
  font-family: ${fonts.textFont};
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.7rem;
  padding: 15px 10px 13px;
  position: relative;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  white-space: inherit;
  width: 100%;

  &:hover {
    background-color: ${colors.primary};
    color: ${colors.paper};
  }
`;
