import { colors, fonts } from "@theme/Themes";

import MaskedInput from "react-text-mask";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  .MuiDivider-root {
    margin: 0px 0 8px;

    .divider-text {
      color: ${colors.textSecondary};
    }
  }
`;

export const Label = styled.label`
  color: ${colors.text};
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const InputContainer = styled.div`
  background-color: ${colors.lighterBackground};
  align-items: center;
  display: flex;
  border: 2px solid ${colors.border};
  border-radius: 6px;

  .MuiInputAdornment-root {
    background-color: transparent;
    border-right: 2px solid ${colors.border};
    padding: 24px 12px;

    p {
      color: ${colors.text};
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
`;

export const Input = styled(MaskedInput)`
  border: none;
  background-color: transparent;
  font-family: ${fonts.textFont};
  line-height: 2rem;
  padding: 12px;
  width: 100%;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${colors.activeDisabled};
  }
`;
