import { colors, fonts } from "@theme/Themes";

import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Contained = styled(StyledContainer)`
  background-color: ${colors.background};
  justify-content: center;
  position: relative;

  &:before,
  &:after {
    background-color: ${colors.background};
    content: "";
    left: -50%;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  &:after {
    left: initial;
    right: -50%;
  }
`;

export const Card = styled(StyledContainer)`
  align-items: center;
  border: 1px solid ${colors.borderLight};
  border-radius: 6px;
  box-shadow: 0 1px 6px 1px rgb(0 0 0 / 9%) !important;
  display: flex;
  font-size: 1.4rem;
  line-height: 1.2;
  gap: 12px;
  margin: 14px 0;
  padding: 13px 18px;
  z-index: 1;
`;

export const Image = styled.img`
  background-color: ${colors.border};
  display: flex;
  min-height: 33.5px;
  width: 53px;
`;

export const Copy = styled(StyledContainer)`
  flex: 1;
`;

export const Button = styled.a`
  align-items: center;
  background-color: ${colors.vendorColor};
  border-radius: 6px;
  color: ${colors.paper};
  display: flex;
  flex: 90px 0 0;
  font-family: ${fonts.titlesFont};
  font-size: 10px;
  justify-content: center;
  height: 33px;

  &:hover {
    color: ${colors.paper};
  }
`;
