import * as Styled from "./PaymentComplete.styles";

import { QueryKey, useQuery } from "react-query";
import { useEffect, useMemo, useState } from "react";

import { ReactComponent as CheckIcon } from "@vendor/assets/check-icon.svg";
import CompleteAdBanner from "@vendor/components/VendorAdBanners/CompleteAdBanner";
import EmailReceipt from "components/EmailReceipt/EmailReceipt";
import RateExperience from "components/RateExperience/RateExperience";
import Summary from "components/Summary/Summary";
import VendorDownload from "@vendor/components/VendorDownload/VendorDownload";
import { Zoom } from "@mui/material";
import { colors } from "@theme/Themes";
import getCheckoutCompletedData from "@utils/getCheckoutCompletedData";
import getPaidAmount from "@utils/getPaidAmount";
import { isTruthy } from "@utils/truthy";
import { queryKeys } from "@utils/constants";
import { toDollars } from "utils/utilities";
import { useCheck } from "context/CheckContext";
import { usePlace } from "context/PlaceContext";

function PaymentComplete(): React.ReactElement {
  const { check } = useCheck();
  const { place } = usePlace();

  const checkoutCompletedData = getCheckoutCompletedData();
  const sessionGuid = checkoutCompletedData?.response.sessionGuid;

  const [totalPaid, setTotalPaid] = useState<number>(0);

  const paidAmountQueryKey = [
    queryKeys.paidAmount,
    { sessionGuid },
  ] as QueryKey;

  const [shouldGetLatestPaidAmount, setShouldGetLatestPaidAmount] =
    useState<boolean>(false);
  const [isCheckFullyPaid, setIsCheckFullyPaid] = useState(false);

  const { data: paidAmount } = useQuery(paidAmountQueryKey, getPaidAmount, {
    enabled: shouldGetLatestPaidAmount,
    refetchInterval: 3_000,
    staleTime: 3_000,
    onError: (error) =>
      console.error(
        "Complete Page > Query - Failure getting Paid Amount",
        error
      ),
  });

  const checkTotalInCents = check?.totals?.totalInCents ?? 0;

  const showBanner = useMemo(() => {
    return place?.flags.includes("place.resy.pay.banner.complete");
  }, [place?.flags]);

  const dueAmount = checkTotalInCents - totalPaid;

  const amountPaidProgress = totalPaid
    ? 100 - (dueAmount / checkTotalInCents) * 100
    : 0;

  const showPartialPaymentProgress =
    checkoutCompletedData?.request.isSplitCheck &&
    (checkoutCompletedData?.request?.totalInCents ?? 0) < checkTotalInCents;

  useEffect(() => {
    if (paidAmount) {
      setTotalPaid(paidAmount.totalPaidInCents);
    } else if (check?.status === "CLOSED") {
      setTotalPaid(check?.totals.paidInCents ?? 0);
    } else {
      setTotalPaid(
        checkoutCompletedData?.request.totalInCents ??
          check?.totals?.paidInCents ??
          0
      );
    }

    setShouldGetLatestPaidAmount(
      isTruthy(checkoutCompletedData) &&
        checkoutCompletedData?.request?.isSplitCheck &&
        check?.status !== "CLOSED"
    );
    if (totalPaid >= checkTotalInCents) {
      setShouldGetLatestPaidAmount(false);
      setIsCheckFullyPaid(true);
    }
  }, [check, paidAmount, totalPaid, checkTotalInCents, checkoutCompletedData]);

  return (
    <Styled.Container direction="column">
      <Summary collapsible={true} />

      <Styled.Container className="row-icon" direction="column">
        <Zoom in={true}>
          <Styled.CheckIconContainer
            className={
              showPartialPaymentProgress && !isCheckFullyPaid ? "split" : ""
            }
            fillColor={isCheckFullyPaid ? colors.lighterBackground : undefined}
          >
            <CheckIcon />
          </Styled.CheckIconContainer>
        </Zoom>
        <h2 id="payment-completed">
          {showPartialPaymentProgress && !isCheckFullyPaid
            ? "Split Payment Made"
            : "Payment Complete"}
        </h2>
      </Styled.Container>

      {showPartialPaymentProgress && (
        <Styled.Container className="progress-bar" direction="column">
          <Styled.Container className="progress-row">
            <strong>Check: {check?.number}</strong>
            <span id="progress-row-amounts">
              <strong>${toDollars(totalPaid)}</strong> / $
              {toDollars(checkTotalInCents)}
            </span>
          </Styled.Container>
          <Styled.LinearProgressBar
            className="overwrite"
            variant="determinate"
            value={amountPaidProgress}
          />
          {isCheckFullyPaid && (
            <Styled.Container className="progress-row">
              <span>
                <strong>Thank you.</strong> This check has been paid in full.
              </span>
            </Styled.Container>
          )}
          {!isCheckFullyPaid && (
            <Styled.Container className="progress-row">
              <span>
                <strong>Remember:</strong> This check has not been fully paid.
                To pay the check in full, tell your group to complete their own
                payments.
              </span>
            </Styled.Container>
          )}
        </Styled.Container>
      )}

      <CompleteAdBanner show={showBanner} />
      <RateExperience />
      <EmailReceipt />
      <VendorDownload />
    </Styled.Container>
  );
}

export default PaymentComplete;
