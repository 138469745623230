import FindCheck from "components/FindCheck/FindCheck";
import VendorLogin from "@vendor/components/VendorLogin/VendorLogin";
import usePageTitle from "hooks/usePageTitle";

const Find = () => {
  usePageTitle("Find a Check");

  return (
    <>
      <VendorLogin />
      <FindCheck />
    </>
  );
};

export default Find;
