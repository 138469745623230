import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  h2 {
    margin-top: 32px;
  }

  h2,
  h3 {
    margin-bottom: 18px;
  }
`;

export const InnerContainer = styled(StyledContainer)`
  margin-bottom: 32px;

  &.no-items {
    border: 1px solid ${colors.border};
    border-radius: 6px;
    padding: 54px 11px;
    text-align: center;

    h1 {
      margin-bottom: 16px;
    }

    h2 {
      margin-bottom: 0px;
    }

    .icon {
      height: 64px;
      width: 64px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
    }

    > *:not(h1, h2) {
      color: ${colors.textSecondary};
    }
  }
`;

export const ErrorContainer = styled(StyledContainer)`
  margin-top: 32px;
`;
