import * as Styled from "components/ViewCheck/ViewCheck.styles";

import StopHandIcon from "components/Icons/StopHandIcon";
import { Typography } from "@mui/material";
import { colors } from "@theme/Themes";

export interface AllPaymentsDisabledProps {
  checkNumber?: string;
}

const AllPaymentsDisabledMessageBox = ({
  checkNumber,
}: AllPaymentsDisabledProps) => {
  return (
    <Styled.ErrorContainer direction="column">
      <Styled.InnerContainer className="no-items" direction="column">
        <StopHandIcon fillColor={colors.error} />
        {checkNumber && (
          <Typography variant="h1">Check: {checkNumber}</Typography>
        )}
        <Typography variant="h2">Pay the check with your server.</Typography>
        <span>
          The check amount exceeds the maximum limit for this specific location.
          To complete your check payment, please contact your server.
        </span>
      </Styled.InnerContainer>
    </Styled.ErrorContainer>
  );
};

export default AllPaymentsDisabledMessageBox;
