import { Card } from "@mui/material";
import StyledContainer from "@GlobalComponents/StyledContainer";
import TextField from "components/Inputs/TextField/TextField";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  margin-top: 18px;

  .edit-split-check-button {
    margin: 18px 0 16px;
  }
`;

export const InnerContainer = styled(StyledContainer)`
  h3,
  p {
    margin: 0 0 8px;
  }

  p {
    color: ${colors.textSecondary};
  }

  label {
    margin-bottom: 5px;
  }

  &.payment-amount {
    margin: 16px 0;
  }

  &.check-status {
    background-color: ${colors.background};
    border-bottom: 1px solid ${colors.borderMedium};
    font-size: 1.5rem;
    font-weight: 500;
    gap: 4px;
    margin: -18px 0 0 -16px;
    padding: 16px;
    position: relative;
    width: calc(100% + 32px);

    button {
      margin-top: 8px;
    }

    &:after {
      background: ${colors.background};
      border-bottom: 1px solid ${colors.borderMedium};
      content: " " !important;
      height: calc(100% + 1px);
      left: -50%;
      position: absolute;
      top: 0;
      width: calc(200%);
      z-index: -1;
    }
  }

  &.check-status-row {
    justify-content: space-between;
  }

  #payment-amount-label {
    line-height: 22px;
    margin: 0 0 0 40px;
  }
`;

export const CardContainer = styled(Card)`
  align-items: center;
  border: 1px solid ${colors.borderLight};
  box-shadow: 0 1px 6px 1px rgb(0 0 0 / 9%);
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  font-weight: 500;
  justify-content: space-between;
  padding: 11px 16px;
`;

export const Counter = styled.div`
  align-items: center;
  border: 1px solid ${colors.text};
  border-radius: 6px;
  display: flex;
  font-size: 1.5rem;
  flex: 118px 0 0;
  justify-content: space-around;
  padding: 9px 0;
`;

export const CounterBtn = styled.button`
  appearance: initial;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 700;
  height: 100%;
  position: relative;

  &:hover {
    &:before {
      background-color: ${colors.background};
      border-radius: 50%;
      content: "";
      left: 50%;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      z-index: 0;
    }

    &:disabled {
      &:before {
        content: none;
      }
    }
  }

  .icon {
    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
  }
`;

export const Input = styled(TextField)``;
