import * as Styled from "./SplitCheck.styles";

import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "components/Button/Button";
import ProceedToCheckout from "@vendor/components/ProceedToCheckout/ProceedToCheckout";
import Summary from "components/Summary/Summary";
import Tips from "components/Tips/Tips";
import { useCheck } from "context/CheckContext";

function SplitCheck(): React.ReactElement {
  const [showNoTipError, setShowNoTipError] = useState<boolean>(false);

  const { placeCode, checkId: checkIdParam } = useParams();
  const navigate = useNavigate();

  const { updateSelectedTip } = useCheck();

  // reset split check and tip
  useEffect(() => {
    updateSelectedTip(undefined);
    // Not adding setSelectedTip to the dep array to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = useCallback(() => {
    navigate(`/${placeCode}/${checkIdParam}`);
  }, [checkIdParam, navigate, placeCode]);

  const handleNoTip = useCallback((show: boolean) => {
    setShowNoTipError(show);
  }, []);

  return (
    <Styled.Container direction="column">
      <Button
        onClick={handleNavigate}
        text="Return to Full Check"
        size="medium"
        variant="outlined"
      />

      <Tips onNoTip={handleNoTip} showNoTipError={showNoTipError} />
      <Summary />
      <ProceedToCheckout onNoTip={handleNoTip} />
    </Styled.Container>
  );
}

export default SplitCheck;
