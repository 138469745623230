import "utils/extensions/number";

import { CheckData } from "./getCheck/GetCheckModels";
import { SplitCheckProps } from "../context/CheckContext";
import { TipProps } from "../components/Tips/Tips";

export interface CheckoutAmountRequestProps {
  totalInCents: number;
  rooamTotalInCents: number;
  customAmountInCents: number | undefined;
  tipInCents: number;
  feesInCents: number;
  isSplitCheck: boolean;
}

const getCheckoutAmountRequestData = (
  check: CheckData | undefined,
  splitCheck: SplitCheckProps | undefined,
  selectedTip: TipProps | undefined
): CheckoutAmountRequestProps => {
  const checkFees = check?.totals.feesInCents ?? 0;
  // const paidInCents = check?.totals.paidInCents ?? 0;
  const dueAmountInCents = check?.totals?.dueInCents ?? 0;

  const selectedTipInCents = (selectedTip?.amount ?? 0) * 100;

  const checkTotalInCents = dueAmountInCents + selectedTipInCents + checkFees;

  const splitCheckTotalInCents = splitCheck?.amount
    ? splitCheck?.amount + selectedTipInCents + checkFees
    : undefined;

  const customAmountInCents = splitCheck?.amount
    ? (splitCheckTotalInCents ?? 0) - selectedTipInCents - checkFees
    : undefined;

  const rooamTotalInCents = splitCheckTotalInCents ?? checkTotalInCents;
  const totalInCents = rooamTotalInCents - selectedTipInCents - checkFees;

  return {
    totalInCents: totalInCents.round(),
    rooamTotalInCents: rooamTotalInCents.round(),
    customAmountInCents: customAmountInCents?.round(),
    tipInCents: selectedTipInCents.round(),
    feesInCents: checkFees,
    isSplitCheck: !!splitCheck,
  };
};

export default getCheckoutAmountRequestData;
