import * as Styled from "./VendorFooter.styles";

import FooterLogo from "@vendor/assets/images/resy-rooam-lockup.png";

export default function VendorFooter(): React.ReactElement {
  const year = new Date().getFullYear();
  return (
    <Styled.Container direction="column">
      <Styled.Logo>
        <img src={FooterLogo} alt="Resy Pay logo" />
      </Styled.Logo>
      <span>
        Resy Pay -{" "}
        <a href="https://rooam.co/" target="_blank" rel="noreferrer">
          Powered by Rooam
        </a>
      </span>
      <br />
      <span className="text-lighter">
        © Copyright {year} - Resy Network, Inc.
      </span>
      <br />
      <span className="text-lighter">
        <a
          href="https://resy.com/terms/resypay"
          target="_blank"
          rel="noreferrer"
        >
          Resy Pay Terms
        </a>
      </span>
    </Styled.Container>
  );
}
