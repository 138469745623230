import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  background-color: ${colors.background};
  align-items: center;
  border-bottom: 1px solid ${colors.borderMedium};
  font-size: 1.3rem;
  line-height: 1.8rem;
  justify-content: center;
  padding: 11px 0px;

  p {
    margin: 0;
  }

  button {
    all: initial;
    color: ${colors.primary};
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;
