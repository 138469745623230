import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import getCheck, { decodeCheckId } from "utils/getCheck/getCheck";
import useCookie, { setItem } from "hooks/useCookies";

import { CheckData } from "../utils/getCheck/GetCheckModels";
import { Client } from "braintree-web";
import { TipProps } from "components/Tips/Tips";
import { checkQueryKeyBuilder } from "../routes/Check/Check";
import getBraintreeInstance from "utils/getBraintreeInstance";
import getCheckoutAmountRequestData from "../utils/getCheckoutAmountRequestData";
import getCurrentTip from "utils/getCurrentTip";
// import getPaymentMethods from "utils/getPaymentMethods";
import getSplitCheckCookie from "utils/getSplitCheckCookie";
import { isCheckPath } from "../app-routes/payweb/AppRoutes";
import { isTruthy } from "../utils/truthy";
import { isValidPlaceCode } from "../utils/getPlace";
import { tipRate } from "../utils/utilities";
import { useAuth } from "./AuthContext";
import { useParams } from "react-router-dom";
import { usePlace } from "./PlaceContext";
import { useQuery } from "react-query";

export interface PaymentMethodProps {
  conversionRate: number | null;
  display: string;
  expMonth: number;
  expYear: number;
  id: number;
  isDefault: boolean;
  rewardDollarsInCents: number | null;
  rewardPoints: number | null;
  token: string;
  type: string;
  amexPointsInDollarAmount?: number;
}

export interface CheckoutCompleteProps {
  charged: boolean | undefined;
  cardType: string | undefined;
  sessionGuid: string | undefined;

  totalPaidInCents: number | undefined;
  financialTransactionId: string | undefined;
}

export enum splitCheckoutTypeEnum {
  EQUALLY = 1,
  BY_AMOUNT = 2,
  BY_ITEM = 3,
}

export interface SplitCheckProps {
  type?: string;
  amount?: number;
  taxes?: number;
  serviceCharges?: number;
  discount?: number;
  selectedItems?: string[];
  itemsWithDiscount?: CheckData["items"];
  splitCheckoutType?: splitCheckoutTypeEnum;
}

interface CheckContextProps {
  check: CheckData | undefined;
  checkTotal: string;
  checkoutComplete: CheckoutCompleteProps;
  error: unknown;
  guestInstance: Client | undefined;
  guestToken: string | undefined;
  isLoading: boolean;
  isSplit: number;
  isLoadingPaymentMethods: boolean;
  paymentMethods: PaymentMethodProps[] | undefined;
  resyInstance: Client | undefined;
  resyToken: string | undefined;
  selectedMethod: PaymentMethodProps | undefined;
  selectedTip: TipProps | undefined;
  setCheckoutComplete: React.Dispatch<
    React.SetStateAction<CheckoutCompleteProps>
  >;
  setIsSplit: React.Dispatch<React.SetStateAction<number>>;
  setPaymentMethods: React.Dispatch<
    React.SetStateAction<PaymentMethodProps[] | undefined>
  >;
  setSelectedMethod: React.Dispatch<
    React.SetStateAction<PaymentMethodProps | undefined>
  >;
  setResyInstance: React.Dispatch<React.SetStateAction<Client | undefined>>;
  updateSelectedTip: (tip: TipProps | undefined) => void;
  setSplitCheck: React.Dispatch<
    React.SetStateAction<SplitCheckProps | undefined>
  >;
  splitCheck: SplitCheckProps | undefined;
}

export const CheckContext = createContext<CheckContextProps | null>(null);

CheckContext.displayName = "CheckContext";

function CheckProvider(props: any): React.ReactElement {
  const [checkId, setCheckId] = useState<string | undefined>(undefined);
  const [isSplit, setIsSplit] = useState<number>(1);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] =
    useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethodProps[] | undefined
  >([]);
  const [selectedTip, setSelectedTip] = useState<TipProps | undefined>();

  const [guestInstance, setGuestInstance] = useState<Client | undefined>();
  const [guestToken, setGuestToken] = useState<string | undefined>();
  const [resyInstance, setResyInstance] = useState<Client | undefined>();
  const [resyToken, setResyToken] = useState<string | undefined>();

  const [selectedMethod, setSelectedMethod] = useState<
    PaymentMethodProps | undefined
  >();

  const [splitCheck, setSplitCheck] = useState<SplitCheckProps | undefined>(
    undefined
  );

  const [checkoutComplete, setCheckoutComplete] =
    useState<CheckoutCompleteProps>({
      charged: undefined,
      cardType: undefined,
      sessionGuid: undefined,
      totalPaidInCents: undefined,
      financialTransactionId: undefined,
    });

  const { checkId: checkIdParam, placeCode } = useParams();

  const { place } = usePlace();

  const [isFetchingEnabled, setIsFetchingEnabled] = useState(false);

  const { isAuthenticated } = useAuth();
  const [authCookie, _] = useCookie("authToken");

  const shouldEnableFetching = useCallback(
    () =>
      isCheckPath() && isTruthy(checkIdParam) && isValidPlaceCode(placeCode),
    [checkIdParam, placeCode]
  );

  useEffect(() => {
    setIsFetchingEnabled(shouldEnableFetching());
    if (checkIdParam) {
      setCheckId(decodeCheckId(checkIdParam));
    }
  }, [checkIdParam, shouldEnableFetching]);

  // Get Check Data
  const checkQueryKey = useMemo(
    () => checkQueryKeyBuilder({ placeCode, ticketId: checkId }),
    [checkId, placeCode]
  );

  const {
    data: check,
    error,
    isLoading,
  } = useQuery(checkQueryKey, getCheck, {
    enabled: isFetchingEnabled,
    refetchInterval: 10_000,
    staleTime: 10_000,
    onSettled: (_) => {
      setIsFetchingEnabled(shouldEnableFetching());
    },
  });

  const updateSelectedTip = useCallback(
    (tip: TipProps | undefined) => {
      setSelectedTip(tip);
    },
    [setSelectedTip]
  );

  // Get splitCheck and/or currentTip from cookie
  useEffect(() => {
    if (check?.number && checkId) {
      const isSplitCheck = getSplitCheckCookie(check?.number);
      let currentTip = getCurrentTip(checkId);

      const defaultTipPercentage = place?.tipsData.defaultTip.percentage ?? 0;

      if (isSplitCheck) {
        setSplitCheck(isSplitCheck);
        currentTip = getCurrentTip(checkId, true);
      }

      // set default tip to cookie if no currentTip and normal flow
      if (!selectedTip && !isSplitCheck) {
        setSelectedTip(currentTip);

        if (!currentTip) {
          setItem(
            `userTip_${checkId}`,
            JSON.stringify({
              percentage: defaultTipPercentage,
              amount: tipRate(
                defaultTipPercentage,
                check?.totals.subTotalInCents
              ),
            }),
            1
          );
        }
      }

      if (!selectedTip && isSplitCheck) {
        setSelectedTip(currentTip);
      }
    }
  }, [
    check?.number,
    check?.totals.subTotalInCents,
    checkId,
    place?.tipsData.defaultTip.percentage,
    selectedTip,
  ]);

  // 🚨 Resy Authentication Braintree vault is no longer available, so no payment methods available
  // Get payment methods
  /* useEffect(() => {
    if (!authCookie || !placeCode) {
      setPaymentMethods([]);
      return;
    }

    const getPaymentMethodsList = async (
      placeCode: string,
      authCookie: string
    ) => {
      setIsLoadingPaymentMethods(true);
      try {
        const data = await getPaymentMethods(placeCode, authCookie);
        setPaymentMethods(data?.paymentMethods ?? []);
      } catch (error) {
        console.error("Error while getting user payment methods", error);
      }
      setIsLoadingPaymentMethods(false);
    };

    void getPaymentMethodsList(placeCode, authCookie);
  }, [authCookie, placeCode]); */

  // Generate Braintree's Guest Client Instance
  useEffect(() => {
    if (!placeCode) {
      return;
    }

    const generateInstance = async (placeCode: string) => {
      const { authorization, instance } = await getBraintreeInstance(placeCode);

      if (authorization && instance) {
        setGuestInstance(instance);
        setGuestToken(authorization);
      }
    };

    generateInstance(placeCode).catch((error) =>
      console.error(
        "Check Context > Error generating Braintree instance",
        error
      )
    );
  }, [placeCode]);

  // Generate Braintree's Resy Client Instance
  /* useEffect(() => {
    if (!authCookie || !placeCode) {
      return;
    }

    const generateInstance = async (placeCode: string, authCookie: string) => {
      const { authorization, instance } = await getBraintreeInstance(
        placeCode,
        authCookie,
      );

      if (!authorization && !instance) {
        setResyInstance(instance);
        setResyToken(authorization);

        throw new Error("Error getting Braintree Token for Resy user");
      }

      setResyInstance(instance);
      setResyToken(authorization);
    };

    generateInstance(placeCode, authCookie).catch((error) =>
      console.error(
        "Error generating Braintree Resy instance in Check Context",
        error,
      ),
    );
  }, [
    authCookie,
    isAuthenticated,
    placeCode,
  ]); */

  const calculatedCheckTotal = useMemo(() => {
    const checkoutAmountRequest = getCheckoutAmountRequestData(
      check,
      splitCheck,
      selectedTip
    );
    return checkoutAmountRequest.rooamTotalInCents
      .round()
      .toDollars()
      .toString();
  }, [check, splitCheck, selectedTip]);

  const value: CheckContextProps = useMemo(
    () => ({
      check,
      checkTotal: calculatedCheckTotal,
      checkoutComplete,
      error,
      guestInstance,
      guestToken,
      isLoading,
      isSplit,
      isLoadingPaymentMethods,
      paymentMethods,
      resyInstance,
      resyToken,
      selectedMethod,
      selectedTip,
      setIsSplit,
      setCheckoutComplete,
      setPaymentMethods,
      setSelectedMethod,
      setResyInstance,
      updateSelectedTip,
      setSplitCheck,
      splitCheck,
    }),
    [
      check,
      calculatedCheckTotal,
      checkoutComplete,
      error,
      guestInstance,
      guestToken,
      isLoading,
      isSplit,
      isLoadingPaymentMethods,
      paymentMethods,
      resyInstance,
      resyToken,
      selectedMethod,
      selectedTip,
      updateSelectedTip,
      splitCheck,
    ]
  );

  return (
    <CheckContext.Provider value={value} {...props} />
  ) as React.ReactElement;
}

// Hook to consume CheckProvider
export function useCheck(): CheckContextProps {
  const context = useContext(CheckContext);

  if (context === undefined) {
    throw new Error("useCheck must be used within a CheckProvider");
  }

  if (context === null) {
    throw new Error("CheckProvider supplied null context");
  }

  return context;
}

export default CheckProvider;
