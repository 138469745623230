import * as Styled from "./Loader.styles";

interface LoaderProps {
  color?: string;
}

function Loader({ color }: LoaderProps) {
  return <Styled.Loader color={color} />;
}

export default Loader;
