import StyledFormControl from "@GlobalComponents/StyledFormControl";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const FormControl = styled(StyledFormControl)`
  gap: 8px;
  width: 100%;

  > div {
    color: ${colors.text};
    border: 1px solid ${colors.border};
    border-radius: 6px;
    background: none;
    height: 45px;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    width: 100%;
    padding: 10px 11px 11px;

    &.braintree-hosted-fields-invalid {
      border-color: ${colors.error};

      + .MuiFormHelperText-root {
        color: ${colors.error};
        display: block;
      }
    }

    &.braintree-hosted-fields-valid:not(#cardholder-name) {
      border-color: ${colors.success};
      color: ${colors.success};
    }

    + .MuiFormHelperText-root {
      display: none;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
      padding: 0 0 5px;
    }
  }
`;

export const Label = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
`;
