import { Backdrop } from "@mui/material";
import React from "react";
import { colors } from "@theme/Themes";

interface FullScreenStateProps {
  children: React.ReactNode;
  backgroundColor?: string;
}
const FullScreenState = (props: FullScreenStateProps) => {
  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: props.backgroundColor ?? colors.background,
      }}
      open={true}
      transitionDuration={0}
    >
      {props.children}
    </Backdrop>
  );
};

export default FullScreenState;
