import * as Styled from "./ViewMenuButton.styles";

import PayCheckImg from "@vendor/assets/images/paycheck-table-qr-code.png";

function ViewMenuPayCheck() {
  return (
    <Styled.PayCheckContainer direction="column">
      <Styled.MainTitle>
        View the <span>Menu,</span>
        <br />
        Come back to pay the <span>Check.</span>
      </Styled.MainTitle>
      <span>
        You can always get back to your check by scanning the QR code on the
        table.
      </span>
      <Styled.PayCheckImg
        src={PayCheckImg}
        alt="Pay your check scanning the QR code at your table"
      />
    </Styled.PayCheckContainer>
  );
}

export default ViewMenuPayCheck;
