import { defer, LoaderFunctionArgs } from "react-router-dom";
import {
  Params,
  paramsFromQRCodeRequest,
} from "./params/paramsFromQRCodeRequest";
import {
  getQueryDataFetchingIfNeeded,
  Query,
} from "utils/getQueryDataFetchingIfNeeded";
import { QueryClient, QueryKey } from "react-query";

import {
  BadRequestError,
  CheckNotFoundError,
  NetworkError,
  NotFoundError,
  PlaceNotFoundError,
} from "utils/api/errors";
import getUpserveCheck from "utils/getCheck/getUpserveCheck";
import { queryKeys } from "utils/constants";
import {
  getPlaceRedirectionIfNeeded,
} from "@vendor/utils/getPlaceRedirectionIfNeeded";
import { setTag } from "@sentry/react";

export const findUpserveCheckByNumberLoader =
  (queryClient: QueryClient) =>
  async ({ request, params }: LoaderFunctionArgs) => {

    const placeRedirectionResponse = getPlaceRedirectionIfNeeded(params);
    if (placeRedirectionResponse) {
      return placeRedirectionResponse;
    }

    const findCheckParams: Params | undefined =
      paramsFromQRCodeRequest(request);

    if (
      params.placePermalink &&
      findCheckParams &&
      findCheckParams.origin === "upserve"
    ) {

      setTag("placePermalink", params.placePermalink);
      setTag("ticketNumber", findCheckParams.ticketNumber);
      setTag("ticketId", findCheckParams.ticketId);
      
      // Trying to Find check with params
      const query = upserveCheckQueryBuilder({
        placePermalink: params.placePermalink,
        ticketNumber: findCheckParams.ticketNumber,
        ticketId: findCheckParams.ticketId
      });

      const request = await getQueryDataFetchingIfNeeded(query, queryClient)
        .catch((error) => {
          if (error instanceof NotFoundError) {
            throw new CheckNotFoundError({
              message: "Ticket Not Found",
              ticketNumber: findCheckParams.ticketNumber ?? undefined,
              placeCode: undefined,
            });
          } else if (error instanceof NetworkError && error.code === 422) {
            throw new PlaceNotFoundError({
              message: "Place Not Found",
              placeCode: params.placePermalink
            });
          } else {
            throw error;
          }
        });

      return defer({ checkData: request });
    }
    throw new BadRequestError();
  };

interface CheckQueryRequest {
  placePermalink: string,
  ticketNumber?: string,
  ticketId?: string
}
export const upserveCheckQueryBuilder = (request: CheckQueryRequest
): Query => ({
  queryKey: upserveCheckQueryKeyBuilder(request),
  queryFn: getUpserveCheck,
});
export const upserveCheckQueryKeyBuilder = (request: CheckQueryRequest) =>
  [queryKeys.upserveCheckFeed, request] as QueryKey;
