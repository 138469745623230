import Switch, { SwitchProps } from "@mui/material/Switch";

import { colors } from "@theme/Themes";
import { styled } from "@mui/material/styles";

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    // backgroundColor: "red",
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: colors.paper,
      "& + .MuiSwitch-track": {
        backgroundColor: colors.switchTrackColor,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        // opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: colors.primary,
      border: `6px solid ${colors.paper}`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: colors.background,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      // opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: colors.borderDark,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default StyledSwitch;
