import { useEffect, useState } from "react";

import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

interface FadeInProps {
  children: React.ReactNode;
  isVisible: boolean;
}

const Container = styled(StyledContainer)`
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &.fade-in {
    opacity: 1;
  }
`;

function FadeIn({ children, isVisible }: FadeInProps) {
  const [animateIn, setAnimateIn] = useState<boolean>(false);

  useEffect(() => {
    setAnimateIn(isVisible);
  }, [isVisible]);

  return (
    <Container className={animateIn ? "fade-in" : ""} direction="column">
      {children}
    </Container>
  );
}

export default FadeIn;
