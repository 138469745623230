/* eslint-disable no-restricted-globals */

import { useEffect, useMemo } from "react";

import PaymentComplete from "components/PaymentComplete/PaymentComplete";
import PaymentVoided from "../../components/PaymentComplete/PaymentVoided";
import { vendorDefaultTitle } from "@vendor/utils/constants";
import { useCheck } from "@context/CheckContext";
import usePageTitle from "hooks/usePageTitle";
import { useParams } from "react-router-dom";

function Complete(): React.ReactElement {
  const { check } = useCheck();

  usePageTitle("Check Paid");

  const { placeCode } = useParams();

  useEffect(() => {
    const destinationPath = `${location.pathname}${location.search}`;
    history.replaceState({}, vendorDefaultTitle, `/${placeCode}`);
    history.pushState({}, `Check Paid | ${vendorDefaultTitle}`, destinationPath);

    window.scrollTo(0, 0);
  }, [placeCode]);

  const isCheckVoided = useMemo(() => {
    return check?.status === "CLOSED" && check?.items.length === 0;
  }, [check]);

  return (
    <>
      {isCheckVoided && <PaymentVoided />}
      {!isCheckVoided && <PaymentComplete />}
    </>
  );
}

export default Complete;
