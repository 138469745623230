import * as Styled from "./Error.styles";

import { Box, Stack } from "@mui/material";
import { colors, fonts } from "@theme/Themes";

import Button from "components/Button/Button";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export interface ErrorStateProps {
  title?: String;
  showReloadPage?: boolean;
}

const ErrorState = ({ title, showReloadPage }: ErrorStateProps) => {
  const navigate = useNavigate();

  const handleTryAgain = useCallback(() => {
    navigate(0);
  }, [navigate]);

  return (
    <Box
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Stack spacing={2} alignItems={"center"}>
        <CloudOffIcon
          sx={{
            width: 106,
            height: 106,
            color: colors.textDisabled,
          }}
        />
        <strong
          style={{
            color: colors.text,
            fontFamily: fonts.textFont,
            fontSize: "16px",
          }}
        >
          {title ?? "Something went wrong"}
        </strong>
        {showReloadPage && (
          <Styled.Container>
            <Button
              color={"error"}
              onClick={handleTryAgain}
              size="medium"
              text={"Try again"}
              variant="contained"
            />
          </Styled.Container>
        )}
      </Stack>
    </Box>
  );
};

export default ErrorState;
