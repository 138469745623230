import { environment } from "./constants";

interface SendTabEmailReceiptProps {
  email: string;
}

async function sendTabEmailReceipt({
  email,
}: SendTabEmailReceiptProps): Promise<Response | undefined> {
  const xRooamSession = localStorage.getItem("x-rooam-session");

  const headers = new Headers();
  headers.append("content-type", "application/json");
  environment.originUrl && headers.append("origin", environment.originUrl);
  xRooamSession && headers.append("x-Rooam-session", xRooamSession);

  const body = JSON.stringify({
    email,
  });

  try {
    const response = await fetch(`${environment.baseUrl}/receipt`, {
      method: "POST",
      headers,
      body,
    });

    if (!response.ok) {
      throw new Error(
        `sendTabEmailReceipt > Request failed with status ${response.status}`
      );
    }

    return response;
  } catch (error) {
    console.error(error);
  }
}

export default sendTabEmailReceipt;
