import * as Styled from "./FindCheck.styles";

import {
  BadRequestError,
  InternalServerDramaError,
  NotFoundError,
} from "utils/api/errors";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { encodeCheckId, fetchCheck } from "utils/getCheck/getCheck";
import { useNavigate, useParams } from "react-router-dom";

import Button from "components/Button/Button";
import Error from "components/Error/Error";
import TextField from "components/Inputs/TextField/TextField";
import { Typography } from "@mui/material";
import { addBreadcrumb } from "@sentry/react";
import trackGaEvent from "utils/trackGaEvent";
import { updateCheckQueryData } from "hooks/useUpdateQueryData";
import { usePlace } from "context/PlaceContext";
import { useQueryClient } from "react-query";

interface Message {
  title: string;
  message?: string;
}

const FindCheck = () => {
  const [checkNumber, setCheckNumber] = useState<string>("");
  const [error, setError] = useState<Message | undefined>();
  const [validationError, setValidationError] = useState<string | undefined>();
  const [isSearching, setIsSearching] = useState(false);
  const { placeCode } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { place, isError: isPlaceError, isLoading } = usePlace();

  useEffect(() => {
    if (error) {
      setIsSearching(false);
      setError(error);
    }
  }, [error]);

  const handleFindCheck = useCallback(() => {
    if (placeCode && checkNumber) {
      addBreadcrumb({
        level: "info",
        category: "user_action",
        message: `Find Check ${checkNumber} at Place ${placeCode}`,
      });

      const checkNumberSanitized = checkNumber.trim().replace(/^#/, "");
      if (checkNumberSanitized.length === 0) {
        setError({
          title: "Check Number is empty!",
          message: "Provide a valid Check Number",
        });
        return;
      }

      setIsSearching(true);
      clearErrors();
      fetchCheck({ placeCode, ticketNumber: checkNumberSanitized })
        .then((data) => {
          updateCheckQueryData(placeCode, data.id, data, queryClient)
            .then(() => {
              navigate(`/${placeCode}/${encodeCheckId(data.id)}`);
            })
            .catch((error) => {
              console.error("Error updating Check query data", error);
            });
        })
        .catch((error) => {
          console.error(
            `Error getting check with number ${checkNumber} at place ${placeCode}`
          );
          if (error instanceof NotFoundError) {
            setError({
              title: "Check not found!",
              message: "Verify that Check Number is correct.",
            });
          } else if (error instanceof BadRequestError) {
            setError({
              title: "Check Number might be wrong!",
              message: "Verify that Check Number is correct.",
            });
          } else if (error instanceof InternalServerDramaError) {
            setError({
              title: "Something went wrong!",
              message: "Try again in a few minutes.",
            });
          } else {
            setError({ title: "Uuups!" });
          }
        });
    } else {
      setError(!place ? { title: "Something went wrong" } : undefined);
      setValidationError(
        !checkNumber ? "Check Number can not be empty" : undefined
      );
    }

    trackGaEvent("Clicked View Check Button");
  }, [checkNumber, navigate, place, placeCode, queryClient]);

  const clearErrors = () => {
    setError(undefined);
    setValidationError(undefined);
  };

  const handleTextFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      clearErrors();
      setCheckNumber(event.target.value);
    },
    []
  );
  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        handleFindCheck();
      }
    },
    [handleFindCheck]
  );

  if (isLoading) {
    return (
      <Styled.Container>
        <Typography variant="h1">Loading Place...</Typography>
      </Styled.Container>
    );
  }

  // return error when not finding place
  if (isPlaceError) {
    return (
      <Styled.Container>
        {isPlaceError && (
          <Error>
            <strong>Error:</strong> An error ocurred trying to find the place,
            please try again.
          </Error>
        )}
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Typography variant="h2">Enter Check information</Typography>

      {error && (
        <Error>
          <strong>{error.title}</strong> {error.message ?? "Please try again."}
        </Error>
      )}

      <Styled.InnerContainer>
        <Styled.FieldLabel>Check Number</Styled.FieldLabel>

        <TextField
          id="check-number"
          isError={Boolean(validationError)}
          helperText={validationError}
          label={!checkNumber ? "Check Number" : undefined}
          onChange={handleTextFieldChange}
          onKeyPress={handleKeyPress}
          shrink={false}
          variant="outlined"
          value={checkNumber}
        />

        <Button
          isLoading={isSearching}
          disabled={checkNumber?.length <= 0}
          onClick={handleFindCheck}
          text="View Check"
          variant="outlined"
        />
      </Styled.InnerContainer>
    </Styled.Container>
  );
};

export default FindCheck;
