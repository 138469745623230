import { colors } from "@theme/Themes";
import styled from "styled-components";

interface LoaderProps {
  color?: string;
}

export const Loader = styled.span<LoaderProps>`
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;

  &:before,
  &:after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid rgb(237, 237, 237);
    animation: prixClipFix 2s linear infinite alternate;
  }

  &:after {
    inset: 8px;
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: ${({ color }) => color ?? colors.primary};
  }
`;
