import * as Styled from "./Order.styles";

import { Avatar } from "@mui/material";
import { LocalOffer } from "@mui/icons-material";
import { TabItemData } from "@utils/getTabFeed/getTabFeed";
import { environment } from "@utils/constants";

const { isWebTab } = environment;

export interface ItemProps {
  id: string;
  composedId: string;
  discount: {
    amountInCents: number;
  } | null;
  name: string;
  preTaxPricePerUnitInCents: number | null;
  priceInCents: number;
  pricePerUnitInCents: number;
  quantity: number;
  taxInCents: number | null;
  // these are WebTab props:
  pricePerUnit: undefined;
}

interface OrderedItemProps {
  item: ItemProps | TabItemData;
  index: number;
}

export const itemPriceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

function OrderedItem({ item, index }: OrderedItemProps): React.ReactElement {
  const pricePerEach = item?.pricePerUnitInCents
    ? itemPriceFormatter.format(item.pricePerUnitInCents / 100)
    : item.pricePerUnit;

  const priceInCents = item.priceInCents
    ? itemPriceFormatter.format(item.priceInCents / 100)
    : itemPriceFormatter.format(
      parseFloat(item.pricePerUnit ?? "") * item.quantity
    );

  return (
    <Styled.Item>
      <Styled.ItemCount index={index}>
        <Avatar>{item.quantity}</Avatar>
      </Styled.ItemCount>
      <Styled.ItemInfo>
        <Styled.ItemName>{item.name}</Styled.ItemName>
        <Styled.ItemUnitPrice className={isWebTab ? "item-unit-price" : ""}>
          @ {pricePerEach} each{" "}
          {item.discount && (
            <span className="item-discount">
              <LocalOffer fontSize="small" />
            </span>
          )}
        </Styled.ItemUnitPrice>
      </Styled.ItemInfo>
      <Styled.ItemPrice>{priceInCents}</Styled.ItemPrice>
    </Styled.Item>
  );
}

export default OrderedItem;
