const getBoolean = (value: any) => {
  switch (value) {
  case true:
  case "true":
  case 1:
  case "1":
  case "on":
  case "yes":
  case "enabled":
  case "enable":
    return true;
  default:
    return false;
  }
};

export default getBoolean;
