import { ComponentType, MouseEvent } from "react";

import { ButtonProps } from "@component/Button/Button";
import { REACT_APP_RESY_LOGIN_URL } from "../../utils/constants";
import trackGaEvent from "@utils/trackGaEvent";
import useAddScript from "@hooks/useAddScript";
import useAuthentication from "../../hooks/useAuthentication";
import { usePlace } from "@context/PlaceContext";

export type OnClickEvent =
  | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  | undefined;

export function useVendorSignIn<P extends object>(
  WrappedComponent: ComponentType<P>
) {
  useAddScript(REACT_APP_RESY_LOGIN_URL);

  const { signIn } = useAuthentication();
  const { place } = usePlace();

  return ({ disabled, onClick, ...props }: P & ButtonProps) => {
    const handleLogin = (event: OnClickEvent) => {
      event?.preventDefault();
      if (place?.resyVenueId) {
        event?.preventDefault();
        onClick?.(event);
        signIn(place.resyVenueId);
      } else {
        console.error(
          "Vendor SignIn > Missing Place Code required for Sign In."
        );
      }

      trackGaEvent("Clicked Login", {
        description: `PlaceCode: ${place?.code}`,
      });
    };

    return (
      <WrappedComponent
        {...(props as P)}
        onClick={handleLogin}
        disabled={disabled ?? !place?.resyVenueId}
      />
    );
  };
}
