import { SplitCheckProps } from "context/CheckContext";

function getSplitCheckCookie(
  checkNumber?: string
): SplitCheckProps | undefined {
  const cookie = sessionStorage.getItem(`splitCheck_${checkNumber}`);

  if (!cookie) {
    return undefined;
  }

  return JSON.parse(cookie);
}

export default getSplitCheckCookie;
