import * as Styled from "../SplitCheckActionSheet.styles";

import { useCallback, useEffect, useState } from "react";

import CheckIcon from "components/Icons/CheckIcon";
import { FormControlLabel } from "@mui/material";
import Radio from "components/Inputs/Radio/Radio";
import { SplitCheckProps } from "context/CheckContext";
import useSplitCheckItems from "@hooks/useSplitCheckItems";

interface SplitCheckOptionsProps {
  onOptionChange: (option: SplitCheckProps["type"] | undefined) => void;
}

function SplitCheckOptions({ onOptionChange }: SplitCheckOptionsProps) {
  const [radioOptions, setRadioOptions] = useState<string[]>([
    "Split Equally",
    "Split by Amount",
  ]);
  const [selectedOption, setSelectedOption] = useState<
    SplitCheckProps["type"] | undefined
  >(undefined);

  const { isEnabled } = useSplitCheckItems();

  // Determine if Split by item should be shown
  useEffect(() => {
    if (isEnabled && !radioOptions.includes("Split by Item")) {
      setRadioOptions(["Split Equally", "Split by Amount", "Split by Item"]);
    }
  }, [radioOptions, isEnabled]);

  const handleSplitOption = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedOption(event.currentTarget.value);
      onOptionChange?.(event.currentTarget.value);
    },
    [onOptionChange]
  );

  const RadioOption = ({
    option,
    optionToKebab,
  }: {
    option: string;
    optionToKebab: string;
  }) => {
    return (
      <Styled.Box>
        <FormControlLabel
          labelPlacement="start"
          value={optionToKebab}
          control={
            <Radio
              checkedIcon={<CheckIcon />}
              id={optionToKebab}
              name="split-choice"
              onChange={handleSplitOption}
              checked={selectedOption === optionToKebab}
            />
          }
          label={option}
        />
      </Styled.Box>
    );
  };

  return (
    <Styled.ContentContainer className="button-bottom" direction="column">
      <Styled.SplitOptions
        aria-labelledby="demo-radio-buttons-group-label"
        name="split-choices"
      >
        {radioOptions.map((item, index) => {
          const optionToKebab = item
            .toLowerCase()
            .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr: string) => "-" + chr)
            .trim();

          return (
            <RadioOption
              key={`${optionToKebab}_${index}`}
              option={item}
              optionToKebab={optionToKebab}
            />
          );
        })}
      </Styled.SplitOptions>
    </Styled.ContentContainer>
  );
}

export default SplitCheckOptions;
