import AmexImg from "assets/payments/amex.png";
import DinersImg from "assets/payments/diners.png";
import DiscoverImg from "assets/payments/discover.png";
import JcbImg from "assets/payments/jcb.png";
import MaestroImg from "assets/payments/maestro.png";
import MasterCardImg from "assets/payments/mastercard.png";
import OtherImg from "assets/payments/other.jpeg";
import VisaImg from "assets/payments/visa.png";
import styled from "styled-components";

interface PaymentIconsProps {
  cardType: string | undefined;
  className: string;
}

function PaymentIcons({ cardType, className }: PaymentIconsProps) {
  if (!cardType) {
    return <></>;
  }

  const cardIssuer = cardType.toLowerCase();

  const RenderComponent = () => {
    switch (cardIssuer) {
    case "american express":
      return (
        <CardIcon
          className={className}
          src={AmexImg}
          alt="American Express"
        />
      );
    case "amex":
      return (
        <CardIcon
          className={className}
          src={AmexImg}
          alt="American Express"
        />
      );
    case "diners":
      return <CardIcon className={className} src={DinersImg} alt="Diners" />;
    case "discover":
      return (
        <CardIcon className={className} src={DiscoverImg} alt="Discover" />
      );
    case "jcb":
      return <CardIcon className={className} src={JcbImg} alt="JCB" />;
    case "maestro":
      return (
        <CardIcon className={className} src={MaestroImg} alt="Maestro" />
      );
    case "mastercard":
      return (
        <CardIcon
          className={className}
          src={MasterCardImg}
          alt="Mastercard"
        />
      );
    case "visa":
      return <CardIcon className={className} src={VisaImg} alt="Visa" />;

    default:
      return (
        <CardIcon
          className={className}
          src={OtherImg}
          alt="Other Payment Methods"
        />
      );
    }
  };

  return <RenderComponent />;
}

export default PaymentIcons;

const CardIcon = styled.img`
  width: 28px;
`;
