import NoTicketAtTableMessageBox, {
  TableProps,
} from "components/MessageBox/NoTicketAtTableMessageBox";
import { useAsyncError, useRouteError } from "react-router-dom";

import MessageBox from "components/MessageBox/MessageBox";
import { TooEarlyError } from "utils/api/errors";
import VendorLogin from "@vendor/components/VendorLogin/VendorLogin";
import { messageForError } from "utils/errors/messageForError";

const FindCheckErrorHandler = () => {
  const asyncError = useAsyncError();
  const routerError = useRouteError();

  const error = asyncError ?? routerError;
  const noTicketAtTableError: TableProps | undefined =
    error instanceof TooEarlyError ? error.data : undefined;

  return (
    <>
      <VendorLogin />
      {noTicketAtTableError && (
        <NoTicketAtTableMessageBox {...noTicketAtTableError} />
      )}
      {!noTicketAtTableError && (
        <MessageBox {...messageForError(asyncError ?? routerError)} />
      )}
    </>
  );
};

export default FindCheckErrorHandler;
