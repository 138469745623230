import { isTruthy } from "../truthy";

class BaseError extends Error {
  constructor(message?:string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NetworkError extends BaseError {
  constructor(public code: number, message: string, public data?: any, public logData?: any) {
    if (isTruthy(data?.userMessage) || isTruthy(data?.message)) {
      message = `${message} - ${ data?.userMessage ?? data?.message }`;
    }
    super(message);
  }
}

export class NotFoundError extends NetworkError {
  constructor(data?: any, logData?: any) {
    super(404, "Not Found", data, logData);
  }
}

export class BadRequestError extends NetworkError {
  constructor(data?: any, logData?: any) {
    super(400, "Bad Request", data, logData);
  }
}

export class InternalServerDramaError extends NetworkError {
  constructor(data?: any, logData?: any) {
    super(500, "Internal Server Error", data, logData);
  }
}

export class TooEarlyError extends NetworkError {
  constructor(data?: any, logData?: any) {
    super(425, "Too Early Error", data, logData);
  }
}

export class InvalidDataError extends BaseError {}

export interface TicketErrorProps {
  message?: string
  ticketNumber?: string
  placeCode?: string
}

export class TicketError extends BaseError {
  constructor(public data?: TicketErrorProps) {
    super(data?.message);
  }
}

export class CheckNotFoundError extends TicketError {}
export class CheckClosedOnPOSError extends TicketError {}

export interface TableErrorProps {
  message?: string
  tableId?: string
  placeCode?: string
}

export interface PlaceErrorProps {
  message?: string
  placeCode?: string
}

export class TableNotFoundError extends  BaseError {
  constructor(public data?: TableErrorProps) {
    super(data?.message);
  }
}

export class PlaceNotFoundError extends  BaseError {
  constructor(public data?: PlaceErrorProps) {
    super(data?.message);
  }
}

export interface ErrorResponseProps {
  timestamp: number
  status: number,
  error: string,
  message: string
  path: string
}

export class InvalidPlaceCodeError extends BaseError {
  constructor(message: string, public placeCode: string | undefined) {
    super(`${message} | Invalid placeCode: ${placeCode}`);
  }
}
