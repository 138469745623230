import { colors, fonts } from "@theme/Themes";

import Button from "@component/Button/Button";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 32px;
  margin-bottom: -16px;
`;

export const SheetContainer = styled(StyledContainer)`
  justify-items: flex-end;
`;

export const ContentHeader = styled(StyledContainer)`
  border-bottom: 1px solid ${colors.border};
  justify-content: space-between;
  padding: 16px 16px 21px 16px;
`;

export const Title = styled.h3`
  display: flex;
  font-size: 1.8rem;
  margin: 0;
`;

export const CancelButton = styled(Button)`
  display: flex;
  &.MuiButtonBase-root {
    font-family: ${fonts.textFont} !important;
    font-size: 1.6rem;
    min-width: initial !important;
    padding: 0 !important;
    width: max-content !important;
  }
`;

export const ContentContainer = styled(StyledContainer)<{
  isMenuShown: boolean;
}>`
  padding: ${({ isMenuShown }) => (isMenuShown ? "0" : "24px 24px 60px")};
`;

export const PayCheckContainer = styled(StyledContainer)`
  color: ${colors.textSecondary};
  font-size: 1.8rem;
  margin: 0 auto;
  max-width: 340px;
  text-align: center;
`;

export const MainTitle = styled.h2`
  color: ${colors.text};
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 14px;

  span {
    color: ${colors.highlightColor};
  }
`;

export const PayCheckImg = styled.img`
  max-width: 340px;
  margin: 0 auto;
`;

export const ContentFooter = styled(StyledContainer)`
  background-color: ${colors.paper};
  border-top: 1px solid ${colors.borderLight};
  padding: 16px 16px 22px;
  flex: 1;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const FooterButton = styled.a`
  background-color: ${colors.primary};
  border-radius: 6px;
  color: ${colors.paper};
  font-family: ${fonts.titlesFont};
  font-size: 1.6rem;
  line-height: 1;
  padding: 16px 16px;
  text-align: center;
  width: 100%;

  &:hover {
    color: ${colors.paper} !important;
  }
`;
