import * as Styled from "./ViewCheck.styles";

import Tips, { TipProps } from "components/Tips/Tips";
import { useCallback, useEffect, useState } from "react";

import OrderList from "components/Order/OrderList";
import PaytronixRewards from "@component/PaytronixRewards/PaytronixRewards";
import ProceedToCheckout from "@vendor/components/ProceedToCheckout/ProceedToCheckout";
import SplitCheckButton from "components/SplitCheck/SplitCheckButton";
import Summary from "components/Summary/Summary";
import { Typography } from "@mui/material";
import ViewMenuButton from "@component/ViewMenuButton/ViewMenuButton";
import { environment } from "@utils/constants";
import { tipRate } from "utils/utilities";
import { useCheck } from "context/CheckContext";
import { usePlace } from "context/PlaceContext";

function ViewCheck(): React.ReactElement {
  const [showNoTipError, setShowNoTipError] = useState<boolean>(false);

  const { check, error, updateSelectedTip, setSplitCheck } = useCheck();
  const { place } = usePlace();

  const placeTipPercentage = place?.tipsData.defaultTip.percentage ?? 0;
  const checkSubTotalInCents = check?.totals.subTotalInCents ?? 0;
  const placeTipAmount = tipRate(placeTipPercentage, checkSubTotalInCents);

  const checkServiceCharges = check?.totals.serviceChargesInCents ?? 0;

  // reset split check and tip
  useEffect(() => {
    const defaultTip: TipProps = {
      percentage: checkServiceCharges ? 0 : placeTipPercentage,
      amount: checkServiceCharges ? 0 : placeTipAmount,
    };

    updateSelectedTip(defaultTip);
    setSplitCheck(undefined);
    sessionStorage.removeItem(`splitCheck_${check?.number}`);
    // Not adding updateSelectedTip to the dep array to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check?.number, placeTipAmount, placeTipPercentage, setSplitCheck]);

  const handleNoTip = useCallback((show: boolean) => {
    setShowNoTipError(show);
  }, []);

  // return error when not finding check
  if (check?.status === "CLOSED") {
    return (
      <Styled.ErrorContainer direction="column">
        <Styled.InnerContainer className="no-items" direction="column">
          <Typography variant="h2">Check closed.</Typography>
          <span>This check is closed in the POS system.</span>
        </Styled.InnerContainer>
      </Styled.ErrorContainer>
    );
  }

  // return error when not finding check
  if (error) {
    return (
      <Styled.ErrorContainer direction="column">
        <Styled.InnerContainer className="no-items" direction="column">
          <Typography variant="h2">Error loading your check</Typography>
          <span>
            The check was not found. Please refresh the page to try again.
          </span>
        </Styled.InnerContainer>
      </Styled.ErrorContainer>
    );
  }

  return (
    <Styled.Container direction="column">
      {environment.viewMenuEnabled === "enabled" && <ViewMenuButton />}

      {check?.items.length === 0 ? (
        <>
          <Typography variant="h2" fontSize={18}>
            Check: {check?.number}
          </Typography>
          <Styled.InnerContainer className="no-items" direction="column">
            <Typography variant="h2">Your check is open!</Typography>
            <span>But nothing has been ordered.</span>
          </Styled.InnerContainer>
        </>
      ) : (
        <>
          <Styled.InnerContainer direction="column">
            <Typography variant="h2" fontSize={18}>
              Check: {check?.number}
            </Typography>
            {check?.items !== undefined && <OrderList items={check?.items} />}
          </Styled.InnerContainer>
          {place?.flags?.includes("place.pay.web.show.split.check") && (
            <SplitCheckButton />
          )}
          <Tips onNoTip={handleNoTip} showNoTipError={showNoTipError} />
          {check?.paytronix && (
            <PaytronixRewards
              rewardDollarsInCents={check?.paytronix?.availableReward * 100}
            />
          )}
          <Summary />
          <ProceedToCheckout onNoTip={handleNoTip} />
        </>
      )}
    </Styled.Container>
  );
}

export default ViewCheck;
