import braintree, { Client } from "braintree-web";
import { getGuestToken, getResyToken } from "./getToken";

const createInstance = async (authorization: string) => {
  let instance;
  return await new Promise<Client>((resolve, reject) => {
    braintree.client.create(
      {
        authorization,
      },
      (err, clientInstance) => {
        if (err) {
          console.error("Error creating Braintree instance", err);
          reject(new TypeError("Error generating instance"));
        }

        instance = clientInstance;
        resolve(instance);
      }
    );
  });
};

async function getBraintreeInstance(placeCode: string, authToken?: string) {
  // 🚨 Disabled Resy Authentication as their Braintree vault is no longer available
  /* let authorization;

  if (authToken) {
    authorization = (await getResyToken(authToken, placeCode)).clientToken;
  } else {
    authorization = (await getGuestToken(placeCode)).clientToken;
  } */

  const authorization = (await getGuestToken(placeCode)).clientToken;

  const instance = await createInstance(authorization);

  return {
    authorization,
    instance,
  };
}

export default getBraintreeInstance;
