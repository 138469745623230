import * as Styled from "./TextField.styles";

import {
  FilledInputProps,
  FormHelperText,
  InputProps,
  OutlinedInputProps,
} from "@mui/material";
import { useCallback, useState } from "react";

import StyledFormControl from "@GlobalComponents/StyledFormControl";

interface TextFieldProps {
  helperText?: string;
  id: string;
  inputProps?:
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>;
  isError?: boolean;
  label?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  shrink?: boolean;
  variant?: "filled" | "outlined" | "standard";
  value?: string;
}

function TextField({
  helperText,
  id,
  inputProps,
  isError,
  label,
  onChange,
  onKeyPress,
  shrink,
  variant,
  value,
}: TextFieldProps): React.ReactElement {
  // Handle focus to show/hide label as placeholder
  const [focus, setFocus] = useState<boolean>(false);

  const handleOnFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(event);
    },
    [onChange]
  );

  const handleOnKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyPress?.(event);
    },
    [onKeyPress]
  );

  return (
    <StyledFormControl error={isError}>
      <Styled.TextInput
        id={id}
        onChange={handleOnChange}
        className="overwrite"
        InputLabelProps={{ shrink }}
        InputProps={inputProps}
        label={!shrink && !focus && label}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onKeyPress={handleOnKeyPress}
        variant={variant}
        value={value}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </StyledFormControl>
  );
}

export default TextField;
