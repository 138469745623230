import { Box, Stack } from "@mui/material";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import { colors } from "@theme/Themes";

export interface ErrorStateNullContextProps {
  title?: String;
}

const ErrorStateNullContext = ({ title }: ErrorStateNullContextProps) => {
  return (
    <Box
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
    >
      <Stack spacing={2} alignItems={"center"}>
        <CloudOffIcon
          sx={{
            width: 106,
            height: 106,
            color: colors.textDisabled,
          }}
        />
        <strong
          style={{
            color: colors.text,
            fontFamily: "sans-serif",
            fontSize: "16px",
          }}
        >
          {title ?? "Something went wrong"}
        </strong>
      </Stack>
    </Box>
  );
};

export default ErrorStateNullContext;
