import { OutlinedInput } from "@mui/material";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)``;

export const Label = styled.label`
  color: ${colors.text};
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const Input = styled(OutlinedInput)`
  &.overwrite {
    background-color: ${colors.background};
    padding-left: 0;

    > input {
      background-color: ${colors.paper};
      border-left: 1px solid ${colors.border};
      height: auto;
      padding: 12px 11px 13px;
    }

    .MuiInputAdornment-root {
      margin: 0;
      padding: 0 13px;

      p {
        color: ${colors.text};
      }
    }

    .MuiOutlinedInput-notchedOutline {
      top: -7px;
    }
  }
`;
