import { getItem, setItem } from "@hooks/useCookies";
import {
  GetPaytronixCheckRequest,
} from "@utils/getCheck/paytronix/getPaytronixCheck";
import tryOrUndefined from "@utils/tryOrUndefined";
import { isTruthy } from "@utils/truthy";

const paytronixUtils = {
  existsDataForCheckId: (ticketId: string) => isTruthy(getItem(`paytronix_${ticketId}`)),
  getData: (ticketId: string): GetPaytronixCheckRequest | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return tryOrUndefined(() => JSON.parse(getItem(`paytronix_${ticketId}`)!));
  },
  setData: (ticketId: string, request: GetPaytronixCheckRequest) => {
    setItem(`paytronix_${ticketId}`, JSON.stringify(request), 1);
  }
};

export default paytronixUtils;
