import * as Styled from "./AmexRewards.styles";

import { useCallback, useEffect, useState } from "react";

import AmountInput from "components/Inputs/AmountInput/AmountInput";
import StyledSwitch from "@GlobalComponents/StyledSwitch";
import { toDollars } from "utils/utilities";
import { useCheck } from "context/CheckContext";

interface AmexRewardsProps {
  methodIndex: number;
  rewardPoints: number;
  rewardDollarsInCents: number;
  conversionRate: number;
  onRewardPointsChange: (points: number | undefined) => void;
  onSwitchChange: (methodIndex: number) => void;
}

function AmexRewards({
  methodIndex,
  onRewardPointsChange,
  rewardPoints,
  rewardDollarsInCents,
  conversionRate,
  onSwitchChange,
}: AmexRewardsProps) {
  const [selected, setSelected] = useState<boolean>(false);
  const [pointsApplied, setPointsApplied] = useState<number>();
  const [pointsAppliedInDollars, setPointsAppliedInDollars] =
    useState<number>();
  const [isExceeded, setIsExceeded] = useState<boolean>(false);

  const { splitCheck, check, selectedTip } = useCheck();

  const checkFees = check?.totals?.feesInCents ?? 0;
  const dueAmountInCents = check?.totals?.dueInCents ?? 0;

  const selectedTipInCents = (selectedTip?.amount ?? 0) * 100;

  const checkTotalInCents = (
    dueAmountInCents +
    checkFees +
    selectedTipInCents
  ).round();

  const splitCheckTotalInCents = splitCheck?.amount
    ? (splitCheck?.amount + checkFees + selectedTipInCents).round()
    : undefined;

  const maxPointsInDollars = rewardPoints * conversionRate;

  const maxRewardDollarsToRedeem = getMaxRewardDollarsToRedeem(
    rewardDollarsInCents,
    (splitCheckTotalInCents ?? checkTotalInCents).toDollars()
  );

  useEffect(() => {
    setPointsApplied(maxRewardDollarsToRedeem / conversionRate);
  }, [conversionRate, maxRewardDollarsToRedeem]);

  const handleSwitch = useCallback(() => {
    setSelected(!selected);

    if (!selected) {
      setPointsApplied(maxRewardDollarsToRedeem / conversionRate);
      onSwitchChange(methodIndex);
      onRewardPointsChange(maxRewardDollarsToRedeem);
      setPointsAppliedInDollars(maxRewardDollarsToRedeem);
    } else {
      setPointsApplied(undefined);
      onRewardPointsChange(undefined);
      setPointsAppliedInDollars(undefined);
    }
  }, [
    conversionRate,
    maxRewardDollarsToRedeem,
    methodIndex,
    onRewardPointsChange,
    onSwitchChange,
    selected,
  ]);

  const handleRewardPoints = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const amount = parseFloat(event.currentTarget.value);
      const pointsToApply = amount / conversionRate || 0;
      const total = (splitCheckTotalInCents ?? checkTotalInCents).toDollars();

      setPointsApplied(pointsToApply);
      setIsExceeded(amount > maxPointsInDollars || amount > total);

      if (amount <= maxPointsInDollars) {
        onRewardPointsChange(amount);
      }
    },
    [
      conversionRate,
      maxPointsInDollars,
      onRewardPointsChange,
      splitCheckTotalInCents,
      checkTotalInCents,
    ]
  );

  return (
    <Styled.AmexRewards direction="column">
      <Styled.Row>
        <Styled.Column className="rewards-info" direction="column" role="note">
          <span>Amex Membership Rewards® points:</span>
          <span>
            {formattedNumber(rewardPoints)} points ($
            {toDollars(rewardDollarsInCents)}) available
          </span>
        </Styled.Column>
        <Styled.Column className="center-items" direction="column">
          <StyledSwitch id="switch-pay-points" onClick={handleSwitch} />
        </Styled.Column>
      </Styled.Row>
      {selected && (
        <>
          <Styled.Row className="reward-points-row">
            <Styled.Column>
              <AmountInput
                id="reward-points-usd"
                defaultValue={pointsAppliedInDollars?.toString()}
                onChange={handleRewardPoints}
              />
            </Styled.Column>
            <Styled.Column>
              {pointsApplied && `${formattedNumber(pointsApplied.round())}`}
              <br />
              points applied
            </Styled.Column>
          </Styled.Row>
          <Styled.Row className="terms" direction="column">
            {isExceeded && (
              <div className="exceeded">
                Cannot apply an amount greater than ticket total or available
                points.
              </div>
            )}
            <div>
              Redeemed Membership Rewards® points will be reflected as a
              statement credit.
            </div>
          </Styled.Row>
        </>
      )}
    </Styled.AmexRewards>
  );
}

export default AmexRewards;

function formattedNumber(n: number) {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getMaxRewardDollarsToRedeem(
  rewardDollarsInCents: number,
  total: number
) {
  const availableRewardDollars = rewardDollarsInCents / 100;

  return Math.min(total, availableRewardDollars);
}
