import * as Styled from "./CardPaymentsDisabled.styles";

import PaymentIcon from "@mui/icons-material/Payment";

function CardPaymentsDisabled() {
  return (
    <Styled.Container>
      <Styled.WarningBox>
        <PaymentIcon />
        <h4>Apple Pay and Google Pay Only!</h4>
      </Styled.WarningBox>
      <Styled.Copy>
        This location only accepts <strong>Apple Pay</strong> and{" "}
        <strong>Google Pay</strong>. If you don&apos;t see these options below,
        check that they are correctly set up on your device.
      </Styled.Copy>
    </Styled.Container>
  );
}

export default CardPaymentsDisabled;
