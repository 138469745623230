import { CheckoutCompletedProps } from "../hooks/useNavigateToCompletePage";
import tryOrUndefined from "./tryOrUndefined";
import { decodeCheckId } from "./getCheck/getCheck";


const getCheckoutCompletedData = (): CheckoutCompletedProps | undefined => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  return tryOrUndefined(() => JSON.parse(decodeCheckId(decodeURIComponent(searchParams.get("data") ?? ""))));
};

export default getCheckoutCompletedData;
