/* TO-DO: eslint-disable indent */
/* eslint-disable indent */
import * as Styled from "./Summary.styles";

import React, { ReactNode, useCallback, useState } from "react";
import { isFalsy, isTruthy } from "../../utils/truthy";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { TipProps } from "components/Tips/Tips";
import { decodeCheckId } from "../../utils/getCheck/getCheck";
import getCheckoutCompletedData from "../../utils/getCheckoutCompletedData";
import tryOrUndefined from "../../utils/tryOrUndefined";
import { useCheck } from "context/CheckContext";
import useCookie from "hooks/useCookies";
import { useParams } from "react-router-dom";

export interface SummaryProps {
  collapsible?: boolean;
}

export interface SummaryContainerProps {
  amountInCents: number;
  children: ReactNode;
  collapsible?: boolean;
}

const textTestIdMap: { [title: string]: string } = {
  "Payment Total:": "payment-total-text",
  "You're paying: ": "paying-amount-text",
  "Tip:": "tip-text",
  "Fees:": "fees-text",
};

const amountTestIdMap: { [title: string]: string } = {
  "Payment Total:": "payment-total-amount",
  "You're paying: ": "paying-amount",
  "Tip:": "tip-amount",
  "Fees:": "fees-amount",
};

const getTitleAsString = (title: React.ReactNode): string => {
  if (typeof title === "string") {
    return title;
  } else {
    return String(title);
  }
};

function Summary({ collapsible }: SummaryProps): React.ReactElement {
  const [isFeesExpanded, setIsFeesExpanded] = useState<boolean>(false);

  const { check, selectedTip: useCheckTip, splitCheck } = useCheck();

  const { checkId } = useParams();

  const [userTip, _] = useCookie(`userTip_${decodeCheckId(checkId ?? "")}`);
  const [userSplitTip, __] = useCookie(
    `userSplitTip_${decodeCheckId(checkId ?? "")}`
  );

  const isCheckClosed = check?.status === "CLOSED";

  const subtotal = check?.totals?.subTotalInCents ?? 0;
  const checkTaxes = check?.totals?.taxInCents ?? 0;
  let checkFees = check?.totals.feesInCents ?? 0;
  const subtotalWithTax = subtotal + checkTaxes;

  const dueAmountInCents = check?.totals?.dueInCents ?? 0;

  const paidInCents = check?.totals.paidInCents ?? 0;

  const parsedUserSplitTip = userSplitTip
    ? JSON.parse(userSplitTip)
    : undefined;

  const savedTip: TipProps | undefined = splitCheck?.amount
    ? parsedUserSplitTip
    : (userTip && tryOrUndefined(() => JSON.parse(userTip))) ?? undefined;

  let selectedTip = useCheckTip ?? savedTip;
  if (isCheckClosed && check?.payments?.length === 1) {
    selectedTip = {
      amount: (check?.totals?.tipsInCents ?? 0) / 100,
      percentage: -1,
    };
  }

  const checkoutCompletedData = getCheckoutCompletedData();

  if (checkoutCompletedData) {
    checkFees = checkoutCompletedData.request.feesInCents;
    selectedTip = {
      amount: checkoutCompletedData.request.tipInCents / 100,
      percentage: -1,
    };
  }

  const discount = splitCheck?.discount
    ? undefined
    : check?.totals?.discountsInCents;
  const serviceCharges = splitCheck?.serviceCharges
    ? undefined
    : check?.totals?.serviceChargesInCents;

  const taxes = splitCheck?.taxes ?? checkTaxes ?? 0;
  const taxesFees = splitCheck
    ? checkFees
    : !paidInCents
    ? taxes + checkFees
    : checkFees;

  const selectedTipInCents = (selectedTip?.amount ?? 0) * 100;

  let checkTotalInCents =
    checkoutCompletedData?.request?.rooamTotalInCents ??
    (dueAmountInCents + checkFees + selectedTipInCents).round();

  if (isCheckClosed) {
    const totalInCents = check?.totals.totalInCents ?? 0;
    checkTotalInCents =
      check?.payments?.length === 1
        ? totalInCents + checkFees + selectedTipInCents
        : checkoutCompletedData?.request?.rooamTotalInCents ?? 0;
  }

  const splitCheckTotalInCents =
    checkoutCompletedData?.request?.rooamTotalInCents ??
    (splitCheck?.amount
      ? (splitCheck?.amount + selectedTipInCents + checkFees).round()
      : 0);

  const handleFeesCollapse = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();

      setIsFeesExpanded(!isFeesExpanded);
    },
    [isFeesExpanded]
  );

  return (
    <SummaryContainer
      amountInCents={
        splitCheck?.amount ? splitCheckTotalInCents : checkTotalInCents
      }
      collapsible={collapsible}
    >
      {collapsible === true && (
        <Styled.Row className="check">
          <Styled.Column>Check:</Styled.Column>
          <Styled.Column>{check?.number}</Styled.Column>
        </Styled.Row>
      )}

      {isTruthy(discount) && (
        <Styled.Row>
          <Styled.Column>Discount:</Styled.Column>
          <Styled.Column>
            <span className="discount">
              - {discount.round().toDollars().toCurrency()}
            </span>
          </Styled.Column>
        </Styled.Row>
      )}

      <Styled.Row>
        <Styled.AccordionContainer
          className="inner-accordion"
          disabled={!splitCheck?.amount}
          square={true}
          id="subtotal-accordion"
        >
          <Styled.Title className="overwrite">
            {splitCheck?.amount && (
              <SummaryTitleValue
                title={"You're paying: "}
                value={splitCheck?.amount?.round().toDollars().toCurrency()}
                valueColumnId={"summary-subtotal-amount"}
              />
            )}
            {!splitCheck?.amount && isFalsy(paidInCents) && (
              <SummaryTitleValue
                title={"Subtotal:"}
                value={`${subtotal.round().toDollars().toCurrency()}`}
                valueColumnId={"summary-subtotal-amount"}
              />
            )}
            {!splitCheck?.amount && isTruthy(paidInCents) && (
              <SummaryTitleValue
                title={"Subtotal + Tax:"}
                value={subtotalWithTax.round().toDollars().toCurrency()}
                valueColumnId={"summary-subtotal-amount"}
              />
            )}
          </Styled.Title>
        </Styled.AccordionContainer>
      </Styled.Row>

      <Styled.Row>
        <Styled.AccordionContainer
          className="inner-accordion"
          expanded={isFeesExpanded}
          onChange={handleFeesCollapse}
          disabled={!checkFees}
          square={true}
          id="taxes-fees-accordion"
        >
          <Styled.Title className="overwrite">
            <SummaryTitleValue
              title={
                <>
                  {splitCheck ?? paidInCents
                    ? "Fees:"
                    : checkFees
                    ? "Taxes & Fees:"
                    : "Tax:"}
                  {!!checkFees && <ExpandCircleDownIcon />}
                </>
              }
              value={taxesFees.toDollars().toCurrency()}
              titleColumnId={"summary-taxes-and-fees-amount"}
            />
          </Styled.Title>
          <Styled.Details className="overwrite">
            {!!taxes && !splitCheck && !paidInCents && (
              <SummaryRow
                title={"Tax:"}
                value={taxes.round().toDollars().toCurrency()}
                valueColumnId={"summary-taxes-amount"}
              />
            )}
            {!!checkFees && (
              <SummaryRow
                title={"Fees:"}
                value={checkFees.round().toDollars().toCurrency()}
                valueColumnId={"summary-fees-amount"}
              />
            )}
            <Styled.Row>
              <p>
                This fee helps cover the convenience costs of mobile payments,
                additional security to prevent fraud and maintain a high EMV
                level to stay PCI compliant.
              </p>
            </Styled.Row>
          </Styled.Details>
        </Styled.AccordionContainer>
      </Styled.Row>

      <SummaryRow
        title={"Tip:"}
        value={selectedTip?.amount?.toCurrency() ?? "$0.00"}
        valueColumnId={"summary-tip-amount"}
      />

      {isTruthy(serviceCharges) && (
        <SummaryRow
          title={"Service Charges:"}
          value={serviceCharges?.round().toDollars().toCurrency()}
        />
      )}

      <Styled.Footer collapsible={collapsible}>
        {isTruthy(paidInCents) && !isCheckClosed && (
          <SummaryRow
            title={
              <>
                <CheckCircleIcon /> Split Payment Made:
              </>
            }
            value={`- ${paidInCents.round().toDollars().toCurrency()}`}
            rowClassname={"footer-row"}
          />
        )}
        {splitCheck?.amount && (
          <SummaryRow
            title={"Payment Total:"}
            value={splitCheckTotalInCents.toDollars().toCurrency()}
            valueColumnId={"summary-total-amount"}
            rowClassname={"total-row"}
          />
        )}
        {!splitCheck?.amount && (
          <SummaryRow
            title={"Total:"}
            value={checkTotalInCents.toDollars().toCurrency()}
            valueColumnId={"summary-total-amount"}
            rowClassname={"total-row"}
          />
        )}
      </Styled.Footer>
    </SummaryContainer>
  );
}

interface SummaryRowProps {
  rowClassname?: string;
}

interface SummaryTitleValueProps {
  title: React.ReactNode;
  value: string;
  titleColumnId?: string;
  valueColumnId?: string;
}

type RowProps = SummaryRowProps & SummaryTitleValueProps;
export const SummaryRow = ({
  title,
  value,
  titleColumnId = undefined,
  valueColumnId = undefined,
  rowClassname = undefined,
}: RowProps) => {
  return (
    <Styled.Row {...(rowClassname && { className: rowClassname })}>
      <SummaryTitleValue {...{ title, value, titleColumnId, valueColumnId }} />
    </Styled.Row>
  );
};
export const SummaryTitleValue = ({
  title,
  value,
  valueColumnId = undefined,
}: SummaryTitleValueProps) => {
  const textTestId = textTestIdMap[getTitleAsString(title)] || "";
  const amountTestId = amountTestIdMap[getTitleAsString(title)] || "";

  return (
    <>
      <Styled.Column data-testid={textTestId}>{title}</Styled.Column>
      <Styled.Column
        {...(valueColumnId && { id: valueColumnId })}
        data-testid={amountTestId}
      >
        {value}
      </Styled.Column>
    </>
  );
};

export const SummaryContainer = ({
  amountInCents,
  children,
  collapsible,
}: SummaryContainerProps): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleCollapse = useCallback(
    (event: React.SyntheticEvent<Element, Event>) => {
      event.preventDefault();
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  if (collapsible === true) {
    return (
      <Styled.AccordionContainer
        className="overwrite"
        id="summary-accordion"
        expanded={isExpanded}
        onChange={handleCollapse}
        square={true}
      >
        <Styled.Title
          className="overwrite"
          collapsible={collapsible ? "true" : "false"}
        >
          <div>
            <span>
              Show Summary <ExpandCircleDownIcon />
            </span>
            <span>{amountInCents.toDollars().toCurrency()}</span>
          </div>
        </Styled.Title>
        <Styled.Details className="overwrite">{children}</Styled.Details>
      </Styled.AccordionContainer>
    );
  }

  return <Styled.RowContainer id="summary">{children}</Styled.RowContainer>;
};

export default Summary;
