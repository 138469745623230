import ErrorState, { ErrorStateProps } from "./ErrorState";

import ErrorStateNullContext from "./ErrorStateNullContext";
import FullScreenState from "../FullScreenState/FullScreenState";

interface FullScreenErrorStateProps extends ErrorStateProps {
  noContext?: boolean;
}

const FullScreenErrorState = (props: FullScreenErrorStateProps) => {
  return (
    <FullScreenState>
      {props.noContext ? (
        <ErrorStateNullContext {...props} />
      ) : (
        <ErrorState {...props} />
      )}
    </FullScreenState>
  );
};

export default FullScreenErrorState;
