import * as Styled from "./Footer.styles";

import { ReactNode } from "react";

interface FooterProps {
  children?: ReactNode;
}

const Footer = ({ children }: FooterProps): React.ReactElement => {
  return <Styled.Container>{children}</Styled.Container>;
};

export default Footer;
