import {
  SplitCheckByItemViewModel,
  calculateSplitCheckByItem,
} from "@utils/calculateSplitCheckByItem";
import { useCallback, useEffect, useState } from "react";

import { ItemProps } from "@component/Order/OrderItem";
import { prorateDiscount } from "@utils/utilities";
import { useCheck } from "@context/CheckContext";

interface UseCheckItemsReturnType {
  amount: number;
  items: ItemProps[] | undefined;
  isEnabled: boolean;
  handleAdd: (item: ItemProps) => void;
  handleRemove: (item: ItemProps) => void;
  selectedItems: string[];
  splitByItemResult: SplitCheckByItemViewModel | undefined;
}

function useCheckItems(): UseCheckItemsReturnType {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [itemsWithTax, setItemsWithTax] = useState<ItemProps[]>();
  const [itemsWithNoTax, setItemsWithNoTax] = useState<ItemProps[]>();
  const [items, setItems] = useState<ItemProps[]>();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [amount, setAmount] = useState<number>(0);
  const [amountWithoutTaxes, setAmountWithoutTaxes] = useState<number>(0);

  const [totalTaxFromCheckItems, setTotalTaxFromCheckItems] =
    useState<number>(0);
  const [selectedItemTaxes, setSelectedItemTaxes] = useState<number>(0);

  const [splitByItemResult, setSplitByItemResult] = useState<
    SplitCheckByItemViewModel | undefined
  >();

  const { check, splitCheck } = useCheck();
  const checkItems = check?.items;

  const subtotal = check?.totals.subTotalInCents ?? 0;
  const checkDiscount = check?.totals.discountsInCents ?? 0;

  // Enable / disable split by item in UI
  useEffect(() => {
    setIsEnabled(
      !!checkItems?.filter((item) => item.preTaxPricePerUnitInCents !== null)
        .length
    );
  }, [checkItems]);

  // Determine all the items with tax
  useEffect(() => {
    if (isEnabled) {
      setItemsWithTax(
        checkItems?.filter((item) => item.preTaxPricePerUnitInCents)
      );
    }
  }, [checkItems, isEnabled]);

  // Determine total tax from check items
  useEffect(() => {
    let itemTaxes = 0;
    itemsWithTax?.map((item) => {
      itemTaxes = itemTaxes + (item?.taxInCents ?? 0 / item.quantity);
      return item;
    });

    setTotalTaxFromCheckItems(itemTaxes);
  }, [isEnabled, itemsWithTax, totalTaxFromCheckItems]);

  // Determine all the items with no tax
  useEffect(() => {
    if (!items?.length) {
      setItemsWithNoTax(
        checkItems?.filter(
          (item) => item.preTaxPricePerUnitInCents === null || !item.taxInCents
        )
      );
    }
  }, [checkItems, items?.length]);

  // ◻ TO-DO: An effect where we prorate the check tax to the items with no tax

  // Merge items with the correspondent assigned tax and apply discount
  useEffect(() => {
    const combinedItems = [...(itemsWithNoTax ?? []), ...(itemsWithTax ?? [])];

    // Apply checkDiscount to each item if exists
    const itemsWithDiscount = combinedItems.map((item) => {
      const itemPrice = item.preTaxPricePerUnitInCents ?? 0;
      const itemPriceDiscount = prorateDiscount(
        subtotal + checkDiscount,
        checkDiscount,
        itemPrice
      );

      return {
        ...item,
        preTaxPricePerUnitInCents: itemPrice - itemPriceDiscount,
      };
    });

    setItems(itemsWithDiscount);
  }, [checkDiscount, itemsWithNoTax, itemsWithTax, subtotal]);

  useEffect(() => {
    setSplitByItemResult(
      calculateSplitCheckByItem(amountWithoutTaxes, check, splitCheck)
    );
  }, [amountWithoutTaxes, check, splitCheck]);

  const handleAdd = useCallback(
    (item: ItemProps) => {
      const updatedSelectedItems = selectedItems;
      updatedSelectedItems.push(item.name);
      setSelectedItems(updatedSelectedItems);

      setAmount(
        amount +
          (item.preTaxPricePerUnitInCents ?? 0) +
          (item?.taxInCents ?? 0) / item?.quantity
      );
      setAmountWithoutTaxes(
        amountWithoutTaxes + (item.preTaxPricePerUnitInCents ?? 0)
      );
      setSelectedItemTaxes(selectedItemTaxes + (item.taxInCents ?? 0));
    },
    [amount, amountWithoutTaxes, selectedItemTaxes, selectedItems]
  );

  const handleRemove = useCallback(
    (item: ItemProps) => {
      const updatedSelectedItems = selectedItems;
      const itemIndex = updatedSelectedItems.indexOf(item.name);

      if (itemIndex > -1) {
        updatedSelectedItems.splice(itemIndex, 1);
        setAmount(
          amount -
            (item?.preTaxPricePerUnitInCents ?? 0) -
            (item?.taxInCents ?? 0) / item?.quantity
        );
        setAmountWithoutTaxes(
          amountWithoutTaxes - (item?.preTaxPricePerUnitInCents ?? 0)
        );
        setSelectedItemTaxes(selectedItemTaxes - (item.taxInCents ?? 0));
      }

      setSelectedItems(updatedSelectedItems);
    },
    [amount, amountWithoutTaxes, selectedItemTaxes, selectedItems]
  );

  return {
    amount,
    items,
    isEnabled,
    handleAdd,
    handleRemove,
    selectedItems,
    splitByItemResult,
  };
}

export default useCheckItems;
