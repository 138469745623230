import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const AmexRewards = styled(StyledContainer)`
  gap: 10px;
  margin-top: 16px;
`;

export const Column = styled(StyledContainer)`
  &.center-items {
    justify-content: center;
  }

  &.rewards-info {
    gap: initial;
    flex: 1;

    span {
      &:last-child {
        color: ${colors.primary};
      }
    }
  }
`;

export const Row = styled(StyledContainer)`
  align-items: stretch;
  display: flex;
  flex: 1;
  font-weight: 400;
  gap: 8px;
  justify-content: space-between;

  &.reward-points-row {
    justify-content: flex-start;
    gap: 16px;
    > div {
      &:first-child {
        flex: 100% 0 1;

        .MuiOutlinedInput-root {
          width: 100%;
        }
      }

      &:last-child {
        flex: 200px 0 1;
        line-height: 1.3;
      }
    }

    .MuiFormControl-root {
      width: 100%;

      .MuiFormLabel-root,
      input {
        color: ${colors.text};
      }
    }
  }

  &.terms {
    color: ${colors.textSecondary};
    font-size: 1.4rem;
  }

  .exceeded {
    color: ${colors.error};
  }
`;
