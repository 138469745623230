import Check, { checkLoader } from "@routes/Check/Check";
import PlaceCode, { placeLoader } from "@routes/PlaceCode/PlaceCode";
import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
  RouterProvider,
} from "react-router-dom";

import CheckLoader from "@routes/Check/CheckLoader";
import Checkout from "@routes/Checkout/Checkout";
import Complete from "@routes/Complete/Complete";
import Find from "@routes/Find/Find";
import FindCheckAtTable from "@routes/Find/FindCheck/FindCheckAtTable";
import FindCheckByNumber from "@routes/Find/FindCheck/FindCheckByNumber";
import FindCheckErrorHandler
  from "@routes/Find/FindCheck/FindCheckErrorHandler";
import Layout from "@component/Layout/Layout";
import RootErrorBoundary
  from "@component/RouterErrorBoundary/RootErrorBoundary";
import Split from "@routes/Split/Split";
import Qa from "@component/Qa/Qa";
import {
  findCheckAtTableLoader,
} from "@utils/qrcode/route/findCheckAtTableLoader";
import {
  findCheckByNumberLoader,
} from "@utils/qrcode/route/findCheckByNumberLoader";
import {
  findUpserveCheckByNumberLoader,
} from "@utils/qrcode/route/findUpserveCheckByNumberLoader";
import { useQueryClient } from "react-query";
import { wrapCreateBrowserRouter } from "@sentry/react";
import FullScreenLoader from "@component/Loading/FullScreenLoader";
import React from "react";
import { isDev } from "@utils/constants";
import { defaultRedirectionUrl } from "@vendor/utils/constants";
import {
  findPaytronixCheckLoader,
} from "@utils/getCheck/paytronix/findPaytronixCheckLoader";

const AppRoutes = () => {
  const queryClient = useQueryClient();

  // When a route does not have an errorElement,
  // errors will bubble up through parent routes.
  // This lets you get as granular or general as you like.

  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />} errorElement={<RootErrorBoundary />}>
        <Route
          path={"/qa"}
          element={isDev ? <Qa /> : <></>}
        />
        <Route
          path={":placeCode"}
          loader={placeLoader(queryClient)}
          element={<PlaceCode />}
        >
          <Route index element={<Find />} />
          <Route path={"find"} element={<Find />} />

          <Route
            path={"qr"}
            loader={findCheckByNumberLoader(queryClient)}
            element={<FindCheckByNumber />}
            errorElement={<FindCheckErrorHandler />}
          >
            <Route path={"micros"} element={<></>} />
            <Route path={"posi"} element={<></>} />
            <Route path={"focus"} element={<></>} />
            <Route path={"aloha"} element={<></>} />
          </Route>

          <Route
            path={":checkId"}
            loader={checkLoader(queryClient)}
            element={<CheckLoader />}
          >
            <Route index element={<Check />} />
            <Route path={"split"} element={<Split />} />
            <Route path={"checkout"} element={<Checkout />} />
            <Route path={"complete"} element={<Complete />} />
            <Route path={"complete/:sessionGuid"} element={<Complete />} />
          </Route>
        </Route>
        <Route
          path={"qr/upserve/:placePermalink/pay/"}
          loader={findUpserveCheckByNumberLoader(queryClient)}
          element={<FindCheckByNumber />}
          errorElement={<FindCheckErrorHandler />}
        ></Route>
        <Route
          path={"/:placeCode/qr/table/:tableId"}
          loader={findCheckAtTableLoader(queryClient)}
          element={<FindCheckAtTable />}
          errorElement={<FindCheckErrorHandler />}
        ></Route>

        <Route
          path={"/venue/:venueId"}
          loader={findPaytronixCheckLoader(queryClient)}
          element={<FindCheckByNumber />}
          errorElement={<FindCheckErrorHandler />}
        >
        </Route>

        <Route
          index
          loader={async () => {
            return redirect(defaultRedirectionUrl);
          }}
          element={ <FullScreenLoader title={"Getting ticket details."} />
          }/>
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRoutes;

export const isCheckPath = (): boolean => {
  const currentPath = window.location.pathname;
  if (currentPath.length === 1) return false;
  return (
    ["find", "checkout", "complete", "qr/"]
      .map((item) => currentPath.includes(item))
      .filter((value) => value).length === 0
  );
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
