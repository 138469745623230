import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import React, { useEffect } from "react";
import {
  Replay,
  configureScope,
  init,
  reactRouterV6Instrumentation,
} from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import App from "App";
import { BrowserTracing } from "@sentry/browser";
import ReactDOM from "react-dom/client";
import { environment } from "@utils/constants";
import { isTruthy } from "@utils/truthy";
import reportWebVitals from "reportWebVitals";
import sentry from "./utils/sentry";

init({
  debug: false,
  dsn: environment.sentry.dsn,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        new URL(environment.baseUrl!).host,
        /^\//,
      ],
      idleTimeout: 60000,
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new ExtraErrorData({ depth: 10 }),
    new CaptureConsole({ levels: ["warn", "error"] }),
    new Replay(),
  ],
  tracesSampleRate: Number(environment.sentry.tracesSampleRate),
  environment: environment.sentry.environment,
  release: environment.sentry.release,
  normalizeDepth: 11,
  replaysSessionSampleRate: Number(
    environment.sentry.replays.sessionSampleRate
  ),
  replaysOnErrorSampleRate: Number(
    environment.sentry.replays.onErrorSampleRate
  ),
  beforeSend(event, hint) {
    let message = event.message;

    if (
      event.logger === "console" &&
      event.message?.includes("[object Object]")
    ) {
      const objects = event.extra?.arguments as any[];
      message = objects
        ?.map((value) =>
          typeof value === "object" ? JSON.stringify(value) : value
        )
        .join(" | ");
    }

    message = `${message} | Current Path ${window.location.pathname}`;
    if (isTruthy(window.location.search)) {
      message = `${message}?${window.location.search}`;
    }

    event.message = message;
    console.debug("Sentry Event", event, hint);

    // implement sentry fingerprint
    if (event?.request && event?.request?.url) {
      const url = event.request.url;
      const normalizedPath = url.replace(
        /\/venue\/\d+\/?.*/,
        "/venue/{placeCode}/"
      );
      event.fingerprint = ["{{default}}", normalizedPath];

      const path = url.match(/\/venue\/\d+\/?.*/);

      if (path?.[0]) {
        event.tags = {
          ...event.tags,
          path: path[0],
        };
      }
    }

    return event;
  },
  beforeSendTransaction(event, hint) {
    console.debug("Sentry Transaction", event, hint);
    return event;
  },
});

configureScope((scope) => {
  scope.setTag("unique_id", sentry.uniqueId());
  scope.setTag("transaction_id", sentry.transactionId());
  scope.setTag("transaction_origin", sentry.transactionEntryPoint());
  if (sentry.transactionEntryPointParams()) {
    scope.setTag(
      "transaction_origin_params",
      sentry.transactionEntryPointParams()
    );
  }
  scope.setTransactionName(
    `${sentry.transactionEntryPoint()} - TXN ${sentry.transactionId()}`
  );
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
