import "react-spring-bottom-sheet/dist/style.css";

import * as Styled from "./SplitCheckButton.styles";

import { useCallback, useMemo, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import Button from "components/Button/Button";
import SplitCheckActionSheet from "./SplitCheckActionSheet";
import { colors } from "@theme/Themes";
import { decodeCheckId } from "../../utils/getCheck/getCheck";
import { setItem } from "../../hooks/useCookies";
import { useParams } from "react-router-dom";
import { usePlace } from "context/PlaceContext";

function SplitCheckButton(): React.ReactElement {
  const { place } = usePlace();
  const { checkId } = useParams();

  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const handleActionSheetIsOpen = useCallback(() => {
    setItem(`userSplitTip_${decodeCheckId(checkId ?? "")}`);
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen, checkId]);

  const show = useMemo(() => {
    return place?.flags.includes("place.pay.web.show.split.check");
  }, [place?.flags]);

  if (!show) {
    return <></>;
  }

  return (
    <Styled.Container direction="column">
      <Styled.Box>
        <div>
          <p>
            <strong>Want to split the check?</strong> Have each person paying
            scan the QR code then press &apos;Split the Check&apos; to split
            equally, by amount or items.
          </p>
        </div>

        <Styled.Container className="btn-container">
          <Button
            className="split-btn"
            onClick={handleActionSheetIsOpen}
            size="medium"
            text="Split the Check"
            id="split-check-button"
          />
        </Styled.Container>
      </Styled.Box>
      <BottomSheet
        id="bottom-sheet"
        open={isActionSheetOpen}
        onDismiss={handleActionSheetIsOpen}
        style={{
          backgroundColor: `${colors.lighterBackground}`,
        }}
      >
        <SplitCheckActionSheet onClose={handleActionSheetIsOpen} />
      </BottomSheet>
    </Styled.Container>
  );
}

export default SplitCheckButton;
