import * as Styled from "../SplitCheckActionSheet.styles";
import * as StyledItem from "components/Order/Order.styles";

import { Add, LocalOffer, Remove } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

import Button from "components/Button/Button";
import { itemPriceFormatter } from "components/Order/OrderItem";
import { toDollars } from "utils/utilities";
import { useCallback } from "react";
import { useCheck } from "context/CheckContext";
import useSplitCheckItems from "@hooks/useSplitCheckItems";

export function uniqueItem(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

function SplitItems() {
  const { placeCode, checkId: checkIdParam } = useParams();
  const navigate = useNavigate();

  const { check, splitCheck, setSplitCheck } = useCheck();

  const {
    amount,
    items,
    handleAdd,
    handleRemove,
    selectedItems,
    splitByItemResult,
  } = useSplitCheckItems();

  const uniqueSelectedItems = selectedItems.filter(uniqueItem);

  const finalAmount = amount + (splitByItemResult?.serviceChargesInCents ?? 0);

  const handleContinue = useCallback(() => {
    setSplitCheck({
      ...splitCheck,
      amount: finalAmount,
      taxes: splitByItemResult?.splitCheck.taxes,
      serviceCharges: splitByItemResult?.splitCheck.serviceCharges,
      selectedItems,
      itemsWithDiscount: items,
      splitCheckoutType: splitByItemResult?.splitCheck.splitCheckoutType,
    });

    sessionStorage.setItem(
      `splitCheck_${check?.number}`,
      JSON.stringify({
        ...splitCheck,
        amount: finalAmount,
        taxes: splitByItemResult?.splitCheck.taxes,
        serviceCharges: splitByItemResult?.splitCheck.serviceCharges,
        selectedItems,
        itemsWithDiscount: items,
        splitCheckoutType: splitByItemResult?.splitCheck.splitCheckoutType,
      })
    );
    navigate(`/${placeCode}/${checkIdParam}/split`);
  }, [
    check?.number,
    checkIdParam,
    finalAmount,
    items,
    navigate,
    placeCode,
    selectedItems,
    setSplitCheck,
    splitByItemResult?.splitCheck.serviceCharges,
    splitByItemResult?.splitCheck.splitCheckoutType,
    splitByItemResult?.splitCheck.taxes,
    splitCheck,
  ]);

  return (
    <>
      {items && (
        <Styled.ItemsContainer direction="column" data-body-scroll-lock-ignore>
          {items?.map((item, index) => (
            <Styled.Item key={`item_${index}`}>
              <StyledItem.ItemInfo>
                <StyledItem.ItemName>{item.name}</StyledItem.ItemName>
                <StyledItem.ItemUnitPrice>
                  @{" "}
                  {itemPriceFormatter.format(
                    (item?.preTaxPricePerUnitInCents ?? 0) / 100
                  )}{" "}
                  {item?.taxInCents
                    ? `+ tax ${itemPriceFormatter.format(
                      item?.taxInCents / item?.quantity / 100
                    )} `
                    : ""}
                  each{" "}
                  {item.discount && (
                    <span className="item-discount">
                      <LocalOffer fontSize="small" />
                    </span>
                  )}
                </StyledItem.ItemUnitPrice>
              </StyledItem.ItemInfo>
              <Styled.SplitCounter>
                <Button
                  disabled={
                    selectedItems.filter((i) => i === item.name).length === 0
                  }
                  icon={<Remove style={{ fontSize: "18px" }} />}
                  onClick={() => handleRemove(item)}
                  size="small"
                  variant="text"
                  dataTestId="minus-btn"
                />
                <strong data-testid="selected-items">
                  {selectedItems.filter((i) => i === item.name).length}
                </strong>
                <Button
                  disabled={
                    selectedItems.filter((i) => i === item.name).length >=
                    item.quantity
                  }
                  icon={<Add style={{ fontSize: "18px" }} />}
                  onClick={() => handleAdd(item)}
                  size="small"
                  variant="text"
                  id={`split-check-add-item-${item.id}`}
                  dataTestId="plus-btn"
                />
              </Styled.SplitCounter>
            </Styled.Item>
          ))}
        </Styled.ItemsContainer>
      )}
      <Styled.Box className="selected-items">
        <Styled.SelectedItemsTitle>Selected Items</Styled.SelectedItemsTitle>
        {selectedItems.length === 0 && (
          <Styled.NoItemsSelected>No items selected</Styled.NoItemsSelected>
        )}
        {selectedItems.length > 0 && (
          <Styled.SelectedItemsContainer direction="column">
            {uniqueSelectedItems.map((item, itemIndex) => {
              const itemSelectedCount = selectedItems.filter(
                (thisItem) => item === thisItem
              ).length;

              const itemPrice = items?.filter((i) => i.name === item && i)[0]
                .pricePerUnitInCents;
              const itemTax = items?.filter((i) => i.name === item && i)[0]
                .taxInCents;
              const itemTotalQty =
                items?.filter((i) => i.name === item && i)[0].quantity ?? 0;

              const itemTotalPrice =
                (itemPrice ?? 0) + (itemTax ?? 0) / itemTotalQty;

              const totalByItem = itemTotalPrice * itemSelectedCount;

              return (
                <Styled.SelectedItem key={`selected-item_${itemIndex}`}>
                  <div className="item-quantity">
                    {itemSelectedCount} x {item}
                  </div>

                  <div className="total-by-item">${toDollars(totalByItem)}</div>
                </Styled.SelectedItem>
              );
            })}
          </Styled.SelectedItemsContainer>
        )}
      </Styled.Box>
      <Styled.Summary direction="column">
        <Styled.SummaryRow>
          <span>
            Check Total:{" "}
            {!!splitByItemResult?.checkDiscountInCents && (
              <span className="discount">(with discount)</span>
            )}
          </span>

          <span data-testid="check-total">
            ${toDollars(splitByItemResult?.checkTotalInCents)}
          </span>
        </Styled.SummaryRow>
        <Styled.SummaryRow>
          <span>Due Amount:</span>{" "}
          <span data-testid="due-amount">${toDollars(splitByItemResult?.checkDueAmountInCents)}</span>
        </Styled.SummaryRow>
        <Styled.SummaryRow
          className={!splitByItemResult?.isValid ? "error" : ""}
        >
          <span>
            You&apos;re Paying
            {!splitByItemResult?.checkServiceChargesInCents &&
              " (Tax included)"}
            :
            {!!splitByItemResult?.checkServiceChargesInCents && (
              <span className="inner-row">
                (Tax + Service Charges included)
              </span>
            )}
          </span>{" "}
          <span data-testid="final-amount">${toDollars(finalAmount)}</span>
        </Styled.SummaryRow>
      </Styled.Summary>
      <Button
        disabled={amount === 0 || !splitByItemResult?.isValid}
        onClick={handleContinue}
        text="Continue"
        id="split-check-for-items-continue"
      />
    </>
  );
}

export default SplitItems;
