import { QueryFunctionContext, QueryKey } from "react-query";
import apiClient from "../api/apiClient";
import { CheckData } from "./GetCheckModels";
import { isTruthy } from "../truthy";

interface GetUpserveCheckKey {
  placePermalink: string;
  ticketNumber?: string;
  ticketId?: string
}

async function getUpserveCheck(
  context: QueryFunctionContext<QueryKey, unknown>
): Promise<CheckData> {
  const params = context.queryKey[1] as GetUpserveCheckKey;
  return await fetchCheck(params);
}

export const fetchCheck = async ( { placePermalink, ticketNumber, ticketId }: GetUpserveCheckKey): Promise<CheckData> => {
  const params = isTruthy(ticketId) ? { ticketId } : { ticketNumber };

  return await apiClient.get({
    path: `/upserve/${placePermalink}/ticket`,
    queryParams: params
  });
};

export default getUpserveCheck;
