import { CheckData } from "utils/getCheck/GetCheckModels";
import { QueryClient } from "react-query";
import { QueryKey } from "react-query/types/core/types";
import { Updater } from "react-query/types/core/utils";
import { checkQueryKeyBuilder } from "routes/Check/Check";
import { setUser } from "@sentry/react";

export const updateCheckQueryData = async <TData extends CheckData>(
  placeCode: string | undefined,
  ticketId: string | undefined,
  data: Updater<TData | undefined, TData>,
  queryClient: QueryClient
): Promise<TData> => {

  if (placeCode && data) {
    setUser({
      username: `${placeCode}_${ticketId}`
    });
  }

  return await updateQueryData(
    checkQueryKeyBuilder({ placeCode, ticketId }),
    data,
    queryClient
  );
};

export const updateQueryData = async <TData>(
  queryKey: QueryKey,
  updater: Updater<TData | undefined, TData>,
  queryClient: QueryClient
): Promise<TData> => {
  await queryClient.cancelQueries(queryKey);
  return queryClient.setQueryData(queryKey, updater, {
    updatedAt: Math.floor(new Date().getTime() / 1000),
  });
};
