import { CheckData, CheckOrigin } from "utils/getCheck/GetCheckModels";
import { useAsyncValue, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import checkOrigin from "../../../utils/getCheck/checkOrigin";
import { encodeCheckId } from "@utils/getCheck/getCheck";
import { updateCheckQueryData } from "hooks/useUpdateQueryData";
import { useQueryClient } from "react-query";
import { setTag } from "@sentry/react";

const FindCheckRedirect = () => {
  const [placeCodeNumber, setPlaceCodeNumber] = useState<string>();
  const [checkId, setCheckId] = useState<string>();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const checkData = useAsyncValue() as CheckData;

  useEffect(() => {
    const navigateToDetailsUpdatingQueryData = async (checkData: CheckData) => {
      const { placeCode, id: ticketId } = checkData;

      const updatedData = await updateCheckQueryData(
        placeCode,
        ticketId,
        checkData,
        queryClient
      );

      setTag("placeCode",placeCode);
      setTag("ticketNumber", checkData.id);
      setTag("ticketId", checkData.number);

      // to avoid infinite loop, save navigation data to the state just once
      if (updatedData && !placeCodeNumber && !checkId) {
        setPlaceCodeNumber(updatedData.placeCode);
        setCheckId(updatedData.id);
      }
    };

    if (checkData) {
      void navigateToDetailsUpdatingQueryData(checkData);
    }
  }, [checkData, checkId, placeCodeNumber, queryClient]);

  // once navigation data is available, redirect!
  useEffect(() => {
    const isQRFindAtTableURIExp = /.*\/.*\/qr\/table\/.*/;

    const origin = (): CheckOrigin =>
      (isQRFindAtTableURIExp.test(window.location.pathname) &&
        CheckOrigin.QRTable) ||
      CheckOrigin.Unknown;

    if (placeCodeNumber && checkId) {
      checkOrigin.set(placeCodeNumber, checkId, origin());
      navigate(`/${placeCodeNumber}/${encodeCheckId(checkId)}`, {
        replace: true,
      });
    }
  }, [checkId, navigate, placeCodeNumber]);

  return <></>;
};

export default FindCheckRedirect;
