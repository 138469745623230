import * as Styled from "./WrongCheck.styles";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useCallback } from "react";

function WrongCheck(): React.ReactElement {
  const { check: isCheck, placeCode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUrl = location.pathname;
  const showWrongCheck =
    currentUrl.replace(`/${placeCode}/${isCheck}`, "").length === 0;

  const handleGoFindCheck = useCallback(() => {
    navigate(`/${placeCode}/find`);
  }, [navigate, placeCode]);

  if (!showWrongCheck) {
    return <></>;
  }

  return (
    <Styled.Container>
      <p>
        <strong>Wrong Check?</strong>{" "}
        <button type="button" onClick={handleGoFindCheck}>
          Click here to go back.
        </button>
      </p>
    </Styled.Container>
  );
}

export default WrongCheck;
