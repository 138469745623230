import StyledBox from "@GlobalComponents/StyledBox";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledBox)`
  margin: 24px 0 18px;
  background-color: ${colors.warning};
  border-color: ${colors.warningBorder};
`;

export const WarningBox = styled(StyledContainer)`
  color: ${colors.warningText};
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 0 0 12px;
  margin: 0 0 8px 0;

  h4 {
    color: ${colors.text};
    margin: 0;
  }

  svg {
    color: ${colors.warningDark};
    font-size: 32px;
  }
`;

export const Copy = styled.div`
  color: ${colors.warningText};
  font-size: 1.4rem;
  line-height: 1.3;
  padding: 0 4px;

  strong {
    color: ${colors.text};
  }
`;
