import styled from "styled-components";

interface StyledContainerProps {
  direction?: "column" | "column-reverse" | "row" | "row-reverse";
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? "initial"};
  align-items: ${({ direction }) =>
    direction !== "column" ? "center" : "initial"};
`;

export default StyledContainer;
