import CheckProvider from "context/CheckContext";
import PlaceProvider from "context/PlaceContext";
import ProviderTreeBuilder from "@context/ProviderTreeBuilder";

interface ProvidersProps {
  children: React.ReactNode;
}

function Providers({ children }: ProvidersProps) {
  const ProviderTree = ProviderTreeBuilder([[PlaceProvider], [CheckProvider]]);
  return <ProviderTree>{children}</ProviderTree>;
}

export default Providers;
