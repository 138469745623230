import * as Styled from "./TipInput.styles";

import { Divider, InputAdornment } from "@mui/material";
import React, { useEffect, useRef } from "react";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { environment } from "@utils/constants";

const { isWebTab } = environment;

// TODO: Should we use a lower value like 1000?

interface TipInputProps {
  defaultValue?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  resetInput?: boolean;
}

function TipInput({
  defaultValue,
  onChange,
  resetInput,
}: TipInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  const numberMask = createNumberMask({
    prefix: "",
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2,
    allowNegative: false,
    allowLeadingZeroes: false,
  });

  useEffect(() => {
    if (resetInput && inputRef.current?.value) {
      inputRef.current.value = "";
    }
  }, [resetInput]);

  return (
    <Styled.Container direction="column">
      {isWebTab ? (
        <Divider>
          <span className="divider-text" data-testid="customTip-label">
            or
          </span>
        </Divider>
      ) : (
        <Styled.Label htmlFor="custom-tip" data-testid="customTip-label">
          Or Custom Tip Amount
        </Styled.Label>
      )}

      <Styled.InputContainer>
        <InputAdornment aria-hidden="true" position="start">
          $
        </InputAdornment>
        <Styled.Input
          mask={numberMask}
          className="form-control"
          placeholder={defaultValue ?? "Enter Custom Tip Amount"}
          guide={false}
          id="custom-tip"
          inputMode="decimal"
          onBlur={() => {}}
          onChange={onChange}
          type="number"
        />
      </Styled.InputContainer>
    </Styled.Container>
  );
}

export default TipInput;
