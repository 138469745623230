import {
  SplitCheckProps,
  splitCheckoutTypeEnum,
} from "../context/CheckContext";

import { CheckData } from "./getCheck/GetCheckModels";

export interface SplitCheckByAmountViewModel {
  specificAmountInCents: number;

  checkTotalInCents: number;
  checkDueAmountInCents: number;

  checkDiscountInCents: number;
  checkServiceChargesInCents: number;

  isValid: boolean;

  splitCheck: SplitCheckProps;
}

export const calculateSplitCheckByAmount = (
  specificAmountInDollars: number | undefined,
  check: CheckData | undefined,
  splitCheck: SplitCheckProps | undefined
): SplitCheckByAmountViewModel => {
  const specificAmountInCents = parseInt(
    ((specificAmountInDollars ?? 0) * 100).toFixed()
  );

  const checkTaxes = check?.totals?.taxInCents ?? 0;
  const checkServiceCharges = check?.totals?.serviceChargesInCents ?? 0;
  const checkTotal = check?.totals.totalInCents ?? 0;

  const checkDiscount = check?.totals?.discountsInCents ?? 0;

  const checkDueAmountInCents = check?.totals.dueInCents ?? 0;

  const isValid = specificAmountInCents <= checkDueAmountInCents;

  // TODO: taxes, service charges and discount should consider prorate values
  const splitCheckData = {
    ...splitCheck,
    amount: specificAmountInCents,

    taxes: checkTaxes,
    serviceCharges: checkServiceCharges,
    discount: checkDiscount,
    splitCheckoutType: splitCheckoutTypeEnum.BY_AMOUNT,
  };

  return {
    specificAmountInCents,
    checkTotalInCents: checkTotal,
    checkDueAmountInCents,

    checkDiscountInCents: checkDiscount,
    checkServiceChargesInCents: checkServiceCharges,

    isValid,
    splitCheck: splitCheckData,
  };
};
