import { getItem, setItem } from "@hooks/useCookies";
import { ulid } from "ulid";

const transactionId = ulid();
const transactionEntryPoint = window.location.pathname;
const transactionEntryPointParams = window.location.search;

const sentry = {
  uniqueId: () => {
    let uniqueId = getItem("ulid");
    if (!uniqueId) {
      uniqueId = ulid();
      setItem("ulid", uniqueId, 365);
    }
    return uniqueId;
  },
  transactionId: () => transactionId,
  transactionEntryPoint: () => transactionEntryPoint,
  transactionEntryPointParams: () => transactionEntryPointParams
};

export default sentry;
