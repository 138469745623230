import { QueryKey, useQuery } from "react-query";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import getPlace, { PlaceDataProps as PlaceData } from "utils/getPlace";

import { isTruthy } from "../utils/truthy";
import { queryKeys } from "../utils/constants";
import { setTag } from "@sentry/react";
import { useParams } from "react-router-dom";

interface PlaceContextProps {
  place: PlaceData | undefined;
  error: unknown;
  isError: boolean;
  isLoading: boolean;
  selectedTip: number;
  setSelectedTip: (tip: number) => void;
}

export const PlaceContext = createContext<PlaceContextProps | null>(null);

PlaceContext.displayName = "PlaceContext";

function PlaceProvider(props: any): React.ReactElement {
  const [place, setPlace] = useState<PlaceData | undefined>(undefined);
  const [selectedTip, setSelectedTip] = useState<number>(0);

  const { placeCode } = useParams();

  const placeQueryKey = [queryKeys.placeData, { placeCode }] as QueryKey;

  const {
    data: placeData,
    error,
    isLoading,
    isError,
  } = useQuery(placeQueryKey, getPlace, {
    enabled: isTruthy(placeCode),
    staleTime: 5 * 60_000,
    onError: (error) =>
      console.error(
        "Place Context > Query - Failure getting Place details",
        error
      ),
  });

  // Save place data on context state
  useEffect(() => {
    if (!place && placeData) {
      setPlace(placeData);
    }
  }, [place, placeData]);

  // set placeCode sentry tag
  useEffect(() => {
    if (placeCode) {
      setTag("placeCode", placeCode);
    }
  }, [placeCode]);

  const value: PlaceContextProps = useMemo(
    () => ({
      place,
      error,
      isError,
      isLoading,
      selectedTip,
      setSelectedTip,
    }),
    [error, isError, isLoading, place, selectedTip]
  );

  return (
    <PlaceContext.Provider value={value} {...props} />
  ) as React.ReactElement;
}

// Hook to consume PlaceProvider
export function usePlace(): PlaceContextProps {
  const context = useContext(PlaceContext);

  if (context === undefined) {
    throw new Error("usePlace must be used within a PlaceProvider");
  }

  if (context === null) {
    throw new Error("PlaceProvider supplied null context");
  }

  return context;
}

export default PlaceProvider;
