import {
  Await,
  Outlet,
  useAsyncValue,
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import React, { PropsWithChildren, Suspense, useEffect } from "react";

import { CheckData } from "../../utils/getCheck/GetCheckModels";
import CheckErrorHandler from "./CheckErrorHandler";
import { CheckLoaderData } from "utils/qrcode/route/models";
import FullScreenLoader from "components/Loading/FullScreenLoader";

const CheckLoader = () => {
  const { checkData } = useLoaderData() as CheckLoaderData;
  return (
    <>
      <Suspense
        fallback={<FullScreenLoader title={"Getting ticket details."} />}
      >
        <Await resolve={checkData} errorElement={<CheckErrorHandler />}>
          <CheckState>
            <Outlet />
          </CheckState>
        </Await>
      </Suspense>
    </>
  );
};

const CheckState = (props: PropsWithChildren) => {
  const checkData = useAsyncValue() as CheckData;
  const navigate = useNavigate();
  const { placeCode, checkId, sessionGuid } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {

    if (checkData && checkData.status === "CLOSED" && placeCode && checkId) {
      navigate({
        pathname: `/${placeCode}/${checkId}/complete/${sessionGuid ?? ""}`,
        search: searchParams.toString(),
      },
      { replace: true },
      );
      
    }
  }, [checkData, checkId, navigate, placeCode, searchParams, sessionGuid]);

  return <>{props.children}</>;
};

export default CheckLoader;
