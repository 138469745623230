import { useAsyncError, useRouteError } from "react-router-dom";

import MessageBox from "components/MessageBox/MessageBox";
import VendorLogin from "@vendor/components/VendorLogin/VendorLogin";
import { messageForError } from "utils/errors/messageForError";

const CheckErrorHandler = () => {
  const asyncError = useAsyncError();
  const routerError = useRouteError();

  const error = asyncError ?? routerError;

  return (
    <>
      <VendorLogin />
      {error && <MessageBox {...messageForError(asyncError ?? routerError)} />}
    </>
  );
};

export default CheckErrorHandler;
