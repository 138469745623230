import * as Styled from "../SplitCheckActionSheet.styles";

import { Add, Remove } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Button from "components/Button/Button";
import calculateSplitCheckEqually from "utils/calculateSplitCheckEqually";
import { toDollars } from "utils/utilities";
import { useCheck } from "context/CheckContext";

interface SplitEquallyProps {
  onHandleClose: () => void;
}

function SplitEqually({ onHandleClose }: SplitEquallyProps) {
  const [counter, setCounter] = useState<number>(2);

  const location = useLocation();
  const isSplitPage = location.pathname.includes("split");

  const { placeCode, checkId: checkIdParam } = useParams();
  const navigate = useNavigate();

  const { check, setSplitCheck, splitCheck } = useCheck();

  const splitEquallyResult = calculateSplitCheckEqually(
    check,
    splitCheck,
    counter
  );

  const handleAdd = useCallback(() => {
    counter <= 7 && setCounter(counter + 1);
  }, [counter]);

  const handleRemove = useCallback(() => {
    counter >= 3 && setCounter(counter - 1);
  }, [counter]);

  const handleContinue = useCallback(() => {
    setSplitCheck(splitEquallyResult.splitCheck);

    sessionStorage.setItem(
      `splitCheck_${check?.number}`,
      JSON.stringify(splitEquallyResult.splitCheck)
    );

    if (isSplitPage) {
      onHandleClose();
      return;
    }

    navigate(`/${placeCode}/${checkIdParam}/split`);
  }, [
    setSplitCheck,
    splitEquallyResult.splitCheck,
    check?.number,
    isSplitPage,
    navigate,
    placeCode,
    checkIdParam,
    onHandleClose,
  ]);

  return (
    <>
      <Styled.Box>
        <Styled.BoxTitle>Split How Many Ways?</Styled.BoxTitle>
        <Styled.SplitCounter>
          <Button
            disabled={counter <= 2}
            icon={<Remove style={{ fontSize: "18px" }} />}
            onClick={handleRemove}
            size="small"
            variant="text"
            dataTestId="minus-btn"
          />
          <strong>{counter}</strong>
          <Button
            disabled={counter === 8}
            icon={<Add style={{ fontSize: "18px" }} />}
            onClick={handleAdd}
            size="small"
            variant="text"
            id="split-check-add"
            dataTestId="plus-btn"
          />
        </Styled.SplitCounter>
      </Styled.Box>
      <Styled.Summary direction="column">
        <Styled.SummaryRow>
          <span>
            Check Total:{" "}
            {!!splitEquallyResult.checkDiscountInCents && (
              <span className="discount">(with discount)</span>
            )}
          </span>

          <span>${toDollars(splitEquallyResult?.checkTotalInCents)}</span>
        </Styled.SummaryRow>
        <Styled.SummaryRow>
          <span>You&apos;re Paying:</span>{" "}
          <span>${toDollars(splitEquallyResult?.equallyAmountInCents)}</span>
        </Styled.SummaryRow>
      </Styled.Summary>
      <Button
        onClick={handleContinue}
        text="Continue"
        id="split-check-equally-continue"
      />
    </>
  );
}

export default SplitEqually;
