import apiClient from "@utils/api/apiClient";

export const applyPaytronixRewardPoints = async ( request: PaytronixTicketRewardRequest): Promise<PaytronixAppliedReward> => {

  return  await apiClient.post({
    path: "/paytronix/reward/apply",
    body: JSON.stringify(request),
  });

};

export interface PaytronixTicketRewardRequest {
  placeCode: string
  ticketId: string
  memberId: string
  rewardDollarsInCentsToUse: number
}
export interface PaytronixAppliedReward {
  message: string;
  discountId: string;
}
