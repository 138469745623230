import { LogoContainer } from "./VendorLogo.styles";
import { colors } from "@theme/Themes";

const VendorLogo = (): React.ReactElement => {
  return (
    <LogoContainer title="vendor-logo">
      <svg
        style={{
          width: "110px",
        }}
        xmlns={"http://www.w3.org/2000/svg"}
        viewBox={"0 0 432.01 104.21"}
      >
        <path
          fill={colors.vendorColor}
          d={
            "M52.59,39.71a5.51,5.51,0,0,0-4-1.43H43v10.6h5.51a5.39,5.39,0,0,0,4-1.5,5.14,5.14,0,0,0,1.47-3.77A5.21,5.21,0,0,0,52.59,39.71Z"
          }
        ></path>
        <path
          fill={colors.vendorColor}
          d={
            "M0,0V104.21H432V0ZM65.56,71.2A21.27,21.27,0,0,1,44.9,55.83H42.64V70.51h-9v-40H48.82a18,18,0,0,1,6.24,1,12.86,12.86,0,0,1,4.5,2.7,10.76,10.76,0,0,1,2.69,4,13.3,13.3,0,0,1,.89,4.85,12.87,12.87,0,0,1-1.75,6.8,12.27,12.27,0,0,1-4.92,4.47,9.21,9.21,0,0,1-2.54,1,12.51,12.51,0,0,0,11.63,7.28Zm45.6-.65H82.69v-40h27.16v7.92H91.69v7.79h13.67v7.67H91.69v8.63h19.47ZM156.84,64a12.68,12.68,0,0,1-3.3,4,15.29,15.29,0,0,1-4.91,2.62,19.33,19.33,0,0,1-6.09.93A24.77,24.77,0,0,1,137,71a22.63,22.63,0,0,1-4.48-1.5,20.11,20.11,0,0,1-3.61-2.14,19.28,19.28,0,0,1-2.76-2.47l5.88-6.32a19.69,19.69,0,0,0,1.85,1.72,15.69,15.69,0,0,0,2.36,1.59A14,14,0,0,0,139.11,63a11.41,11.41,0,0,0,3.31.46,7.39,7.39,0,0,0,2.06-.3,6.61,6.61,0,0,0,1.88-.88,4.7,4.7,0,0,0,1.36-1.38,3.4,3.4,0,0,0,.52-1.84,3.82,3.82,0,0,0-2.19-3.46,30.2,30.2,0,0,0-6.91-2.44,18.38,18.38,0,0,1-4.27-1.56,13.7,13.7,0,0,1-3.39-2.47,10.31,10.31,0,0,1-3-7.47,12.64,12.64,0,0,1,.91-4.76A11.1,11.1,0,0,1,132.14,33a13.3,13.3,0,0,1,4.61-2.71,19.31,19.31,0,0,1,6.52-1,21.26,21.26,0,0,1,5.45.63,19.61,19.61,0,0,1,4,1.5,14.51,14.51,0,0,1,2.73,1.81c.7.62,1.24,1.12,1.61,1.47l-5.28,5.79c-.45-.37-1-.76-1.54-1.18a14.85,14.85,0,0,0-2-1.17,14.42,14.42,0,0,0-2.4-.91,10.34,10.34,0,0,0-2.76-.36,6.29,6.29,0,0,0-1.91.3,7,7,0,0,0-1.69.82,4.19,4.19,0,0,0-1.21,1.2,2.59,2.59,0,0,0-.46,1.48A3.78,3.78,0,0,0,140.05,44a21.52,21.52,0,0,0,6,2.11,27.06,27.06,0,0,1,4.4,1.47A14.63,14.63,0,0,1,154.3,50a11.35,11.35,0,0,1,2.73,3.7,12.61,12.61,0,0,1,1,5.33A10.5,10.5,0,0,1,156.84,64Zm37.1-10.28V70.55h-9.26V53.82L170.53,30.56h10.34l8.78,15.11,8.53-15.11h9.92Zm233.22,45.6H236.35V4.85H427.16Z"
          }
        ></path>
        <path
          fill={colors.vendorColor}
          d={
            "M285.71,56.91h4.57a22.44,22.44,0,0,0,6.21-.86,15.41,15.41,0,0,0,5.31-2.7,13.41,13.41,0,0,0,3.66-4.65,15,15,0,0,0,1.38-6.61,11.72,11.72,0,0,0-.84-4.43A10.81,10.81,0,0,0,303.62,34h0a11.4,11.4,0,0,0-3.76-2.5,13,13,0,0,0-5-.92H281.12l-7.73,40h9.66Zm3.62-18.64h3.93a4.67,4.67,0,0,1,3.41,1.17,4.25,4.25,0,0,1,1.17,3.17c0,2.23-.61,3.84-1.83,4.8a7.47,7.47,0,0,1-4.76,1.48h-4Z"
          }
        ></path>
        <path
          fill={colors.vendorColor}
          d={
            "M324.14,61.7h12l1.09,8.83h10.31l-6.13-39.35-.1-.63H330.91L309.1,70.08l-.25.45h10.79Zm10.34-22.51L336.21,54H327Z"
          }
        ></path>
        <polygon
          fill={colors.vendorColor}
          points="363.62 70.17 363.55 70.53 373.5 70.53 376.7 54 394.9 30.55 383.4 30.55 373.36 44.34 368.63 30.55 357.89 30.55 366.74 53.86 363.62 70.17"
        />
      </svg>
    </LogoContainer>
  );
};

export default VendorLogo;
