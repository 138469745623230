import {
  LoaderFunctionArgs,
  Params,
  defer,
  useLocation,
} from "react-router-dom";
import {
  Query,
  getQueryDataFetchingIfNeeded,
} from "utils/getQueryDataFetchingIfNeeded";
import { QueryClient, QueryKey } from "react-query";
import getCheck, {
  GetCheckRequest,
  decodeCheckId,
  fetchCheckBySessionGuid,
} from "utils/getCheck/getCheck";
import { getItem, setItem } from "@hooks/useCookies";
import { useEffect, useMemo } from "react";

import { CheckData } from "utils/getCheck/GetCheckModels";
import TopAdBanner from "@vendor/components/VendorAdBanners/TopAdBanner";
import ViewCheck from "components/ViewCheck/ViewCheck";
import { queryKeys } from "utils/constants";
import { setUser } from "@sentry/react";
import tryOrUndefined from "../../utils/tryOrUndefined";
import { updateCheckQueryData } from "@hooks/useUpdateQueryData";
import usePageTitle from "hooks/usePageTitle";
import { usePlace } from "context/PlaceContext";

function Check(): React.ReactElement {
  usePageTitle("Your Check");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { place } = usePlace();

  const showBanner = useMemo(() => {
    return place?.flags.includes("place.resy.pay.banner.feed");
  }, [place?.flags]);

  const bannerMessage = () => (
    <p>
      Earn <strong>4X Membership Rewards® points</strong> at restaurants
      worldwide
    </p>
  );

  return (
    <>
      <TopAdBanner contained show={showBanner} message={bannerMessage()} />
      <ViewCheck />
    </>
  );
}

export default Check;

export const checkLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    if (params.sessionGuid) {
      return defer({
        checkData: getCheckBySessionGuidIfNeeded(params, queryClient),
      });
    }

    if (params.placeCode && params.checkId) {
      const request = checkData(
        {
          placeCode: params.placeCode,
          ticketId: decodeCheckId(params.checkId),
        },
        queryClient
      );
      return defer({ checkData: request });
    }

    throw new Error("Missing required Place code or Check number");
  };

const getCheckBySessionGuidIfNeeded = async (
  params: Params,
  queryClient: QueryClient
): Promise<CheckData> => {
  const { sessionGuid, placeCode, checkId } = params;
  let checkData: CheckData;

  if (sessionGuid && placeCode && checkId) {
    checkData =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      tryOrUndefined(() => JSON.parse(getItem(sessionGuid)!)) ??
      (await fetchCheckBySessionGuid(sessionGuid, placeCode));

    setItem(sessionGuid, JSON.stringify(checkData), 30);

    return await updateCheckQueryData<CheckData>(
      placeCode,
      decodeCheckId(checkId),
      checkData,
      queryClient
    );
  } else {
    throw new Error(
      "Missing required parameters | getCheckBySessionGuidIfNeeded(*)"
    );
  }
};

export const checkData = async (
  request: GetCheckRequest,
  queryClient: QueryClient
): Promise<CheckData> => {
  setUser({
    username: `${request.placeCode}_${
      request.ticketId ?? request.ticketNumber
    }`,
  });

  return await getQueryDataFetchingIfNeeded(
    checkQueryBuilder(request),
    queryClient
  );
};

export const checkQueryBuilder = (request: GetCheckRequest): Query => ({
  queryKey: checkQueryKeyBuilder(request),
  queryFn: getCheck,
});

export const checkQueryKeyBuilder = ({
  placeCode,
  ticketId,
  ticketNumber,
}: GetCheckRequest) =>
  [queryKeys.checkFeed, { placeCode, ticketId, ticketNumber }] as QueryKey;
