import { colors, fonts } from "@theme/Themes";

import Button from "components/Button/Button";
import { RadioGroup } from "@mui/material";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 16px 32px;
  width: 100%;
`;

export const ContentContainer = styled(StyledContainer)`
  gap: 16px;

  &.split-by-item {
    justify-content: flex-start;
    height: 100%;
    max-height: calc(100vh - 130px);
    flex: 1;
    flex-grow: 0;

    > div:first-child {
      padding-top: 0;
    }

    > .MuiButtonBase-root {
      margin-top: auto;
    }
  }
`;

export const ContentHeader = styled(StyledContainer)`
  justify-content: space-between;
  padding-top: 16px;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  h3 {
    font-size: 1.8rem;
    margin: 0;
    flex: 1;
  }

  .back-btn {
    flex: 18px 0 0;
    min-width: 18px;
    opacity: 1 !important;
    padding: 2px 4px !important;

    &.Mui-disabled,
    &.Mui-disabled:hover {
      opacity: 1;
      svg {
        opacity: 0.2;
      }
    }

    svg {
      fill: ${colors.utilityGray};
      height: 18px;
      width: 18px;
    }
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    font-family: ${fonts.textFont} !important;
    font-size: 1.6rem;
    min-width: initial !important;
    padding: 0 !important;
    width: max-content !important;
  }
`;

export const SplitOptions = styled(RadioGroup)`
  gap: 8px;
`;

export const Box = styled(StyledContainer)`
  border: 1px solid ${colors.border};
  border-radius: 6px;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;

  &.selected-items {
    color: ${colors.textSecondary};
    border-radius: 8px;
    flex-direction: column;
    flex: 65px 1 1;
    justify-content: flex-start;
    padding: 8px 0;
  }

  label {
    font-family: ${fonts.textFont};
    font-size: 16px;
    flex: 1;
    gap: 8px;
    margin: 0;
    width: 100%;

    span {
      font-weight: 500;
    }
  }

  .MuiFormControlLabel-label {
    flex: 1;
  }

  .MuiRadio-root {
    margin-right: 0;
    padding: 0;

    .icon {
      fill: ${colors.primary};
      border-radius: 50%;
      overflow: hidden;
      height: 20px;
      width: 20px;
    }

    .MuiSvgIcon-root {
      fill: ${colors.primary};
      height: 24px;
      margin-right: -2px;
      width: 24px;
    }
  }
`;

export const BoxTitle = styled.strong`
  font-family: ${fonts.titlesFont};
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
`;

export const SelectedItemsTitle = styled.span`
  background-color: ${colors.paper};
  padding: 0 16px;
  margin-top: -22px;
`;

export const NoItemsSelected = styled.span`
  align-items: center;
  display: flex;
  flex: 1;
  font-weight: 500;
  line-height: 1;
`;

export const SplitCounter = styled(StyledContainer)`
  border: 1px solid ${colors.text};
  border-radius: 4px;

  strong {
    text-align: center;
    width: 10px;
  }

  button {
    color: ${colors.text};
    padding: 6px 10px !important;

    &:disabled {
      color: ${colors.textDisabled};
    }
  }
`;

export const Summary = styled(StyledContainer)`
  color: ${colors.textSecondary};
  font-size: 14px;
  font-weight: 500;
  gap: 2px;
`;

export const SummaryRow = styled(StyledContainer)`
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;

  .discount {
    color: ${colors.success};
  }

  &.error {
    color: ${colors.error};
  }

  .inner-row {
    color: ${colors.textDisabled};
    font-size: 14px;
    display: block;
  }
`;

export const AmountFieldContainer = styled(StyledContainer)`
  label {
    align-items: flex-start;
    gap: 4px;
    justify-content: flex-start;
    margin: 0;
    width: 100%;

    .MuiOutlinedInput-root {
      width: 100%;
    }

    span {
      font-family: ${fonts.titlesFont};
      font-size: 12px;
      font-weight: 500;
    }
  }
`;

export const ItemsContainer = styled(StyledContainer)`
  background-color: ${colors.background};
  border-bottom: 1px solid ${colors.borderMedium};
  border-top: 1px solid ${colors.borderMedium};
  flex: 1;
  gap: 8px;
  margin-left: -16px;
  min-height: 112px;
  overflow-y: scroll;
  padding: 16px;
  //max-height: calc(100vh - 242px);

  position: relative;
  width: calc(100% + 32px);

  &:before {
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
    content: " ";
    height: 0px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
`;

export const ItemsContentContainer = styled(StyledContainer)`
  gap: 8px;
  padding: 16px;
  overflow-y: scroll;
  height: max-content;
  width: 100%;
`;

export const Item = styled(Box)`
  background-color: ${colors.paper};
  border-color: ${colors.borderMedium};
  border-radius: 5px;
  padding: 12px 12px 12px 16px;

  > div span {
    font-size: 14px;
    line-height: 18px;
  }
`;

interface ItemsContainerShadowProps {
  position: "top" | "bottom";
}

export const ItemsContainerShadow = styled.div<ItemsContainerShadowProps>`
  border: 1px solid red;
  bottom: ${({ position }) => (position === "bottom" ? 0 : "initial")};
  height: 4px;
  left: 0;
  position: absolute;
  top: ${({ position }) => (position === "top" ? 0 : "initial")};
  width: 100%;
`;

export const SelectedItemsContainer = styled(StyledContainer)`
  align-items: flex-start;
  gap: 2px;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 0 16px;
  width: 100%;
`;

export const SelectedItem = styled(StyledContainer)`
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
`;
