import { Box } from "@mui/material";
import { colors } from "@theme/Themes";
import styled from "styled-components";

const StyledBox = styled(Box)`
  border: 1px solid ${colors.border};
  border-radius: 6px;
  padding: 22px;
`;

export default StyledBox;
