import * as Styled from "./TopAdBanner.styles";

import GoldCardImg from "@vendor/assets/images/gold-card-small.png";
import trackGaEvent from "@utils/trackGaEvent";
import { useCallback } from "react";
import { usePlace } from "@context/PlaceContext";

interface TopAdBannerProps {
  message: React.ReactNode | string;
  contained?: boolean;
  show?: boolean;
}

function TopAdBanner({ contained, message, show }: TopAdBannerProps) {
  const { place } = usePlace();

  const handleClick = useCallback(() => {
    trackGaEvent("Clicked AMEX Banner", {
      description: `PlaceCode: ${place?.code}`,
    });
  }, [place?.code]);

  const Component = () => (
    <Styled.Card>
      <Styled.Image src={GoldCardImg} alt="American Express Gold Card" />
      <Styled.Copy>
        <span>{message}</span>
      </Styled.Copy>
      <Styled.Button
        href="#"
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
      >
        Apply Now
      </Styled.Button>
    </Styled.Card>
  );

  if (!show) {
    return <></>;
  }

  if (contained) {
    return (
      <Styled.Contained>
        <Component />
      </Styled.Contained>
    );
  }

  return <Component />;
}

export default TopAdBanner;
