import SplitCheck from "components/SplitCheck/SplitCheck";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import usePageTitle from "hooks/usePageTitle";

const Split = (): React.ReactElement => {
  usePageTitle("Split the Check");

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <SplitCheck />;
};

export default Split;
