import { RESY_LOGIN_API_KEY } from "../utils/constants";
import { useAuth } from "@context/AuthContext";
import { useCallback } from "react";
import { useCheck } from "@context/CheckContext";
import useCookie from "@hooks/useCookies";

interface UseAuthentication {
  signIn: (placeCode: string) => void;
  signOut: () => void;
}

const useAuthentication = (): UseAuthentication => {
  const [_, updateAuthCookie] = useCookie("authToken");
  const { didSignOut } = useAuth();

  const { setPaymentMethods, setResyInstance } = useCheck();

  const signIn = useCallback(
    (placeCode: string) => performSignIn(placeCode),
    []
  );

  const signOut = useCallback(() => {
    console.info("Signing Out!");
    updateAuthCookie(undefined);
    setPaymentMethods(undefined);
    setResyInstance(undefined);
    didSignOut();
  }, [didSignOut, setPaymentMethods, setResyInstance, updateAuthCookie]);

  return { signIn, signOut } as const;
};

const performSignIn = (placeCode: string) => {
  if (RESY_LOGIN_API_KEY && placeCode) {
    window.resyWidget.openModal({
      apiKey: RESY_LOGIN_API_KEY,
      venueId: placeCode,
      authSuccessRedirectUrl: window.location.href,
    });
  } else {
    console.error("Missing data required to perform Login");
  }
};

export default useAuthentication;
