export const RESY_LOGIN_API_KEY = process.env.REACT_APP_RESY_LOGIN_API_KEY;
export const REACT_APP_RESY_LOGIN_URL =
  process.env.REACT_APP_RESY_LOGIN_URL ?? "";

export const vendorColor = "#ff462d";
export const vendorDefaultTitle = "Resy Pay: Right This Way";

export const rateExperienceTitle="How was your payment experience?";
export const rateExperienceBadHref="https://docs.google.com/forms/d/e/1FAIpQLSft4ESHLsV-WKOJDnignccpqvIGugq8f4_6nW8WCgOX6c4hgQ/viewform";
export const rateExperienceGreatHref= "https://docs.google.com/forms/d/e/1FAIpQLSfJDnFutPPnSR9V2-ZoSvaYtNUXKQbzz5yTR-nbBf_RLvlmvw/viewform";

export const defaultRedirectionUrl = "https://resy.com";

export const applePayLabels = {
  paymentSheet: {
    totalLabel: "Resy"
  },
  validation: {
    displayName: "Resy Pay"
  }
};
