import apiClient from "./api/apiClient";

export const getTicketNoItems = async (): Promise<any> => {
  try {
    return await apiClient.get({
      path: "/qa/omnivore/ticket",
      headers: {
        "Content-Type": "application/json"
      },
    });
  } catch (error) {
    console.error("Error creating ticket with one item", error);
    throw error;
  }
};

export const getTicketOneItem = async (): Promise<any> => {
  try {
    return await apiClient.get({
      path: "/qa/omnivore/ticket/oneItem",
      headers: {
        "Content-Type": "application/json"
      },
    });
  } catch (error) {
    console.error("Error creating ticket with one item", error);
    throw error;
  }
};

export const getTicketMultipleItem = async (): Promise<any> => {
  try {
    return await apiClient.get({
      path: "/qa/omnivore/ticket/multipleItems",
      headers: {
        "Content-Type": "application/json"
      },
    });
  } catch (error) {
    console.error("Error creating ticket with one item", error);
    throw error;
  }
};