import { QueryFunctionContext, QueryKey } from "react-query";

import apiClient from "./api/apiClient";
import { isFalsy } from "./truthy";

interface PaidAmount {
  totalPaidInCents: number;
}

interface GetPaiAmountKey {
  sessionGuid: string;
}

async function getPaidAmount(
  context: QueryFunctionContext<QueryKey, unknown>
): Promise<PaidAmount> {
  const { sessionGuid } = context.queryKey[1] as GetPaiAmountKey;
  
  if (isFalsy(sessionGuid)) {
    throw new Error(`getPaidAmount > Invalid sessionGuid: ${sessionGuid}`);
  }

  return await apiClient.get({ path: `/${sessionGuid}/ticket/paid-amount` });
}

export default getPaidAmount;
