import StyledContainer from "theme/GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  background-color: #006fcf;
  color: "#ffffff";
  font-size: 1.4rem;
  gap: 6px;
  line-height: 1.15;
  padding: 18px 0;
  position: relative;
  text-align: center;
  z-index: 1;

  &:after {
    background: #006fcf;
    content: " " !important;
    height: 100%;
    left: -50%;
    position: absolute;
    top: 0;
    width: calc(200%);
    z-index: -1;
  }

  .sup {
    font-size: 13px;
    margin-left: 2px;
  }
`;

export const Icon = styled.div`
  width: 24px;
`;

export const CopyText = styled.div`
  color: #ffffff;
`;
