import { CheckOrigin } from "./getCheck/GetCheckModels";
import apiClient from "./api/apiClient";
import checkOrigin from "./getCheck/checkOrigin";
import paytronixUtils from "@utils/getCheck/paytronix/paytronixUtils";
import { splitCheckoutTypeEnum } from "@context/CheckContext";

interface CheckoutProcessingProps {
  paymentMethod: "CREDIT_CARD" | "APPLE_PAY" | "GOOGLE_PAY";
  placeCode: string;
  ticketId: string;
  tipInCents: number;
  amexPointsInDollarAmount: number | undefined;
  nonce?: string;
  resyAuthToken?: string;
  resyPaymentMethodId?: string;
  token?: string;
  deviceData?: string;
  customAmountInCents?: number;
  ticketNumber: string;
  splitCheckoutType?: splitCheckoutTypeEnum;
}

async function checkoutProcessing(
  request: CheckoutProcessingProps
): Promise<any> {
  const {
    paymentMethod,
    tipInCents,
    amexPointsInDollarAmount,
    nonce,
    resyAuthToken,
    resyPaymentMethodId,
    token,
    deviceData,
    customAmountInCents,
    placeCode,
    ticketId,
    ticketNumber,
    splitCheckoutType,
  } = request;

  const data = JSON.stringify({
    nonce,
    paymentMethod,
    resyPaymentMethodId,
    tipInCents,
    amexPointsInDollarAmount,
    token,
    deviceData,
    customAmountInCents,
    placeCode,
    ticketId,
    qrTable:
      checkOrigin.get(placeCode, ticketNumber) ===
      CheckOrigin.QRTable.toString(),
    paytronix: paytronixUtils.getData(ticketId),
    splitCheckoutType,
  });

  try {
    return await apiClient.post({
      path: `${customAmountInCents ? "/split" : ""}/checkout`,
      headers: {
        "Content-Type": "application/json",
        ...(resyAuthToken && { resyAuthToken }),
      },
      body: data,
    });
  } catch (error) {
    console.error("Error processing Checkout", data, error);
  }
}

export default checkoutProcessing;
