import * as Styled from "./VendorTopBar.styles";

import { ArrowBack } from "@mui/icons-material";
import BrandLogo from "@vendor/components/VendorLogo/VendorLogo";
import Button from "@component/Button/Button";
// import TopBarLoginButton from "./TopBarLoginButton";
// import TopBarSignOutButton from "./TopBarSignOutButton";
// import { useAuth } from "@context/AuthContext";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePlace } from "@context/PlaceContext";

const VendorTopBar = () => {
  const { place } = usePlace();
  // const { isAuthenticated, user } = useAuth();

  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Styled.Wrapper className="overwrite" position="fixed">
        <Styled.Container className="overwrite">
          <Button
            className="back-btn"
            icon={<ArrowBack fontSize="large" color="inherit" />}
            onClick={handleGoBack}
            size="small"
            variant="text"
          />
          <BrandLogo />
          <Styled.EmptyDiv />
          {/* {!isAuthenticated && <TopBarLoginButton />}
          {isAuthenticated && <TopBarSignOutButton user={user} />} */}
        </Styled.Container>
        {place?.name && (
          <Styled.LocationName>
            <h1>{place?.name}</h1>
          </Styled.LocationName>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default VendorTopBar;
