import "utils/extensions/number";

import * as Styled from "./Tips.styles";

import React, { useCallback, useEffect, useState } from "react";
import { tipPercentage, tipRate } from "utils/utilities";
import useCookie, { setItem } from "hooks/useCookies";

import { PlaceDataProps } from "utils/getPlace";
import TipInput from "components/Inputs/TipInput/TipInput";
import { Typography } from "@mui/material";
import { useCheck } from "context/CheckContext";
import { usePlace } from "context/PlaceContext";

export interface TipProps {
  amount?: number;
  percentage: number;
}

interface TipsComponentProps {
  onNoTip?: (show: boolean) => void;
  showNoTipError?: boolean;
}

const Tips = ({
  onNoTip,
  showNoTipError,
}: TipsComponentProps): React.ReactElement => {
  const [tips, setTips] = useState<TipProps[]>([]);
  const [inputVal, setInputVal] = useState<string | undefined>(undefined);

  const { check, selectedTip, updateSelectedTip, splitCheck } = useCheck();
  const { place } = usePlace();

  const showCustomTip = !place?.flags.includes(
    "place.pay.web.no.custom.tip.default"
  );
  const isSplitPage = !!splitCheck?.amount;

  const [userTip, _] = useCookie(`userTip_${check?.id}`);

  const checkSubtotal = isSplitPage
    ? splitCheck?.amount ?? 0
    : check?.totals.subTotalInCents ?? 0;

  useEffect(() => {
    setTips(tipsForPlace(place, checkSubtotal) ?? defaultTips(checkSubtotal));
  }, [checkSubtotal, place]);

  const handleTipSelection = useCallback(
    (event: React.MouseEvent<HTMLElement>, selected: TipProps) => {
      if (isSplitPage) {
        setItem(`userSplitTip_${check?.id}`, JSON.stringify(selected), 1);
      } else {
        setItem(`userTip_${check?.id}`, JSON.stringify(selected), 1);
      }

      updateSelectedTip(selected);
      setInputVal(undefined);
      onNoTip?.(false);
    },
    [isSplitPage, onNoTip, updateSelectedTip, check?.id]
  );

  const handleCustomTip = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const percentage = tipPercentage(event.target.value, check?.totals);
      const amount =
        event.target.value.length > 0 ? parseFloat(event.target.value) : 0;

      const newTip = {
        percentage,
        amount,
      };

      const inputValInCurrency = event.target.value;

      if (isSplitPage) {
        setItem(`userSplitTip_${check?.id}`, JSON.stringify(newTip), 1);
      } else {
        setItem(`userTip_${check?.id}`, JSON.stringify(newTip), 1);
      }

      updateSelectedTip(newTip);
      setInputVal(inputValInCurrency);
      onNoTip?.(false);
    },
    [check?.totals, isSplitPage, onNoTip, updateSelectedTip, check?.id]
  );

  useEffect(() => {
    if (userTip) {
      if (isSplitPage) {
        updateSelectedTip(undefined);
      } else {
        const savedTip: TipProps = JSON.parse(userTip);

        const tipToSave = tips.filter(
          (tip) => tip.amount === savedTip.amount
        )[0];

        if (tipToSave) {
          // active tip button based on saved selection
          updateSelectedTip(tipToSave);
        } else {
          updateSelectedTip(undefined);
        }
      }
    }
  }, [
    check?.totals.subTotalInCents,
    isSplitPage,
    tips,
    updateSelectedTip,
    userTip,
  ]);

  useEffect(() => {
    const TipsTitle = document.getElementById("select-your-tip");

    if (showNoTipError) {
      TipsTitle?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [showNoTipError]);

  return (
    <Styled.Container
      className={showNoTipError ? "error" : ""}
      direction="column"
      id="tip-selection"
    >
      <Typography id="select-your-tip" className="tips-title" variant="h3">
        <a href="#select-your-tip">Select Your Tip</a>
      </Typography>
      <Styled.ToggleList
        size="small"
        value={selectedTip}
        exclusive
        onChange={handleTipSelection}
      >
        {tips.map((tip, index) => {
          const isSelected = tip.percentage === selectedTip?.percentage;

          if (tip.percentage < 0) {
            return null;
          }

          return (
            <Styled.TipButton
              className="overwrite"
              key={`tip-option-${index}`}
              value={tip}
              selected={isSelected}
              id={`tip-option-${tip.percentage}`}
              data-testid={`tip-option-${tip.percentage}`}
            >
              {tip.percentage === 0 && (
                <>
                  <strong className="smaller">No Tip</strong>
                </>
              )}
              {tip.percentage > 0 && (
                <>
                  <strong>{tip.percentage}%</strong>
                  <span>${tip.amount?.toFixed(2)}</span>
                </>
              )}
            </Styled.TipButton>
          );
        })}
      </Styled.ToggleList>
      {showCustomTip && (
        <TipInput
          onChange={handleCustomTip}
          resetInput={inputVal === undefined}
          defaultValue={inputVal ?? "Enter Custom Tip Amount"}
        />
      )}
      {showNoTipError && (
        <span className="no-tip-message" data-testid="no-tip-selected">
          Select your tip
        </span>
      )}
    </Styled.Container>
  );
};

export default Tips;

const tipsForPlace = (
  place: PlaceDataProps | undefined,
  checkSubTotalInCents: number | undefined
) =>
  place?.tipsData?.tips?.map((tip: TipProps): TipProps => {
    return {
      percentage: tip.percentage,
      amount: tipRate(tip.percentage, checkSubTotalInCents),
    };
  });

const defaultTips = (checkSubTotalInCents: number | undefined): TipProps[] => [
  {
    percentage: 18,
    amount: tipRate(18, checkSubTotalInCents),
  },
  {
    percentage: 20,
    amount: tipRate(20, checkSubTotalInCents),
  },
  {
    percentage: 22,
    amount: tipRate(22, checkSubTotalInCents),
  },
];
