import {
  SplitCheckProps,
  splitCheckoutTypeEnum,
} from "../context/CheckContext";

import { CheckData } from "./getCheck/GetCheckModels";

export interface SplitCheckEquallyViewModel {
  equallyAmountInCents: number;
  checkTotalInCents: number;
  checkDiscountInCents: number;
  splitCheck: SplitCheckProps;
}

const calculateSplitCheckEqually = (
  check: CheckData | undefined,
  splitCheck: SplitCheckProps | undefined,
  splitCount: number | undefined
): SplitCheckEquallyViewModel => {
  splitCount = splitCount ?? 1;

  const checkTotalInCents = check?.totals.totalInCents ?? 0;
  const amountToPay = checkTotalInCents / splitCount;

  const taxesInCents = (check?.totals.taxInCents ?? 0) / splitCount;
  const serviceChargesInCents = check?.totals?.serviceChargesInCents
    ? check?.totals?.serviceChargesInCents / splitCount
    : 0;
  const discountInCents = check?.totals?.discountsInCents
    ? check?.totals?.discountsInCents / splitCount
    : 0;

  // TODO: taxes, service charges and discount should consider prorate values
  //  because when there are no partial payments then its easy, you divide by splitCount,
  //  otherwise taxes and other stuff might not be correct
  const splitCheckData: SplitCheckProps = {
    ...splitCheck,
    amount: amountToPay,

    taxes: taxesInCents,
    serviceCharges: serviceChargesInCents,
    discount: discountInCents,
    splitCheckoutType: splitCheckoutTypeEnum.EQUALLY,
  };

  return {
    equallyAmountInCents: amountToPay,
    checkTotalInCents,
    checkDiscountInCents: discountInCents,
    splitCheck: splitCheckData,
  };
};

export default calculateSplitCheckEqually;
