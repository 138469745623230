import {
  Await,
  LoaderFunctionArgs,
  Outlet,
  defer,
  useLoaderData,
} from "react-router-dom";
import { QueryClient, QueryKey } from "react-query";
import React, { Suspense } from "react";
import getPlace, { PlaceDataProps, isValidPlaceCode } from "utils/getPlace";

import FullScreenErrorState from "components/Error/FullScreenErrorState";
import FullScreenLoader from "components/Loading/FullScreenLoader";
import { InvalidPlaceCodeError } from "@utils/api/errors";
import { getPlaceRedirectionIfNeeded } from "@vendor/utils/getPlaceRedirectionIfNeeded";
import { isTruthy } from "@utils/truthy";
import { queryKeys } from "utils/constants";

function PlaceCode(): React.ReactElement {
  const { placeData } = useLoaderData() as PlaceLoaderData;

  return (
    <>
      <Suspense fallback={<FullScreenLoader />}>
        <Await
          resolve={placeData}
          errorElement={
            <FullScreenErrorState
              title={"Unable to get required details"}
              showReloadPage={true}
            />
          }
        >
          <Outlet />
        </Await>
      </Suspense>
    </>
  );
}

export default PlaceCode;

export const placeLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const placeRedirectionResponse = getPlaceRedirectionIfNeeded(params);
    if (placeRedirectionResponse) {
      return placeRedirectionResponse;
    }

    if (isTruthy(params.placeCode) && isValidPlaceCode(params.placeCode)) {
      const request = placeData(params.placeCode, queryClient);
      return defer({ placeData: request });
    }

    return await Promise.reject(
      new InvalidPlaceCodeError("placeLoader >", params.placeCode)
    );
  };

const placeData = async (
  placeCode: string,
  queryClient: QueryClient
): Promise<PlaceDataProps> => {
  return await new Promise((resolve) => {
    const data =
      queryClient.getQueryData<PlaceDataProps>(queryKeys.placeData) ??
      queryClient.fetchQuery(placeDataQuery(placeCode));

    resolve(data);
  });
};

const placeDataQuery = (placeCode: string) => ({
  queryKey: [queryKeys.placeData, { placeCode }] as QueryKey,
  queryFn: getPlace,
});

interface PlaceLoaderData {
  placeData: PlaceDataProps;
}
