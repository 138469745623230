import { environment } from "./constants";

interface GetPinCodeProps {
  number: string;
  resend?: boolean;
}

async function getPinCode({ number, resend }: GetPinCodeProps) {
  const url = environment.baseUrl;
  try {
    if (url) {
      const response = await fetch(`${url}/login/code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number,
          resend,
        }),
      });

      if (!response.ok) {
        throw new Error(
          `getPinCode > Request failed with status ${response.status}`
        );
      }

      const sessionToken = response.headers.get("x-rooam-session");

      // Validate if sessionToken is not null before returning
      if (sessionToken === null) {
        throw new Error("getPinCode > Failed to get session token");
      }

      return sessionToken;
    }
  } catch (error) {
    console.error(`getPinCode > Failed to send PIN Code: ${error}`);
  }
}

export default getPinCode;
