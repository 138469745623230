// eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Number {
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  toDollars(): number;
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  toCurrency(): string;
  // eslint-disable-next-line @typescript-eslint/method-signature-style
  round(): number;
}

// eslint-disable-next-line no-extend-native
Number.prototype.toDollars = function () {
  return (this as number / 100);
};

// eslint-disable-next-line no-extend-native
Number.prototype.toCurrency = function () {
  return this.toLocaleString("en-us", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// eslint-disable-next-line no-extend-native
Number.prototype.round = function () {
  const value = parseFloat(this.toFixed(2));
  return Math.round(value);
};
