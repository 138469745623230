import { Totals } from "./getCheck/GetCheckModels";

export function toDollars(money: number | undefined): string {
  if (!money) {
    return (0).toFixed(2);
  }

  return (money / 100).toFixed(2);
}

export function toCents(money: number | undefined): number {
  if (!money) {
    return 0;
  }

  return money * 100;
}

export function tipRate(
  tipValue: number,
  subtotal?: number | undefined
): number {
  const subTotalOrZero = subtotal ? subtotal / 100 : 0;
  const tip = (subTotalOrZero * tipValue) / 100;
  const tipWithDecimals = Math.floor(tip * 100) / 100;

  return tipWithDecimals;
}

export function tipPercentage(tip: string, totals: Totals | undefined): number {
  if (!tip || !totals?.subTotalInCents) {
    return 0;
  }

  const tipToNumber = parseFloat(tip) * 100;

  return (tipToNumber / totals?.subTotalInCents) * 100;
}

export function prorateServiceCharges(
  checkSubtotal: number,
  checkServiceCharges: number,
  amountPaid: number
) {
  const serviceChargeRate = checkServiceCharges / checkSubtotal;
  return serviceChargeRate * amountPaid;
}

export function prorateDiscount(
  checkSubTotal: number,
  discount: number,
  amountPaid: number
) {
  const discountPortion = (amountPaid / checkSubTotal) * discount;
  return discountPortion;
}
