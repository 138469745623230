import * as Styled from "components/Button/Button.styles";

import React, { MouseEvent, ReactNode, useCallback } from "react";

export interface ButtonProps {
  ariaLabel?: string;
  className?: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  dataTestId?: string;
  disabled?: boolean;
  id?: string;
  icon?: ReactNode;
  isLoading?: boolean;
  node?: ReactNode;
  onClick?: (
    event?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset";
  text?: string;
  variant?: "text" | "outlined" | "contained";
}

function Button({
  ariaLabel,
  className,
  color,
  dataTestId,
  disabled,
  id,
  icon,
  isLoading,
  node,
  onClick,
  size,
  text,
  type,
  variant,
}: ButtonProps): React.ReactElement {
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      onClick?.(event);
    },
    [onClick]
  );

  return (
    <Styled.Btn
      aria-label={ariaLabel}
      id={id}
      className={className}
      color={color ?? "primary"}
      data-testid={dataTestId ?? "PrimaryButton"}
      disabled={disabled ?? isLoading}
      onClick={handleOnClick}
      size={size ?? "large"}
      type={type}
      variant={variant ?? "contained"}
    >
      {isLoading ? (
        <Styled.Loader
          data-testid="PrimaryButtonLoader"
          color="inherit"
          size={18}
          role="status"
        />
      ) : (
        icon
      )}

      {isLoading
        ? text === ("Sent" || "Send")
          ? ""
          : "Please Wait..."
        : !text
          ? node
          : text}
    </Styled.Btn>
  );
}

export default Button;
