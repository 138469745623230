import { useEffect, useState } from "react";

import { isTruthy } from "utils/truthy";
import { useCheck } from "context/CheckContext";
import { environment } from "@utils/constants";

const useGetDisabledPaymentOptions = (): DisabledPaymentOption => {
  const { check } = useCheck();
  const [isCreditCardPaymentDisabled, setIsCreditCardPaymentDisabled] =
    useState<boolean>(false);
  const [isAllPaymentsDisabled, setIsAllPaymentsDisabled] =
    useState<boolean>(false);

  useEffect(() => {
    if (isTruthy(check) && isTruthy(check.uiSettings?.disabledPaymentOptions)) {
      const creditCardDisabled =
        check.uiSettings?.disabledPaymentOptions.includes("CREDIT_CARD") ??
        false;
      const allDisabled =
        check.uiSettings?.disabledPaymentOptions.includes("ALL") ?? false;
      setIsCreditCardPaymentDisabled(creditCardDisabled);
      setIsAllPaymentsDisabled(allDisabled);
    }
  }, [check]);

  return {
    creditCard: isCreditCardPaymentDisabled || environment.payments.creditCard.disabled,
    all: isAllPaymentsDisabled,
  };
};

export default useGetDisabledPaymentOptions;

export interface DisabledPaymentOption {
  creditCard: boolean;
  all: boolean;
}
