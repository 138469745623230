import { ItemProps as Item } from "components/Order/OrderItem";

export interface Totals {
  subTotalInCents: number;
  discountsInCents?: number;
  taxInCents?: number;
  taxesFees?: number;
  feesInCents?: number;
  tip?: number;
  serviceChargesInCents?: number;
  credit?: number;
  paidInCents: number;
  tipsInCents?: number;
  dueInCents?: number;
  totalInCents?: number
}

export interface Payment {
  type: string;
  amountInCents: number;
  tipInCents?: number;
  feesInCents?: number;
  rooamPayment: boolean;
}

export interface CheckData {
  id: string;
  number: string;
  items: Item[];
  totals: Totals;
  status: string;
  placeCode: string;
  uiSettings?: UISettings;
  payments: Payment[]
  paytronix?: Paytronix
}

export interface Paytronix {
  memberId: string;
  availableReward: number;
  appliedDiscount: number;
}

export type DisabledPaymentOption = "CREDIT_CARD" | "ALL";
export interface UISettings {
  disabledPaymentOptions?: DisabledPaymentOption[];
}

export enum CheckOrigin {
  QRTable = "QR_TABLE",
  Unknown = "UNKNOWN"
}
