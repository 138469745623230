import React from "react";

interface IconProps {
  children: React.ReactNode;
}

export function Icon(props: IconProps): React.ReactElement {
  const StyledChildrenAsSVGIcon = (): any =>
    React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(props.children as React.ReactElement, {
          className: "svg-icon",
        });
      }
      return child;
    });

  return <span className="icon">{StyledChildrenAsSVGIcon()}</span>;
}

export interface IconFillColorProps {
  fillColor?: string;
  strokeColor?: string;
}
