/* eslint-disable indent */
import * as Styled from "./PaymentDetails.styles";

import { isFalsy, isTruthy } from "../../utils/truthy";
import { useCallback, useEffect, useState } from "react";

import AddPaymentMethod from "components/AddPaymentMethod/AddPaymentMethod";
import Button from "components/Button/Button";
import ComponentLoader from "../Loading/ComponentLoader";
import Error from "components/Error/Error";
import PaymentMethodList from "components/PaymentMethodList/PaymentMethodList";
import braintree from "braintree-web";
import checkoutProcessing from "utils/checkoutProcessing";
import getCheckoutAmountRequestData from "../../utils/getCheckoutAmountRequestData";
import trackGaEvent from "utils/trackGaEvent";
import { useAuth } from "context/AuthContext";
import { useCheck } from "../../context/CheckContext";
import useCookie from "hooks/useCookies";
import { useNavigateToCompletePage } from "../../hooks/useNavigateToCompletePage";
import { usePlace } from "context/PlaceContext";
import { useSearchParams } from "react-router-dom";

export type ErrorType =
  | "cardholderName"
  | "cvv"
  | "expirationDate"
  | "number"
  | "postalCode"
  | "statusError"
  | "checkoutError"
  | "tokenizeError"
  | "HOSTED_FIELDS_FIELDS_EMPTY"
  | "ApplePayNotEnabled";

interface PaymentDetailsProps {
  clientInstance: braintree.Client | undefined;
}

function PaymentDetails({
  clientInstance: client,
}: PaymentDetailsProps): React.ReactElement {
  const [error, setError] = useState<ErrorType | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [processNonAmexPayment, setProcessNonAmexPayment] =
    useState<boolean>(false);

  const [isProcessingCheckout, setIsProcessingCheckout] =
    useState<boolean>(false);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const {
    check,
    isLoadingPaymentMethods,
    paymentMethods,
    selectedMethod,
    setCheckoutComplete,
    selectedTip,
    splitCheck,
  } = useCheck();

  const { isAuthenticated } = useAuth();
  const { place } = usePlace();
  const { toCompletePage } = useNavigateToCompletePage();

  // 🚨 Disabled Resy Authentication as their Braintree vault is no longer available
  // const [authCookie, _] = useCookie("authToken");
  const [dataCollector, setDataCollector] = useState<braintree.DataCollector>();

  const checkoutAmountRequest = getCheckoutAmountRequestData(
    check,
    splitCheck,
    selectedTip
  );

  const [searchParams] = useSearchParams();
  const isAmexPwp = searchParams.toString() === "AmexPwp=true";

  const createDataCollector = useCallback(async () => {
    if (client) {
      await braintree.dataCollector
        .create({ client })
        .then((dataCollectorInstance) => {
          setDataCollector(dataCollectorInstance);
        })
        .catch((error) =>
          console.error("Error creating Data Collector instance", error)
        );
    }
  }, [client]);

  const handleShowPaymentMethod = useCallback(() => {
    setShowAddPaymentMethod(true);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      !paymentMethods && setShowAddPaymentMethod(true);
    }

    if (
      (!isAuthenticated && paymentMethods?.length === 0) ||
      (!isAuthenticated && !paymentMethods)
    ) {
      setShowAddPaymentMethod(true);
    }

    if (client) {
      createDataCollector().catch((error) => console.error(error));
    }
  }, [isAuthenticated, paymentMethods, client, createDataCollector]);

  const handlePayment = useCallback(async () => {
    setIsProcessingCheckout(true);

    if (selectedMethod && place?.code && check?.id) {
      const { token, type, amexPointsInDollarAmount } = selectedMethod;
      const paymentMethod = (() => {
        switch (type) {
          case "Apple Pay":
            return "APPLE_PAY";
          case "Google Pay":
            return "GOOGLE_PAY";

          default:
            return "CREDIT_CARD";
        }
      })();

      const response = await checkoutProcessing({
        paymentMethod,
        placeCode: place?.code,
        ticketId: check?.id,
        tipInCents: checkoutAmountRequest.tipInCents,
        amexPointsInDollarAmount: amexPointsInDollarAmount ?? undefined,
        token,
        deviceData: dataCollector?.deviceData,
        customAmountInCents: checkoutAmountRequest.customAmountInCents,
        ticketNumber: check?.number,
        splitCheckoutType: splitCheck?.splitCheckoutType,
      });

      if (response?.charged) {
        setCheckoutComplete(response);
        toCompletePage(checkoutAmountRequest, response);

        trackGaEvent("Clicked Pay Check Button", {
          description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
          event_label: isAmexPwp && "AmexPwP",
        });

        return;
      }

      setError("checkoutError");
      setIsProcessingCheckout(false);
      setProcessNonAmexPayment(false);

      trackGaEvent("Error: Pay Check Button on processing Checkout", {
        description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
      });
    }
  }, [
    check?.id,
    check?.number,
    checkoutAmountRequest,
    dataCollector?.deviceData,
    isAmexPwp,
    place?.code,
    selectedMethod,
    setCheckoutComplete,
    splitCheck?.splitCheckoutType,
    toCompletePage,
  ]);

  const handleReadyForPayment = useCallback(() => {
    setProcessNonAmexPayment(true);
  }, []);

  useEffect(() => {
    if (processNonAmexPayment && !isProcessingCheckout) {
      void handlePayment();
    }
  }, [handlePayment, isProcessingCheckout, processNonAmexPayment]);

  return (
    <Styled.Container direction="column">
      <Styled.Title>Payment Details</Styled.Title>

      {error && error === "checkoutError" && (
        <Error>
          <strong>Error:</strong> There was an error processing your payment.
          Please try again or contact your server.
        </Error>
      )}

      {error &&
        error !== ("statusError" && "checkoutError") &&
        isFalsy(errorMessage) && (
          <Error>
            <strong>Validation Error:</strong> An issue occurred when validating
            your card. Please check below and try again.
          </Error>
        )}

      {error &&
        error !== ("statusError" && "checkoutError") &&
        isTruthy(errorMessage) && (
          <Error>
            <strong>Validation Error:</strong> {errorMessage}
          </Error>
        )}

      {isLoadingPaymentMethods && <ComponentLoader />}

      {paymentMethods && paymentMethods?.length > 0 && (
        <>
          <PaymentMethodList list={paymentMethods} />
          {!showAddPaymentMethod && !processNonAmexPayment && (
            <Button
              onClick={handleShowPaymentMethod}
              size="medium"
              text={isAuthenticated ? "Add Payment" : "Change Payment Method"}
              variant="outlined"
            />
          )}
        </>
      )}

      {showAddPaymentMethod && (
        <AddPaymentMethod
          clientInstance={client}
          error={error}
          onHandlePayment={() => {
            void handleReadyForPayment();
          }}
          setError={setError}
          setErrorMessage={setErrorMessage}
          setShowAddPaymentMethod={setShowAddPaymentMethod}
        />
      )}

      {paymentMethods &&
        paymentMethods?.length > 0 &&
        !showAddPaymentMethod && (
          <Button
            className="cta-button"
            disabled={showAddPaymentMethod}
            isLoading={isProcessingCheckout}
            onClick={() => {
              void handlePayment();
            }}
            text={`Pay Check: ${checkoutAmountRequest.rooamTotalInCents
              .toDollars()
              .toCurrency()}`}
          />
        )}
    </Styled.Container>
  );
}

export default PaymentDetails;
