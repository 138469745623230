import { FormControl } from "@mui/material";
import styled from "styled-components";

const StyledFormControl = styled(FormControl)`
  .MuiFormHelperText-root {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 18px;
    margin: 2px 0;
  }
`;

export default StyledFormControl;
