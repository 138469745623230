import { Radio } from "@mui/material";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const RadioButton = styled(Radio)`
  &.overwrite {
    display: flex;
    margin-right: 1.6rem;

    &.Mui-checked {
      .MuiSvgIcon-root {
        background-color: ${colors.primary};
        border-radius: 50%;
        fill: ${colors.paper};
        overflow: hidden;
        padding: 6px;
      }
    }

    .MuiSvgIcon-root {
      height: 34px;
      width: 34px;
    }
  }
`;
