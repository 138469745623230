import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ComponentLoader from "@component/Loading/ComponentLoader";
import FullScreenErrorState from "@component/Error/FullScreenErrorState";
import getNextScreen from "@utils/getNextScreen";
import { isDev } from "@utils/constants";
import { isValidPlaceCode } from "@utils/getPlace";

const TabInitialRouteWrapper = ({ children }: { children?: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialRoute, setInitialRoute] = useState<string | undefined>();
  const [shouldRedirect, setShouldRedirect] = useState(true);

  const { placeCode } = useParams();
  const baseUrl = `/venue/${placeCode}`;
  const navigate = useNavigate();

  const isValidPlace = isValidPlaceCode(placeCode);

  const location = useLocation();
  const pathname = location.pathname;
  const qaRegex = /^\/qa(\/.*)?$/;
  const isQAPage = isDev && qaRegex.test(pathname);
  const isCompletePage = pathname.includes("complete");

  const xRooamSession = localStorage.getItem("x-rooam-session");

  useEffect(() => {
    if (!xRooamSession && !isQAPage && isValidPlace) {
      setInitialRoute(`${baseUrl}/phone`);
    } else if (!initialRoute && !isQAPage && !isCompletePage && isValidPlace) {
      getNextScreen()
        .then((initialPath) => {
          setInitialRoute(`${baseUrl}/${initialPath}`);
        })
        .catch((error) => {
          console.error(error);
          setInitialRoute(`${baseUrl}/phone`);
        });
    } else if (isCompletePage) {
      setInitialRoute(`${baseUrl}/complete`);
    }
  }, [
    baseUrl,
    initialRoute,
    isCompletePage,
    isQAPage,
    isValidPlace,
    xRooamSession,
  ]);

  useEffect(() => {
    if (initialRoute && shouldRedirect && !isQAPage) {
      setIsLoading(false);
      setShouldRedirect(false);
      navigate(initialRoute);
    }
  }, [initialRoute, isQAPage, navigate, shouldRedirect]);

  if (!isValidPlace && !isQAPage) {
    return (
      <FullScreenErrorState
        title={"Unable to load Place. PlaceCode not found"}
      />
    );
  }

  if (isLoading && !isQAPage) {
    return <ComponentLoader />;
  }

  return <>{children}</>;
};

export default TabInitialRouteWrapper;
