import * as Styled from "./Error.styles";

import ErrorIcon from "@mui/icons-material/Error";

interface ErrorProps {
  children: React.ReactNode;
}

function Error({ children }: ErrorProps): React.ReactElement {
  return (
    <Styled.ErrorMessage
      className="overwrite"
      icon={<ErrorIcon />}
      severity="error"
    >
      <span>{children}</span>
    </Styled.ErrorMessage>
  );
}

export default Error;
