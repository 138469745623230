import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  // padding-top: 36px;

  h2 {
    font-size: 1.8rem;
    margin: 0 0 18px;
  }
`;

export const InnerContainer = styled(Container)`
  gap: 5px;

  button {
    margin-top: 5px;
  }
`;

export const FieldLabel = styled.span`
  font-size: 1.6rem;
  font-weight: 600;
`;
