import * as Styled from "./RateExperience.styles";
import {
  rateExperienceBadHref,
  rateExperienceGreatHref,
  rateExperienceTitle,
} from "@vendor/utils/constants";

function RateExperience(): React.ReactElement {
  return (
    <Styled.Container>
      <Styled.Title>{ rateExperienceTitle }</Styled.Title>

      <Styled.LinkContainer>
        <Styled.Link
          href={rateExperienceBadHref}
          rel="noreferrer"
          target="_blank"
        >
          Bad
        </Styled.Link>
        <Styled.Link
          href={rateExperienceGreatHref}
          rel="noreferrer"
          target="_blank"
        >
          Great
        </Styled.Link>
      </Styled.LinkContainer>
    </Styled.Container>
  );
}

export default RateExperience;
