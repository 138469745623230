import * as Styled from "./AmountInput.styles";

import React, { useEffect, useRef } from "react";

import { InputAdornment } from "@mui/material";

interface AmountInputProps {
  dataTestId?: string;
  defaultValue?: string;
  id?: string;
  disabled?: boolean;
  maxAmount?: number;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  resetInput?: boolean;
}

function AmountInput({
  dataTestId,
  defaultValue,
  id,
  disabled,
  maxAmount,
  onChange,
  resetInput,
}: AmountInputProps): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (resetInput && inputRef.current?.value) {
      inputRef.current.value = "";
    }
  }, [resetInput]);

  return (
    <Styled.Input
      disabled={disabled}
      className="overwrite"
      defaultValue={defaultValue}
      inputRef={inputRef}
      id={id}
      name="amount-input"
      onChange={onChange}
      startAdornment={<InputAdornment position="start">$</InputAdornment>}
      type="number"
      inputProps={{
        type: "number",
        min: 0,
        max: maxAmount,
        "data-testid": dataTestId,
      }}
    />
  );
}

export default AmountInput;
