import { match, P } from "ts-pattern";

import { isFalsy, isTruthy } from "utils/truthy";

export interface Params {
  ticketNumber?: string;
  ticketId?: string
  total: string;
  origin: string;
}

export const paramsFromQRCodeRequest = (
  request: Request
): Params | undefined => {
  // Checking match for url
  const params: Params | undefined = match(request.url)
    .with(microsPath, () => microsParams(request))
    .with(posiPath, () => posiParams(request))
    .with(focusPath, () => focusParams(request))
    .with(alohaPath, () => alohaParams(request))
    .with(upservePath, () => upserveParams(request))
    .otherwise(() => undefined);

  return params;
};
const regexpPredicate = (expr: RegExp) =>
  P.when((str: string): str is never => expr.test(str));
const microsFullRegexp = /(?:.*\/.*\/qr\/micros.*check).(.+)/;
const posiFullRegexp = /(?:.*\/.*\/qr\/posi.*check).(.+)/;
const focusFullRegexp = /(?:.*\/.*\/qr\/focus.*check).(.+)/;
const alohaFullRegexp = /(?:.*\/.*\/qr\/aloha.*check).(.+)/;
const upserveFullRegexp = /.*\/.*\/qr\/upserve\/.*\/pay\/.*/;
const microsPath = regexpPredicate(microsFullRegexp);
const posiPath = regexpPredicate(posiFullRegexp);
const focusPath = regexpPredicate(focusFullRegexp);
const alohaPath = regexpPredicate(alohaFullRegexp);
const upservePath = regexpPredicate(upserveFullRegexp);
export const microsParams = (request: Request): Params | undefined => {
  let params: Params | undefined;

  const queryAttrs = request.url.match(microsFullRegexp)?.[1].split("&");
  const ticketNumber = queryAttrs?.[0];
  const ticketTotal = queryAttrs?.[1];

  if (isTruthy(ticketNumber) && isTruthy(ticketTotal)) {
    params = {
      ticketNumber,
      total: ticketTotal,
      origin: "micros",
    };
  } else {
    console.error(
      `Micros QR Code is Not valid for query string. Url: ${request.url}`
    );
  }

  return params;
};
export const posiParams = (request: Request): Params | undefined => {
  let params: Params | undefined;

  const queryAttrs = request.url.match(posiFullRegexp)?.[1].split("&");
  const ticketNumber = queryAttrs?.[4];
  const ticketTotal = queryAttrs?.[5];

  if (isTruthy(ticketNumber) && isTruthy(ticketTotal)) {
    params = {
      ticketNumber,
      total: ticketTotal,
      origin: "posi",
    };
  } else {
    console.error(
      `Positouch QR Code is Not valid for query string. Url: ${request.url}`
    );
  }

  return params;
};
export const focusParams = (request: Request): Params | undefined => {
  let params: Params | undefined;

  const queryAttrs = request.url.match(focusFullRegexp)?.[1].split("-$");
  const ticketNumber = queryAttrs?.[0];
  const ticketTotal = queryAttrs?.[1];

  if (isTruthy(ticketNumber) && isTruthy(ticketTotal)) {
    params = {
      ticketNumber,
      total: ticketTotal,
      origin: "focus",
    };
  } else {
    console.error(
      `Focus QR Code is Not valid for query string. Url: ${request.url}`
    );
  }

  return params;
};
export const alohaParams = (request: Request): Params | undefined => {
  let params: Params | undefined;

  const queryAttrs = request.url.match(alohaFullRegexp)?.[1].split("&");
  const ticketNumber = queryAttrs?.[0];
  const ticketTotal = queryAttrs?.[1];

  if (isTruthy(ticketNumber) && isTruthy(ticketTotal)) {
    params = {
      ticketNumber,
      total: ticketTotal,
      origin: "aloha",
    };
  } else {
    console.error(
      `Aloha QR Code is Not valid for query string. Url: ${request.url}`
    );
  }

  return params;
};
export const upserveParams = (request: Request): Params | undefined => {
  const queryParams = new URL(request.url).searchParams;

  const ticketNumber = queryParams.get("checkNumber") ?? undefined;
  const ticketId = queryParams.get("checkId") ?? undefined;
  const ticketTotal = queryParams.get("total") ?? "<UNKNOWN>";

  if (isFalsy(ticketNumber) && isFalsy(ticketId)) {
    console.error(
      `Upserve QR Code is Not valid for query string. Url: ${request.url}`
    );
    return undefined;
  }

  return {
    ticketNumber: isFalsy(ticketId) ? ticketNumber : undefined,
    ticketId: isTruthy(ticketId) ? ticketId : undefined,
    total: ticketTotal,
    origin: "upserve",
  };
};
