import {
  CheckClosedOnPOSError,
  CheckNotFoundError,
  PlaceNotFoundError,
  TableNotFoundError,
} from "utils/api/errors";
import { P, match } from "ts-pattern";

import { MessageBoxProps } from "components/MessageBox/MessageBox";
import { addBreadcrumb } from "@sentry/react";

export const messageForError = (error: any): MessageBoxProps => {
  const defaultMessage: MessageBoxProps = {
    title: "There was an error pulling ticket information.",
    message: "Please refresh the page to get the latest ticket data.",
  };

  const message: MessageBoxProps | undefined =
    match(error)
      .with(P.instanceOf(CheckNotFoundError), () => {
        return {
          title: "Not Found.",
          message: `Unable to find Check with Number: ${error.data?.ticketNumber}`,
        };
      })
      .with(P.instanceOf(TableNotFoundError), () => {
        return {
          title: "Not Found.",
          message: error.data.message,
        };
      })
      .with(P.instanceOf(PlaceNotFoundError), () => {
        return {
          title: "Not Found.",
          message: error.data.message,
        };
      })
      .with(P.instanceOf(CheckClosedOnPOSError), () => {
        return {
          title: "Check closed.",
          message: "This check is closed in the POS system.",
        };
      })
      .otherwise(() => {
        addErrorBreadcrumb(
          `Something went wrong at ${window.location.href}`,
          error
        );
      }) ?? undefined;

  return message ?? defaultMessage;
};

const addErrorBreadcrumb = (message: string, error: Error) => {
  addBreadcrumb({
    level: "error",
    category: "message_for_error",
    message,
    data: {
      path: window.location.href,
      error,
    },
  });
};

/* interface ErrorBreadcrumb {
  message: string;
  error: Error | undefined
} */
