import { Box, Stack, Typography } from "@mui/material";

import BrandLogo from "@vendor/components/VendorLogo/VendorLogo";
import FullScreenState from "components/FullScreenState/FullScreenState";
import Loader from "components/Loader/Loader";
import { colors } from "@theme/Themes";

interface FullScreenLoaderProps {
  title?: string;
  backgroundColor?: string
}

const FullScreenLoader = ({ title, backgroundColor }: FullScreenLoaderProps) => {
  return (
    <FullScreenState backgroundColor={backgroundColor}>
      {title && (
        <>
          <Typography
            variant="h1"
            sx={{
              top: "35%",
              position: "absolute",
              alignSelf: "center",
              color: colors.text,
            }}>
            {title}
          </Typography>
        </>
      )}
      <Box
        alignItems={"center"}
        sx={{
          height: 100,
        }}
      >
        <Stack spacing={2} alignItems={"center"}>
          <BrandLogo />
          <Loader color={colors.vendorColor} />
        </Stack>
      </Box>
    </FullScreenState>
  );
};

export default FullScreenLoader;
