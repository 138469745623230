import * as Styled from "./ProceedToCheckout.styles";

import { useCallback, useEffect, useState } from "react";

import Button from "@component/Button/Button";
import { Check } from "@mui/icons-material";
import Radio from "@component/Inputs/Radio/Radio";
import { encodeCheckId } from "@utils/getCheck/getCheck";
// import getPaymentMethods from "@utils/getPaymentMethods";
import trackGaEvent from "@utils/trackGaEvent";
import { useAuth } from "@context/AuthContext";
import { useCheck } from "@context/CheckContext";
// import useCookie from "@hooks/useCookies";
import { useNavigate } from "react-router-dom";
import { usePlace } from "@context/PlaceContext";

interface ProceedToCheckoutProps {
  onNoTip?: (show: boolean) => void;
}

function ProceedToCheckout({
  onNoTip,
}: ProceedToCheckoutProps): React.ReactElement {
  const [agreed, setAgreed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isAuthenticated } = useAuth();
  // const [authCookie, _] = useCookie("authToken");

  const { check, selectedTip } = useCheck();
  const { place } = usePlace();

  const navigate = useNavigate();

  const handleCheckout = useCallback(async () => {
    setIsLoading(true);

    if (!selectedTip) {
      setIsLoading(false);
      onNoTip?.(true);
      return;
    }

    // 🚨 Disabled Resy Authentication as their Braintree vault is no longer available
    /* if (place?.code) {
      try {
        const data = await getPaymentMethods(place?.code, undefined);
        setPaymentMethods(data?.paymentMethods ?? []);
      } catch (error) {
        console.error(error);
      }
    } */

    trackGaEvent("Clicked Checkout Button", {
      description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
    });

    check?.number !== undefined &&
      navigate(`/${place?.code}/${encodeCheckId(check.id)}/checkout/`);
  }, [check?.id, check?.number, navigate, onNoTip, place?.code, selectedTip]);

  useEffect(() => {
    if (isAuthenticated) {
      setAgreed(true);
    }
  }, [isAuthenticated]);

  const handleProceedToCheckout = useCallback(() => {
    setAgreed(!agreed);
  }, [agreed]);

  return (
    <Styled.Container data-testid="ProceedToCheckout" direction="column">
      {!isAuthenticated && (
        <Styled.InnerContainer>
          <Radio
            checked={agreed}
            checkedIcon={<Check />}
            id="agree-to-terms"
            name="agree-to-terms"
            onClick={handleProceedToCheckout}
            value="agreed"
          />{" "}
          <Styled.TermsContainer>
            I agree to the{" "}
            <a
              href="https://resy.com/terms/resypay"
              rel="noreferrer"
              target="_blank"
            >
              Resy Pay Terms of Services.
            </a>
          </Styled.TermsContainer>
        </Styled.InnerContainer>
      )}
      <Styled.InnerContainer>
        <Button
          disabled={!agreed}
          isLoading={isLoading}
          onClick={() => {
            void handleCheckout();
          }}
          text="Checkout"
        />
      </Styled.InnerContainer>
    </Styled.Container>
  );
}

export default ProceedToCheckout;
