import { colors, fonts } from "@theme/Themes";

import { Card } from "@mui/material";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  gap: 8px;
`;

export const PaymentMethodList = styled.ul`
  display: block;
  margin: 12px 0 0;
  padding: 0;
  width: 100%;
`;

export const PaymentMethodItem = styled.li`
  display: block;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface PaymentCardProps {
  isAmex?: boolean;
}

export const PaymentCard = styled(Card)<PaymentCardProps>`
  font-weight: 700;
  padding: 14px;
  border: 1px solid ${({ isAmex }) => (isAmex ? colors.border : "transparent")};

  .card-type-container {
    align-items: center;
    gap: 16px;
    justify-content: flex-start;

    .MuiRadio-root {
      margin: -9px -9px -9px auto;

      .icon {
        fill: ${colors.primary};
        border-radius: 50%;
        overflow: hidden;
        height: 20px;
        width: 20px;
      }

      .MuiSvgIcon-root {
        fill: ${colors.primary};
        height: 24px;
        margin-right: -2px;
        width: 24px;
      }
    }
  }

  .card-type {
    align-items: center;
    display: flex;

    div {
      align-items: center;
      display: flex;
    }

    svg {
      align-items: center;
      display: flex;
      margin: 3px 0 0;
      width: 28px;
    }
  }

  .default-type {
    align-items: center;
    display: flex;
    height: 20px;
    width: 28px;

    svg {
      margin: 0;
      transform: scale(1.8);
    }
  }
`;

export const PaymentMethodLabel = styled.label`
  font-family: ${fonts.textFont};
  font-size: 1.6rem;
`;

export const AmexPwPNotEligible = styled.div`
  color: ${colors.textSecondary};
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 4px;

  .sup {
    font-size: smaller;
    margin-left: 1px;
  }
`;
