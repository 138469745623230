import * as Styled from "../ViewCheck/ViewCheck.styles";

import { Typography } from "@mui/material";
import ViewMenuButton from "@component/ViewMenuButton/ViewMenuButton";

const NoTicketAtTableMessageBox = ({ number }: TableProps) => {
  return (
    <>
      <ViewMenuButton isNoTicketAtTableError={true} />
      <Styled.ErrorContainer direction="column">
        <Styled.InnerContainer className="no-items" direction="column">
          <Typography variant="h1">Table: {number}</Typography>
          <Typography variant="h2">You can pay here!</Typography>
          <span>But nothing has been ordered.</span>
        </Styled.InnerContainer>
      </Styled.ErrorContainer>
    </>
  );
};

export interface TableProps {
  id: string;
  number?: string;
}

export default NoTicketAtTableMessageBox;
