import * as Styled from "./ViewMenuButton.styles";

import { useCallback, useState } from "react";

import ViewMenuPayCheck from "./ViewMenuPayCheck";
import { isDev } from "@utils/constants";
import { usePlace } from "@context/PlaceContext";

interface ViewMenuSheetProps {
  onClose: () => void;
}

function ViewMenuSheet({ onClose }: ViewMenuSheetProps) {
  const [showMenu, setShowMenu] = useState(false);

  const { place } = usePlace();

  const payCheckUrl = window.location.href;

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const handleShowMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const menuLink = isDev
    ? `https://fake-restaurant.pages.dev/menu?payCheck=${payCheckUrl}`
    : `${place?.links.url1}?payCheck=${payCheckUrl}`;

  return (
    <Styled.SheetContainer direction="column">
      <Styled.ContentHeader>
        <Styled.Title>Menu</Styled.Title>
        <Styled.CancelButton
          onClick={handleClose}
          text="Cancel"
          variant="text"
        />
      </Styled.ContentHeader>
      <Styled.ContentContainer isMenuShown={showMenu}>
        <ViewMenuPayCheck />
      </Styled.ContentContainer>
      <Styled.ContentFooter>
        <Styled.FooterButton
          href={menuLink}
          onClick={handleShowMenu}
          target="_blank"
        >
          View Menu
        </Styled.FooterButton>
      </Styled.ContentFooter>
    </Styled.SheetContainer>
  );
}

export default ViewMenuSheet;
