import { QueryFunctionContext, QueryKey } from "react-query";
import apiClient from "../api/apiClient";
import { CheckData } from "./GetCheckModels";
import { isFalsy } from "../truthy";
import { isValidPlaceCode } from "../getPlace";

interface GetCheckAtTableKey {
  placeCode: string;
  tableId: string;
}

async function getCheckAtTable(
  context: QueryFunctionContext<QueryKey, unknown>
): Promise<CheckData> {
  const params = context.queryKey[1] as GetCheckAtTableKey;
  return await fetchCheckAtTable(params);
}

export const fetchCheckAtTable = async ( { placeCode, tableId }: GetCheckAtTableKey): Promise<CheckData> => {
  if (isFalsy(placeCode) || !isValidPlaceCode(placeCode) || isFalsy(tableId)) {
    throw new Error(`fetchCheckAtTable > placeCode: ${placeCode} or tableId: ${tableId} not valid`);
  }
  return await apiClient.get({ path: "/table/ticket", queryParams: { placeCode, tableId } });
};

export default getCheckAtTable;
