import { colors, fonts } from "@theme/Themes";

import AMEXImgBackground from "@vendor/assets/images/amex-background.png";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Card = styled(StyledContainer)`
  align-items: center;
  border: 1px solid ${colors.borderLight};
  border-radius: 6px;
  box-shadow: 0 1px 6px 1px rgb(0 0 0 / 9%) !important;
  display: flex;
  font-size: 1.4rem;
  gap: 12px;
  margin: 14px 0;
  padding: 0 0 13px 0;
  z-index: 1;
`;

export const Cover = styled(StyledContainer)`
  background: #f6f7f9 url(${AMEXImgBackground}) top center repeat-x;
  background-size: 75%;
  gap: 8px;
  justify-content: center;
  padding: 18px 0;
  width: 100%;
`;

export const Image = styled.img`
  display: flex;
  width: 156px;

  @media only screen and (min-width: 541px) {
    width: 50%;
  }
`;

export const Copy = styled(StyledContainer)`
  align-items: center;
  font-size: 1.6rem;
  padding: 0 18px;
  text-align: center;
  width: 100%;
`;

export const Title = styled.h4`
  color: ${colors.vendorColor};
  font-size: 1.5rem;
  max-width: 100px;
`;

export const Message = styled.span`
  max-width: 350px;
`;

export const Button = styled.a`
  align-items: center;
  background-color: ${colors.vendorColor};
  border-radius: 6px;
  color: ${colors.paper};
  display: flex;
  font-family: ${fonts.titlesFont};
  font-size: 1.5rem;
  justify-content: center;
  height: 47px;
  margin-top: 18px;
  width: 100%;

  &:hover {
    color: ${colors.paper};
  }
`;
