import { QueryClient, QueryKey } from "react-query";
import { QueryFunction } from "react-query/types/core/types";

export interface Query<TQueryFnData = unknown, TQueryKey extends QueryKey = QueryKey> {
  queryKey: TQueryKey;
  queryFn: QueryFunction<TQueryFnData, TQueryKey>;
}

export async function getQueryDataFetchingIfNeeded<T>(
  query: Query,
  queryClient: QueryClient,
): Promise<T> {
  return await new Promise<T>((resolve) => {
    const data = queryClient.getQueryData<T>(query.queryKey)
      ?? queryClient.fetchQuery(query);
    resolve(data as T);
  });
}
