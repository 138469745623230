import { AppBar, Toolbar } from "@mui/material";

import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Wrapper = styled(AppBar)`
  &.overwrite {
    -webkit-box-shadow: 0 3px 14px 0px rgba(0, 0, 0, 0.06);
    box-shadow: 0 3px 14px 0px rgba(0, 0, 0, 0.06);
    left: auto;
    max-width: 640px;
    right: auto;

    .back-btn {
      color: ${colors.textDisabled};
      justify-content: flex-start;
      padding-left: 0;
      padding-right: 0;
      width: 51px;
    }

    .login-btn {
      min-width: 51px;
      width: auto;
    }

    .account-icon,
    .account-btn {
      width: 30px;
      height: 30px;
      background-color: transparent;
    }
  }
`;

export const Container = styled(Toolbar)`
  &.overwrite {
    justify-content: space-between;
    padding: 7.7px 16px;

    @media (min-width: 600px) {
      min-height: initial;
    }
  }
`;

export const LocationName = styled.div`
  color: ${colors.secondary};
  border-top: solid 1px ${colors.borderLight};
  padding: 7px 16px;
  text-align: center;

  h1 {
    font-size: 1.5rem;
    margin: 0;
  }
`;

export const EmptyDiv = styled.div`
  width: 51px;
`;
