import { Icon, IconFillColorProps } from "components/Icons/Icon";

import React from "react";

function CheckIcon(props: IconFillColorProps): React.ReactElement {
  return (
    <Icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-icon"
        viewBox="0 0 24 24"
      >
        <path
          style={{ fill: props.fillColor }}
          d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
        ></path>
      </svg>
    </Icon>
  );
}

export default CheckIcon;
