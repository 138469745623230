import { defer, LoaderFunctionArgs } from "react-router-dom";
import {
  Params,
  paramsFromQRCodeRequest,
} from "./params/paramsFromQRCodeRequest";

import {
  BadRequestError,
  CheckNotFoundError,
  NotFoundError,
} from "utils/api/errors";
import { QueryClient } from "react-query";
import { checkData } from "routes/Check/Check";
import { setTag } from "@sentry/react";

export const findCheckByNumberLoader =
  (queryClient: QueryClient) =>
  async ({ request, params }: LoaderFunctionArgs) => {
    const findCheckParams: Params | undefined =
      paramsFromQRCodeRequest(request);

    if (params.placeCode && findCheckParams) {

      setTag("placeCode", params.placeCode);
      setTag("ticketNumber", findCheckParams.ticketNumber);
      setTag("ticketId", findCheckParams.ticketId);

      // Trying to Find check with params
      const request = checkData( {
        placeCode: params.placeCode,
        ticketNumber: findCheckParams.ticketNumber
      },
      queryClient
      ).catch((error) => {
        if (error instanceof NotFoundError) {
          throw new CheckNotFoundError({
            message: "Ticket Not Found",
            ticketNumber: findCheckParams.ticketNumber ?? undefined,
            placeCode: undefined
          });
        } else {
          throw error;
        }
      });

      return defer({ checkData: request });
    }
    throw new BadRequestError();
  };
