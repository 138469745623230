import StyledBox from "@GlobalComponents/StyledBox";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  margin-bottom: 16px;
  width: 100%;

  .MuiBox-root {
    background-color: ${colors.paper};
    overflow: hidden;
  }

  &.btn-container {
    border-top: solid 1px ${colors.border};
    gap: 11px;
    margin: 11px 0 0;
    padding: 8px;
    width: 100%;

    button {
      margin: 0;
    }
  }

  .split-btn {
    margin: 16px 0;
  }
`;

export const Box = styled(StyledBox)`
  font-size: 1.2rem;
  padding: 11px 0 0;
  text-align: center;
  width: 100%;

  > div {
    padding: 0 14px;
  }

  p {
    margin: 0 auto;
    max-width: 400px;
  }
`;
