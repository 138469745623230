import { QueryFunctionContext, QueryKey } from "react-query";
import { isFalsy, isTruthy } from "./truthy";

import { InvalidPlaceCodeError } from "./api/errors";
import apiClient from "./api/apiClient";
import { environment } from "./constants";

interface TipProps {
  percentage: number;
}

export interface PlaceDataProps {
  baseFareInCents: number;
  code: string;
  id: string;
  flags: string[];
  name: string;
  resyVenueId: string;
  tipsData: {
    defaultTip: TipProps;
    tips: TipProps[];
  };
  links: {
    label1: string;
    url1: string;
  };
  address?: string;
  logo?: string;
  image?: string;
}

interface GetPlaceKey {
  placeCode: string;
}

const { isWebTab } = environment;

async function getPlace(
  context: QueryFunctionContext<QueryKey, unknown>
): Promise<any> {
  const { placeCode } = context.queryKey[1] as GetPlaceKey;

  if (isFalsy(placeCode) || !isValidPlaceCode(placeCode)) {
    throw new InvalidPlaceCodeError("getPlace >", placeCode);
  }

  const payWebPath = `/place/${placeCode}`;
  const webTabPath = `/venue/${placeCode}`;

  try {
    let result;

    if (isWebTab) {
      result = await apiClient.get({ path: webTabPath });
    } else {
      result = await apiClient.get({ path: payWebPath });
    }

    return result;
  } catch (error) {
    console.error("Error fetching place data:", error);
    throw error;
  }
}

export default getPlace;

export const isValidPlaceCode = (placeCode: string | undefined) =>
  isTruthy(placeCode) && /([A-Z0-9]{10,}|CQR85E|7JXEEW|CAKJ4S)/.test(placeCode);
