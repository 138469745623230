import * as Styled from "./Qa.styles";
import Button from "../Button/Button";
import { useCallback } from "react";
import { getTicketNoItems, getTicketMultipleItem, getTicketOneItem } from "utils/qa";
import { useNavigate } from "react-router-dom";
import { encodeCheckId } from "@utils/getCheck/getCheck";

function Qa(): React.ReactElement {
  const navigate = useNavigate();
  const handleGetTicketNoItems = useCallback(
    () => {
      getTicketNoItems()
        .then(({ placeCode, id }) => {
          navigate(`/${placeCode}/${encodeCheckId(id)}`);
        })
        .catch((error) => {
          console.error("Error creating qa ticket", error);
        });
    },
    [navigate],
  );

  const handleGetTicketOneItem = useCallback(
    () => {
      getTicketOneItem()
        .then(({ placeCode, id }) => {
          navigate(`/${placeCode}/${encodeCheckId(id)}`);
        })
        .catch((error) => {
          console.error("Error creating qa ticket", error);
        });
    },
    [navigate],
  );

  const handleGetTicketMultipleItems = useCallback(
    () => {
      getTicketMultipleItem()
        .then(({ placeCode, id }) => {
          navigate(`/${placeCode}/${encodeCheckId(id)}`);
        })
        .catch((error) => {
          console.error("Error creating qa ticket", error);
        });
    },
    [navigate],
  );

  return (
    <>
      <Styled.Container direction="column">
        <Styled.Row>
          <Button
            onClick={handleGetTicketNoItems}
            text={"Omnivore - No items"}
          />
        </Styled.Row>
        <Styled.Row>
          <Button
            onClick={handleGetTicketOneItem}
            text={"Omnivore - One item"}
          />
        </Styled.Row>
        <Styled.Row>
          <Button
            onClick={handleGetTicketMultipleItems}
            text={"Omnivore - Multiple item"}
          />
        </Styled.Row>
      </Styled.Container>
    </>
  );
}

export default Qa;
