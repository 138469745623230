import * as Styled from "./ViewMenuButton.styles";

import { useCallback, useState } from "react";

import { BottomSheet } from "react-spring-bottom-sheet";
import Button from "@component/Button/Button";
import { CheckOrigin } from "@utils/getCheck/GetCheckModels";
import ViewMenuSheet from "./ViewMenuSheet";
import checkOrigin from "@utils/getCheck/checkOrigin";
import { colors } from "@theme/Themes";
import { useCheck } from "@context/CheckContext";
import { usePlace } from "@context/PlaceContext";

interface ViewMenuButtonProps {
  isNoTicketAtTableError?: boolean;
}

function ViewMenuButton({ isNoTicketAtTableError }: ViewMenuButtonProps) {
  const [isActionSheetOpen, setIsActionSheetOpen] = useState(false);

  const handleViewMenu = useCallback(() => {
    setIsActionSheetOpen(!isActionSheetOpen);
  }, [isActionSheetOpen]);

  const { check } = useCheck();
  const { place } = usePlace();

  const isCheckOrigin =
    place?.code && check?.id
      ? checkOrigin.get(place.code, check?.id)
      : undefined;

  const isTableQR = isCheckOrigin
    ? isCheckOrigin === CheckOrigin.QRTable.toString()
    : isNoTicketAtTableError;

  if (!isTableQR) {
    return <></>;
  }

  return (
    <Styled.Container>
      <Button
        text="View Menu"
        size="medium"
        onClick={handleViewMenu}
        variant="outlined"
      />

      <BottomSheet
        id="bottom-sheet"
        className="viewMenu-sheet"
        open={isActionSheetOpen}
        onDismiss={handleViewMenu}
        style={{
          backgroundColor: `${colors.lighterBackground}`,
        }}
      >
        <ViewMenuSheet onClose={handleViewMenu} />
      </BottomSheet>
    </Styled.Container>
  );
}

export default ViewMenuButton;
