import { Await, useLoaderData } from "react-router-dom";
import React, { Suspense } from "react";

import { CheckLoaderData } from "utils/qrcode/route/models";
import FindCheckErrorHandler from "./FindCheckErrorHandler";
import FindCheckRedirect from "./FindCheckRedirect";
import FullScreenLoader from "components/Loading/FullScreenLoader";

const FindCheckByNumber = () => {
  const { checkData } = useLoaderData() as CheckLoaderData;
  return (
    <>
      <Suspense
        fallback={<FullScreenLoader title={"Getting ticket details."} />}
      >
        <Await resolve={checkData} errorElement={<FindCheckErrorHandler />}>
          <FindCheckRedirect />
        </Await>
      </Suspense>
    </>
  );
};

export default FindCheckByNumber;
