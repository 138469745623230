import * as Styled from "./SplitCheckActionSheet.styles";

import { SplitCheckProps, useCheck } from "context/CheckContext";
import { useCallback, useState } from "react";

import { ArrowBackIosNew } from "@mui/icons-material";
import Button from "components/Button/Button";
import SplitAmount from "./SplitCheckFlows/SplitAmount";
import SplitCheckOptions from "./SplitCheckFlows/SplitCheckOptions";
import SplitEqually from "./SplitCheckFlows/SplitEqually";
import SplitItems from "./SplitCheckFlows/SplitItems";
import { useLocation } from "react-router-dom";

interface SplitCheckActionSheetProps {
  onClose: () => void;
}

function SplitCheckActionSheet({ onClose }: SplitCheckActionSheetProps) {
  const [splitOption, setSplitOption] = useState<
    SplitCheckProps["type"] | undefined
  >(undefined);

  const { check, setSplitCheck } = useCheck();

  const location = useLocation();
  const isSplitPage = location.pathname.includes("split");

  const handleSplitOption = useCallback(
    (option: SplitCheckProps["type"] | undefined) => {
      setSplitOption(option);
      setSplitCheck({ type: option });
    },
    [setSplitCheck]
  );

  const handleClose = useCallback(() => {
    if (!isSplitPage) {
      setSplitCheck(undefined);
      sessionStorage.removeItem(`splitCheck_${check?.number}`);
    }

    setSplitOption(undefined);
    onClose?.();
  }, [check?.number, isSplitPage, onClose, setSplitCheck]);

  const handleGoBack = useCallback(() => {
    setSplitOption(undefined);
  }, []);

  return (
    <Styled.Container>
      <Styled.ContentContainer
        className={splitOption === "split-by-item" ? "split-by-item" : ""}
        direction="column"
      >
        <Styled.ContentHeader>
          <Styled.Title aria-label="Split the Check dialog">
            <Button
              disabled={!splitOption}
              className="back-btn"
              icon={<ArrowBackIosNew fontSize="large" color="inherit" />}
              onClick={handleGoBack}
              size="small"
              variant="text"
            />

            <h3>Split the Check</h3>
          </Styled.Title>
          <Styled.CancelButton
            onClick={handleClose}
            text="Cancel"
            variant="text"
          />
        </Styled.ContentHeader>

        {!splitOption && (
          <SplitCheckOptions onOptionChange={handleSplitOption} />
        )}

        {splitOption === "split-equally" && (
          <SplitEqually onHandleClose={handleClose} />
        )}
        {splitOption === "split-by-amount" && (
          <SplitAmount onHandleClose={handleClose} />
        )}
        {splitOption === "split-by-item" && <SplitItems />}
      </Styled.ContentContainer>
    </Styled.Container>
  );
}

export default SplitCheckActionSheet;
