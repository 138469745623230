import * as Styled from "./VendorDownload.styles";

import { ReactComponent as AppStoreIcon } from "@vendor/assets/images/app-store.svg";
import VendorIcon from "../VendorLogo/VendorIcon";
import { colors } from "@theme/Themes";

function VendorDownload(): React.ReactElement {
  return (
    <Styled.Container>
      <Styled.Badge>
        <VendorIcon fillColor={colors.vendorColor} />
      </Styled.Badge>
      <Styled.Title>Download the Resy App</Styled.Title>
      <p>
        Want to checkout even faster next time? Download the Resy App and create
        an account to link up your payment information.
      </p>

      <a
        href="https://apps.apple.com/app/apple-store/id866163372"
        target="_blank"
        rel="noreferrer"
        data-testid="vendor-download"
      >
        <AppStoreIcon />
      </a>
    </Styled.Container>
  );
}

export default VendorDownload;
