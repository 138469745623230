import { Alert } from "@mui/material";
import StyledContainer from "@GlobalComponents/StyledContainer";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const ErrorMessage = styled(Alert)`
  &.overwrite {
    align-items: center;
    background-color: ${colors.errorLight};
    color: ${colors.errorDark};
    font-size: 1.3rem;
    margin-bottom: 16px;

    svg {
      fill: ${colors.error};
      height: 22px;
      width: 22px;
    }
  }
`;

export const Container = styled(StyledContainer)`
  gap: 10px;
  margin-top: 16px;
`;
