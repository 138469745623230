import { QueryFunctionContext, QueryKey } from "react-query";
import apiClient from "../api/apiClient";
import { CheckData } from "./GetCheckModels";
import { isFalsy } from "../truthy";
import { isValidPlaceCode } from "../getPlace";
import { removeEmpty } from "../removeEmpty";
import Rot47Cipher from "../rot47cipher";
import paytronixUtils from "@utils/getCheck/paytronix/paytronixUtils";
import {
  fetchPaytronixCheck,
} from "@utils/getCheck/paytronix/getPaytronixCheck";

interface GetCheckKey {
  placeCode?: string;
  ticketId?: string;
  ticketNumber?: string;
}

export interface GetCheckRequest {
  placeCode?: string;
  ticketNumber?: string;
  ticketId?: string
}

async function getCheck(
  context: QueryFunctionContext<QueryKey, unknown>
): Promise<CheckData> {
  const params = context.queryKey[1] as GetCheckKey;

  if (params.ticketId && paytronixUtils.existsDataForCheckId(params.ticketId)) {
    const request = paytronixUtils.getData(params.ticketId);
    if (isFalsy(request)) {
      throw new Error(`Invalid Paytronix data for ticketId ${params.ticketId}`);
    }
    return await fetchPaytronixCheck(request);
  } else {
    return await fetchCheck(removeEmpty(params));
  }
}

export const fetchCheck = async ( request: GetCheckRequest): Promise<CheckData> => {

  if (isFalsy(request.placeCode) || !isValidPlaceCode(request.placeCode)) {
    throw new Error(`fetchCheck > placeCode: ${request.placeCode} not valid`);
  }

  if (isFalsy(request.ticketId) && isFalsy(request.ticketNumber)) {
    throw new Error("fetchCheck > ticketNumber | ticketId not provided");
  }

  return await apiClient.get( { path: "/ticket", queryParams: removeEmpty(request) } );
};

export const fetchCheckBySessionGuid = async (sessionGuid: string, placeCode: string): Promise<CheckData> => {
  return await apiClient.get({ path: `${sessionGuid}/ticket`, queryParams: { placeCode } });
};

export const decodeCheckId = (str: string) => Rot47Cipher.decode(decodeBase64(str));
export const encodeCheckId = (str: string) => encodeBase64(Rot47Cipher.encode(str));

const decodeBase64 = (str: string):string => atob(str);
const encodeBase64 = (str: string):string => btoa(str);

export default getCheck;
