import { Icon, IconFillColorProps } from "@component/Icons/Icon";

export default function VendorRewardsIcon(
  props: IconFillColorProps
): React.ReactElement {
  return (
    <Icon>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          style={{ fill: props.fillColor }}
          d="M13.294,8.203c-0.32-0.314-0.761-0.471-1.321-0.471h-1.804v3.475h1.804c0.56,0,1.001-0.164,1.321-0.491
							  c0.32-0.327,0.48-0.74,0.48-1.237C13.774,8.943,13.614,8.518,13.294,8.203z M18.714,0H5.286C2.367,0,0,2.367,0,5.286v13.427C0,21.633,2.367,24,5.286,24h13.427C21.633,24,24,21.633,24,18.714V5.286
							  C24,2.367,21.633,0,18.714,0z M17.536,18.52c-3.253,0-5.898-2.092-6.76-5.033H10.04v4.809H7.098V5.2h4.963
							  c0.774,0,1.454,0.108,2.041,0.325c0.587,0.216,1.078,0.51,1.471,0.884c0.394,0.373,0.688,0.809,0.881,1.305
							  c0.193,0.497,0.291,1.028,0.291,1.59c0,0.851-0.19,1.594-0.571,2.229c-0.38,0.635-0.917,1.084-1.611,1.465
							  c-0.223,0.132-0.476,0.252-0.829,0.345c0.68,1.437,2.051,2.384,3.803,2.384V18.52z"
        ></path>
      </svg>
    </Icon>
  );
}
