import Loader from "components/Loader/Loader";
import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

interface ComponentLoaderProps {
  loaderText?: string;
}

const Container = styled(StyledContainer)`
  align-items: center;
  height: 50vh;
  gap: 16px;
  justify-content: center;
`;

function ComponentLoader({ loaderText }: ComponentLoaderProps) {
  return (
    <Container direction="column">
      <Loader />
      {loaderText && <span>{loaderText}</span>}
    </Container>
  );
}

export default ComponentLoader;
