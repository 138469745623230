import * as Styled from "./Radio.styles";

interface RadioProps {
  checked?: boolean;
  checkedIcon?: React.ReactNode;
  id: string;
  name: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  value?: unknown;
}

function Radio({
  checked,
  checkedIcon,
  id,
  name,
  onChange,
  onClick,
  value,
}: RadioProps): React.ReactElement {
  return (
    <Styled.RadioButton
      checked={checked}
      checkedIcon={checkedIcon}
      className="overwrite"
      id={id}
      name={name}
      onClick={onClick}
      onChange={onChange}
      value={value}
    />
  );
}

export default Radio;
