import { ReactComponent as AmexIcon } from "assets/payments/amex.svg";
import Button from "components/Button/Button";
import { encodeCheckId } from "utils/getCheck/getCheck";
import trackGaEvent from "utils/trackGaEvent";
import { useCallback } from "react";
import { useCheck } from "context/CheckContext";
import { useNavigate } from "react-router-dom";
import { usePlace } from "context/PlaceContext";

function AmexPwp() {
  const navigate = useNavigate();
  const { check } = useCheck();
  const { place } = usePlace();

  const handleAmexPwp = useCallback(() => {
    check?.number !== undefined &&
      navigate(
        `/${place?.code}/${encodeCheckId(check.id)}/checkout/?AmexPwp=true`
      );

    trackGaEvent("Clicked AmexPwP Button", {
      description: `PlaceCode: ${place?.code} | CheckNumber: ${check?.number}`,
    });
  }, [check?.id, check?.number, navigate, place?.code]);

  return (
    <Button
      ariaLabel="Pay using American Express Membership Rewards Points"
      className="expressCheckoutBtn AmexPwPBtn"
      icon={<AmexIcon className="pay-icon" />}
      onClick={handleAmexPwp}
      size="medium"
      node={
        <div aria-hidden="true">
          Use Membership Rewards<span className="sup">®</span> Points
        </div>
      }
    />
  );
}

export default AmexPwp;
