import * as Styled from "../ViewCheck/ViewCheck.styles";

import { Typography } from "@mui/material";

const MessageBox = ({ title, message }: MessageBoxProps) => {
  return (
    <Styled.ErrorContainer direction="column">
      <Styled.InnerContainer className="no-items" direction="column">
        <Typography variant="h2">{title}</Typography>
        {message && (
          <>
            <span>{message}</span>
          </>
        )}
      </Styled.InnerContainer>
    </Styled.ErrorContainer>
  );
};

export interface MessageBoxProps {
  title: string;
  message?: string;
}

export default MessageBox;
