import * as Styled from "./AmexPwpBanner.styles";

import { ReactComponent as AmexIcon } from "assets/payments/amex.svg";

function AmexPwpBanner() {
  return (
    <Styled.Container>
      <Styled.Icon>
        <AmexIcon />
      </Styled.Icon>
      <Styled.CopyText>
        Enter your Membership Rewards<span className="sup">®</span> eligible
        American Express
        <span className="sup">®</span> Card to use Pay with Points.
      </Styled.CopyText>
    </Styled.Container>
  );
}

export default AmexPwpBanner;
