import { useEffect } from "react";

const useAddScript = (url: string, onload?: () => any): void => {
  useEffect(() => {
    if (document.getElementById(hashCode(url))) return;

    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    script.id = hashCode(url);
    script.onload = onload ?? null;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [onload, url]);
};

export default useAddScript;

const hashCode = (string: string) =>
  string
    .split("")
    .reduce(
      (acc, current) =>
        (acc + ((acc << 7) + (acc << 3))) ^ current.charCodeAt(0),
      0
    )
    .toString(16);
