import { Icon, IconFillColorProps } from "components/Icons/Icon";

import React from "react";

function StopHandIcon(props: IconFillColorProps): React.ReactElement {
  return (
    <Icon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-icon"
        viewBox="0 0 24 24">
        <path
          style={{ fill: props.fillColor }}
          d="M11.75 3.092v8.538c0 .383.688.391.688 0v-7.17c0-1.357 2.395-1.399 2.395 0v8.125c0 .5.491.573.676.197.176-.361.908-1.974.917-1.991.735-1.541 3.193-.605 2.429 1.209-.19.492-2.544 5.832-3.144 7.179-.629 1.411-1.857 2.821-4.126 2.821h-3.94c-2.388 0-3.645-1.417-3.645-3.895v-10.812c0-1.434 1.976-1.362 1.976-.066v4.741c0 .391.715.389.715 0v-7.618c0-1.475 2.208-1.435 2.208 0v7.192c0 .397.664.378.664-.008v-8.442c0-1.435 2.187-1.477 2.187 0zm-1.081-3.092c-1.055 0-1.972.499-2.53 1.277-1.833-.2-3.391 1.146-3.446 2.972-1.554.143-2.693 1.403-2.693 3.044v10.812c0 3.636 2.163 5.895 5.645 5.895h3.94c2.686 0 4.8-1.423 5.953-4.006.437-.981 2.873-6.496 3.17-7.24.464-1.119.373-2.297-.25-3.236-.761-1.146-2.233-1.75-3.624-1.41v-3.649c0-1.914-1.646-3.203-3.53-3.017-.532-.879-1.492-1.442-2.635-1.442z"></path>
      </svg>
    </Icon>
  );
}

export default StopHandIcon;
