import { QueryFunctionContext, QueryKey } from "react-query";
import { CheckData } from "../GetCheckModels";
import apiClient from "@utils/api/apiClient";
import paytronixUtils from "@utils/getCheck/paytronix/paytronixUtils";
import { CheckNotFoundError, NotFoundError } from "@utils/api/errors";

export interface GetPaytronixCheckRequest {
  venueId: string,
  placeCode?: string

  ticketNumber?: string
  ticketId?: string

  paytronix: Paytronix
}

export interface Paytronix {
  memberId: string,
  token: string,
  skipAuth: boolean
}

async function getPaytronixCheck(
  context: QueryFunctionContext<QueryKey, unknown>
): Promise<CheckData> {
  const params = context.queryKey[1] as GetPaytronixCheckRequest;
  return await fetchPaytronixCheck(params);
}

export const fetchPaytronixCheck = async ( request: GetPaytronixCheckRequest): Promise<CheckData> => {

  const response = await apiClient.post({
    path: "/paytronix/ticket",
    body: JSON.stringify(request),
  }).catch((error) => {
    if (error instanceof NotFoundError) {
      throw new CheckNotFoundError({
        message: "Ticket Not Found",
        ticketNumber: request.ticketNumber,
        placeCode: request.placeCode
      });
    } else {
      throw error;
    }
  });

  const paytronixCookieValue: GetPaytronixCheckRequest = {
    ...request,
    placeCode: response.placeCode,
    ticketId: response.id
  };

  paytronixUtils.setData(response.id, paytronixCookieValue);

  return response;
};

export default getPaytronixCheck;
