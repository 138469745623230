import apiClient from "./api/apiClient";

interface AddPaymentMethodRequest {
  nonce: string,
  resyAuthToken?: string
  deviceData?: string
  placeCode?: string
}

async function addPaymentMethod(
  { nonce, resyAuthToken, deviceData, placeCode }: AddPaymentMethodRequest
): Promise<any> {
  const data = JSON.stringify({
    nonce,
    deviceData,
    placeCode
  });

  try {
    return await apiClient.post({
      path: "/paymentMethods/add",
      headers: {
        "Content-Type": "application/json",
        ...(resyAuthToken && { resyAuthToken }),
      },
      body: data
    });
  } catch (error) {
    console.error("Error adding Payment Method to Braintree", error);
    throw error;
  }
}

export default addPaymentMethod;
