import StyledBox from "@GlobalComponents/StyledBox";
import { colors } from "@theme/Themes";
import styled from "styled-components";

export const Container = styled(StyledBox)`
  color: ${colors.textSecondary};
  margin-top: 54px;
  padding-top: 46px;
  text-align: center;

  svg {
    width: 140px;
  }
`;

export const Badge = styled(StyledBox)`
  background-color: ${colors.paper};
  border-radius: 16px;
  height: 62px;
  margin: -78px auto 0;
  padding: 16px;
  width: 62px;

  svg {
    width: 100%;
  }
`;

export const Title = styled.h4`
  color: ${colors.text};
`;
