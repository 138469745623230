import StyledContainer from "@GlobalComponents/StyledContainer";
import styled from "styled-components";

export const Container = styled(StyledContainer)`
  margin-top: 32px;
`;

export const Logo = styled.span`
  display: block;
  margin: 0 auto;
  max-width: 150px;
  padding: 0 0 14px;
  width: 100%;

  img {
    width: 100%;
  }
`;

export const HelpContainer = styled.div``;
