import { getItem, setItem } from "@hooks/useCookies";

import { CheckOrigin } from "./GetCheckModels";

const checkOrigin = {
  get: (placeCode: string, ticketNumber: String): CheckOrigin => {
    return getItem(`${placeCode}_${ticketNumber}_ORIGIN`) as CheckOrigin;
  },
  set: (placeCode: string, ticketNumber: String, origin: CheckOrigin) => {
    setItem(`${placeCode}_${ticketNumber}_ORIGIN`, origin, 0.25);
  },
};

export default checkOrigin;
