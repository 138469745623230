import * as Styled from "./PaymentComplete.styles";
import { Zoom } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import { usePlace } from "@context/PlaceContext";
import { useMemo } from "react";
import CompleteAdBanner
  from "@vendor/components/VendorAdBanners/CompleteAdBanner";
import VendorDownload
  from "@vendor/components/VendorDownload/VendorDownload";
import RateExperience from "../RateExperience/RateExperience";
import { colors } from "@theme/Themes";

const PaymentVoided = () => {
  const { place } = usePlace();

  const showBanner = useMemo(() => {
    return place?.flags.includes("place.resy.pay.banner.complete");
  }, [place?.flags]);

  return (
    <Styled.Container direction="column">
      <Styled.Container className="row-icon" direction="column">
        <Zoom in={true}>
          <Styled.CheckIconContainer outerBackgroundColor={colors.border} innerBackgroundColor={colors.borderDark}>
            <BlockIcon/>
          </Styled.CheckIconContainer>
        </Zoom>
        <h4 id="payment-completed" >{"Check Voided"}</h4>
      </Styled.Container>
      <CompleteAdBanner show={showBanner} />
      <RateExperience />
      <VendorDownload />
    </Styled.Container>
  );
};

export default PaymentVoided;
