import {
  BadRequestError,
  InternalServerDramaError,
  InvalidDataError,
  NetworkError,
  NotFoundError,
  TooEarlyError,
} from "./errors";

import { environment } from "../constants";
import tryOrUndefined from "../tryOrUndefined";
import { isTruthy } from "../truthy";
import sentry from "../sentry";
import { addBreadcrumb } from "@sentry/react";
import { match } from "ts-pattern";

const apiClient = {
  get: async ({ path, queryParams, headers }: Request) =>
    await request(new URL(path, environment.baseUrl), queryParams, {
      method: "GET",
      headers: prepareHeaders(headers),
      credentials: "include",
      mode: "cors",
    }),

  post: async ({ path, queryParams, headers, body }: Request) =>
    await request(new URL(path, environment.baseUrl), queryParams, {
      method: "POST",
      headers: prepareHeaders(headers),
      body,
      credentials: "include",
      mode: "cors",
    }),
};

export default apiClient;

type QueryParamsInit = string[][] | Record<string, any> | string;

async function request(
  url: URL,
  queryParams: QueryParamsInit | undefined,
  config: RequestInit | undefined
): Promise<any> {

  setQueryParams(queryParams, url);

  const request = {
    url: url?.toString(),
    queryParams,
    ...config,
  };

  let logData: any = await mapToJson(request, undefined);
  let error: Error | unknown | undefined;
  let errorMessage = `${request.method} ${url.pathname}${url.search}  > Failed!`;

  try {
    const response = await fetchRetry(url, config);
    logData = await mapToJson(request, response);
    addFetchBreadcrumb(logData);

    if (response.ok && response.status === 204) {
      return await Promise.resolve();
    } else if (response.ok) {
      return await response.json();
    } else {
      errorMessage = `${request.method} ${url.pathname}${url.search} > Status ${response.status} - ${logData?.response?.statusText}`;
      error = await mapResponseError(response, logData);
    }
  } catch (err) {
    error = err;
    errorMessage = `${errorMessage} > ${error}`;
  }

  console.error(errorMessage, logData, (error instanceof NetworkError) ? undefined : error);

  if (error instanceof SyntaxError) {
    error = new InvalidDataError(error.message);
  }

  return await Promise.reject(error);
}

const setQueryParams = (queryParams: QueryParamsInit | undefined, url: URL) => {
  if (queryParams) {
    const params = new URLSearchParams(queryParams);
    params.forEach((value: string, key: string, _: URLSearchParams) =>
      url.searchParams.append(key, value)
    );
  }
};

export interface Request {
  path: string;
  queryParams?: QueryParamsInit;
  headers?: HeadersInit;
  body?: BodyInit;
}

const prepareHeaders = (headers: HeadersInit | undefined): HeadersInit => {
  const sentryTransactionHeaders = {
    "X-Sentry-Unique-ID": sentry.uniqueId(),
    "X-Sentry-Transaction-ID": sentry.transactionId(),
    "X-Sentry-Transaction-Origin": sentry.transactionEntryPoint()
  };
  return { ...headers, ...sentryTransactionHeaders };
};

async function fetchRetry(input: RequestInfo | URL, init?: RequestInit, retry: Retry = defaultRetryOptions): Promise<Response> {
  try {
    const response = await fetch(input, init);
    return response;
  } catch(error) {
    const { currentAttempt, maxAttempts } = retry;
    if (currentAttempt >= maxAttempts) throw error;

    const nextRetryOptions = { ...retry, currentAttempt: currentAttempt + 1 };
    const nextRetryInMillis = retryDelayInMillis({ ...retry, currentAttempt: currentAttempt + 1 });

    return await delay(nextRetryInMillis)
      .then(async () => await fetchRetry(input, init, nextRetryOptions));
  }
};

const delay = async (millis: number) => await new Promise(resolve => setTimeout(resolve, millis));

const retryDelayInMillis = ({ currentAttempt, initialDelayInMillis, maxDelayInMillis, backoffFactor }: Retry) => Math.min(initialDelayInMillis * (backoffFactor ** currentAttempt), maxDelayInMillis);

interface Retry {
  currentAttempt: number
  maxAttempts: number,
  initialDelayInMillis: number,
  maxDelayInMillis: number,
  backoffFactor: number,
}

const defaultRetryOptions = {
  currentAttempt: 1,
  maxAttempts: 3,
  initialDelayInMillis: 2000,
  maxDelayInMillis: 25000,
  backoffFactor: 2
};

const mapToJson = async (request: object, response: Response | undefined): Promise<unknown> => {
  return {
    currentPath: window.location.pathname,
    request,
    response: response ? await mapResponseToJson(response) : {}
  };
};

const mapResponseToJson = async (response: Response): Promise<unknown> => {
  const responseBody = await bodyFromResponse(response);
  const responseStatusText = isTruthy(responseBody?.message) ? responseBody?.message : response.statusText;

  return {
    body: responseBody,
    headers: response.headers.entries(),
    ok: response.ok,
    redirected: response.redirected,
    status: response.status,
    statusText: responseStatusText,
    type: response.type,
    url: response.url,
  };
};

const bodyFromResponse = async (response: Response): Promise<any | undefined> => {
  const responseBodyJson = response.status !== 204 ? await tryOrUndefined(async () => await response.clone().json()) : undefined;
  const responseBodyString = response.status !== 204 ? await tryOrUndefined(async () => await response.clone().text()) : undefined;
  return responseBodyJson ?? responseBodyString;
};

const mapResponseError = async (response: Response, logData: any) => {
  const responseBody = await bodyFromResponse(response);

  return match(response.status)
    .with(400, () => new BadRequestError(responseBody, logData))
    .with(404, () => new NotFoundError(responseBody, logData))
    .with(425, () => new TooEarlyError(responseBody, logData))
    .with(500, () => new InternalServerDramaError(responseBody, logData))
    .otherwise(() => new NetworkError(
      response.status,
      response.statusText,
      responseBody,
      logData,
    ));
};

const addFetchBreadcrumb = (logData: any) => {
  addBreadcrumb({
    level: "info",
    category: "fetch_request",
    message: `Request: ${logData.request.url}`,
    data: { log: logData }
  });
};
