import * as Styled from "./ExpressCheckout.styles";

import React, { useCallback, useEffect, useState } from "react";

import AmexPwp from "./AmexPwp";
import ApplePay from "./ApplePay";
import { Client } from "braintree-web";
import Error from "@component/Error/Error";
import { ErrorType } from "@component/PaymentDetails/PaymentDetails";
import GooglePay from "./GooglePay";
import { useCheck } from "context/CheckContext";

interface ExpressCheckoutProps {
  clientInstance: Client | undefined;
  creditCardPaymentDisable?: boolean;
  showAmexPwp?: boolean;
  showGooglePay?: boolean;
}

function ExpressCheckout({
  clientInstance,
  creditCardPaymentDisable,
  showAmexPwp,
  showGooglePay
}: ExpressCheckoutProps): React.ReactElement {
  const [supportsApplePay, setSupportsApplePay] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType | undefined>();
  const [errorMessage, setErrorMessage] = useState<
    React.ReactNode | undefined
  >();

  const { checkTotal } = useCheck();

  useEffect(() => {
    if (
      window.ApplePaySession &&
      ApplePaySession.supportsVersion(3) &&
      ApplePaySession.canMakePayments()
    ) {
      setSupportsApplePay(true);
    }
  }, [supportsApplePay]);

  const handleError = useCallback((errorNode: React.ReactNode) => {
    setErrorMessage(errorNode);
  }, []);

  return (
    <Styled.Container>
      <Styled.Title>Express Checkout</Styled.Title>

      {error && errorMessage && <Error>{errorMessage}</Error>}

      {supportsApplePay && (
        <ApplePay
          clientInstance={clientInstance}
          amount={checkTotal}
          onError={handleError}
          setError={setError}
        />
      )}

      {showGooglePay && (
        <GooglePay
          clientInstance={clientInstance}
          amount={checkTotal}
          onError={handleError}
          setError={setError}
        />
      )}

      {showAmexPwp && !creditCardPaymentDisable && <AmexPwp />}
    </Styled.Container>
  );
}

export default ExpressCheckout;
