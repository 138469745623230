import { SplitCheckProps, splitCheckoutTypeEnum } from "@context/CheckContext";

import { CheckData } from "./getCheck/GetCheckModels";
import { prorateServiceCharges } from "./utilities";

export interface SplitCheckByItemViewModel {
  specificAmountInCents: number;
  checkTotalInCents: number;
  checkDueAmountInCents: number;
  checkDiscountInCents: number;
  checkServiceChargesInCents: number;
  serviceChargesInCents: number;
  isValid: boolean;

  splitCheck: SplitCheckProps;
}

export const calculateSplitCheckByItem = (
  specificAmountInCents: number,
  check: CheckData | undefined,
  splitCheck: SplitCheckProps | undefined
): SplitCheckByItemViewModel => {
  const checkSubtotalInCents = check?.totals.subTotalInCents ?? 0;
  const checkTaxes = check?.totals.taxInCents ?? 0;
  const checkServiceChargesInCents = check?.totals?.serviceChargesInCents ?? 0;
  const checkTotalInCents = check?.totals.totalInCents ?? 0;
  const checkDueAmountInCents = check?.totals.dueInCents ?? 0;
  const checkDiscountInCents = check?.totals.discountsInCents ?? 0;

  const serviceChargesInCents = prorateServiceCharges(
    checkSubtotalInCents,
    checkServiceChargesInCents,
    specificAmountInCents
  );

  const isValid = specificAmountInCents <= checkDueAmountInCents;

  const splitCheckData = {
    ...splitCheck,
    amount: specificAmountInCents,
    taxes: checkTaxes,
    serviceCharges: serviceChargesInCents,
    discount: checkDiscountInCents,
    splitCheckoutType: splitCheckoutTypeEnum.BY_ITEM,
  };

  return {
    specificAmountInCents,
    checkTotalInCents,
    checkDueAmountInCents,
    checkDiscountInCents,
    checkServiceChargesInCents,
    serviceChargesInCents,
    isValid,

    splitCheck: splitCheckData,
  };
};
