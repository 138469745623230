import * as Styled from "./PaymentMethod.styles";

import { PaymentMethodProps, useCheck } from "context/CheckContext";
import { useCallback, useEffect, useState } from "react";

import AmexRewards from "components/AmexRewards/AmexRewards";
import CheckIcon from "components/Icons/CheckIcon";
import PaymentIcons from "components/PaymentDetails/PaymentIcons";
import Radio from "components/Inputs/Radio/Radio";
import { useSearchParams } from "react-router-dom";

interface PaymentMethodListProps {
  list: PaymentMethodProps[];
}

const amexRegex = /amex|american express/;

function PaymentMethodList({ list }: PaymentMethodListProps) {
  const [methodList, setMethodList] = useState<PaymentMethodProps[]>(list);
  const [rewardPointsSelected, setRewardPointsSelected] = useState<
    number | undefined
  >();

  const { selectedMethod, setSelectedMethod } = useCheck();

  const [searchParams] = useSearchParams();
  const showAmexPwpOnly = searchParams.toString() === "AmexPwp=true";

  const changeSelectedMethod = useCallback(
    (methodIndex: number) => {
      const updatedList = [...methodList];

      const isAmex = amexRegex.test(
        updatedList[methodIndex].type.toLowerCase() ?? ""
      );

      updatedList.map((item) => (item.isDefault = false));

      updatedList[methodIndex].isDefault = true;

      if (isAmex) {
        updatedList[methodIndex].amexPointsInDollarAmount =
          rewardPointsSelected;
      } else {
        updatedList[methodIndex].amexPointsInDollarAmount = undefined;
      }

      setMethodList(updatedList);
      setSelectedMethod(updatedList[methodIndex]);
    },
    [methodList, rewardPointsSelected, setSelectedMethod]
  );

  const handleSelectMethod = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const methodIndex = parseInt(event.target.value);

      changeSelectedMethod(methodIndex);
    },
    [changeSelectedMethod]
  );

  const handleSwitchChange = useCallback(
    (methodIndex: number) => {
      changeSelectedMethod(methodIndex);
    },
    [changeSelectedMethod]
  );

  const handleRewardPoints = useCallback(
    (rewardDollars: number | undefined) => {
      setRewardPointsSelected(rewardDollars);

      if (selectedMethod) {
        const updatedSelectedMethod = selectedMethod;

        updatedSelectedMethod.amexPointsInDollarAmount = rewardDollars;

        setSelectedMethod(updatedSelectedMethod);
      }
    },
    [selectedMethod, setSelectedMethod]
  );

  useEffect(() => {
    setMethodList(list);
  }, [list]);

  useEffect(() => {
    if (!selectedMethod) {
      const selected = methodList.filter(
        (item: PaymentMethodProps) => item.isDefault
      )[0];
      setSelectedMethod(selected);
    }
  }, [methodList, selectedMethod, setSelectedMethod]);

  return (
    <Styled.PaymentMethodList role="list">
      {methodList.map((method, index) => {
        const cardType = method.type.toLowerCase();
        const isAmex = cardType === "american express" || cardType === "amex";

        const { rewardPoints, rewardDollarsInCents, conversionRate } = method;

        if (showAmexPwpOnly && !isAmex) {
          return <></>;
        }

        return (
          <Styled.PaymentMethodItem
            key={`${method.id}_${index}`}
            role="listitem"
          >
            <Styled.PaymentCard isAmex={isAmex}>
              <Styled.Container className="card-type-container">
                {method.type && (
                  <PaymentIcons className="card-type" cardType={method.type} />
                )}
                <Styled.PaymentMethodLabel
                  htmlFor={`select-payment-method-${index}`}
                >
                  Personal •••• {method.display}
                </Styled.PaymentMethodLabel>
                <Radio
                  checked={method.isDefault}
                  checkedIcon={<CheckIcon />}
                  id={`select-payment-method-${index}`}
                  name={`select-payment-method-${index}`}
                  onChange={handleSelectMethod}
                  value={index}
                />
              </Styled.Container>
              {isAmex &&
                !!rewardPoints &&
                !!rewardDollarsInCents &&
                !!conversionRate && (
                <AmexRewards
                  methodIndex={index}
                  rewardPoints={rewardPoints}
                  conversionRate={conversionRate}
                  rewardDollarsInCents={rewardDollarsInCents}
                  onRewardPointsChange={handleRewardPoints}
                  onSwitchChange={handleSwitchChange}
                />
              )}
              {showAmexPwpOnly && !rewardPoints && (
                <Styled.AmexPwPNotEligible>
                  Oops! We&apos;re sorry, but the card you entered is not
                  eligible for payment with Membership Rewards
                  <span className="sup">®</span>.
                </Styled.AmexPwPNotEligible>
              )}
            </Styled.PaymentCard>
          </Styled.PaymentMethodItem>
        );
      })}
    </Styled.PaymentMethodList>
  );
}

export default PaymentMethodList;
