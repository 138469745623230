import * as Styled from "components/Layout/Layout.styles";

import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import ErrorState from "components/Error/ErrorState";
import Footer from "components/Footer/Footer";
import FullScreenState from "components/FullScreenState/FullScreenState";
import { InvalidPlaceCodeError } from "../../utils/api/errors";
import VendorFooter from "@vendor/components/VendorFooter/VendorFooter";

const RootErrorBoundary = () => {
  const error = useRouteError();

  return (
    <FullScreenState>
      <Styled.InnerWrapper>
        <ErrorState title={messageForError(error)} />
        <Footer>
          <VendorFooter />
        </Footer>
      </Styled.InnerWrapper>
    </FullScreenState>
  );
};

export default RootErrorBoundary;

const messageForError = (error: any): string => {
  if (isRouteErrorResponse(error)) {
    if (error.status === 400) {
      return "Bad Request";
    }

    if (error.status === 404) {
      return "Not Found";
    }

    if (error.status === 401) {
      return "Unauthorized to see this";
    }

    if (error.status === 503) {
      return "It looks like our service is not available";
    }
  } else if (error instanceof InvalidPlaceCodeError) {
    console.error(error);
    return "Can not get data for provided Place";
  }

  return "Something went wrong";
};
